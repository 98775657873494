import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import {
  ExtensionItem,
} from './component'
import {
  Template,
  ActionButton,
  Stepper,
  ModalComponent,
  AllowedToChange,
} from '../../components'
import isAllowed from '../../components/utils/profilAuthorization'

// config empty extension
const emptyExtension = [
  {
    id_extension: null,
    extension: '',
    description: '',
    id_location: null,
    location: '',
    adresse: '',
  },
]

class TripExtension extends PureComponent {
  static propTypes = {
    ACTIVE_STEP: PropTypes.func.isRequired,
    VALIDATE_STEP: PropTypes.func.isRequired,
    GET_EXTENSION_LIST: PropTypes.func.isRequired,
    PUT_TRIP_EXTENSION: PropTypes.func.isRequired,
    RESET_TRIP_EXTENSION: PropTypes.func.isRequired,
    GET_TRIP_CONTENT: PropTypes.func.isRequired,
    GET_TRIP_EXTENSION: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isTripStepValidated: PropTypes.bool.isRequired,
    idTripSelected: PropTypes.number,
    extensionList: PropTypes.array,
    tripContent: PropTypes.array,
    tripExtension: PropTypes.array,
    tripDetails: PropTypes.array,
    locationList: PropTypes.array,
    userAccount: PropTypes.object,
    isDetailModified: PropTypes.bool,
  }

  static defaultProps = {
    extensionList: [],
    tripContent: [],
    tripExtension: [],
    tripDetails: [],
    locationList: [],
    userAccount: {},
    isDetailModified: false,
    idTripSelected: null,
  }

  constructor(props) {
    super(props);
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.reload = this.reload.bind(this)
    this.saveData = this.saveData.bind(this)
    this.goTo = this.goTo.bind(this)
    this.unvalidStep = this.unvalidStep.bind(this)
    this.redirectToExtension = this.redirectToExtension.bind(this)
    this.updateTripExtension = this.updateTripExtension.bind(this)
    this.onDeleteExtension = this.onDeleteExtension.bind(this)
    this.displayAuthorizationWarning = this.displayAuthorizationWarning.bind(this)
    this.checkVersion = this.checkVersion.bind(this)
    this.validate = this.validate.bind(this)
    this.state = {
      token: localStorage.getItem('token'),
      userId: localStorage.getItem('userId'),
      isValidated: true,
      tripExtension: [...emptyExtension],
      validMsg: '',
      navigateToDestination: null,
      isModalOpen: false,
    }
  }

  componentDidMount() {
    const {
      history,
      isTripStepValidated,
      extensionList,
      tripExtension,
      tripContent,
      ACTIVE_STEP,
      VALIDATE_STEP,
      GET_EXTENSION_LIST,
      GET_TRIP_EXTENSION,
    } = this.props
    const {
      userId,
    } = this.state
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    // redirect if preview step is not active
    if (!isTripStepValidated) history.push('trip')
    // define active step for Stepper
    ACTIVE_STEP('tripExtension')
    // validate step tripExtension is not a mandatory step
    VALIDATE_STEP('TripExtension', true)
    // goback from extension
    if (history.location.state && history.location.state.currentPage === 'tripExtension') {
      VALIDATE_STEP('TripExtension', false)
      this.setState({
        tripExtension: history.location.state.tripExtension,
        isValidated: false,
      })
    } else if (!_.isEmpty(tripExtension) && tripExtension[0] !== 'empty') {
      this.setState({
        tripExtension: [
          ...tripExtension,
          ...emptyExtension,
        ],
      })
    } else if (!_.isEmpty(tripContent)) {
      GET_TRIP_EXTENSION(tripContent[0].extension)
    }
    // check if props exists
    if (!_.isEmpty(extensionList)) {
      GET_EXTENSION_LIST(userId)
    }
  }

  componentWillReceiveProps(nextprops) {
    const { token, userId } = this.state
    const { tripExtension: tripExtensionOld } = this.props
    const {
      putTripExtension,
      tripExtension,
      VALIDATE_STEP,
      idTripSelected,
      isNewTrip,
      isDuplicatedTrip,
      ID_TRIP_SELECT,
      ROW_TRIP_SELECT,
      GET_LIST_CIRCUIT,
    } = nextprops
    // Content saved in DB -> get back updated content
    if (putTripExtension && putTripExtension.success) {
      this.reload()
      this.setState({
        validMsg: 'Modification was successfully recorded !',
        isValidated: true,
      })
      const idTrip = putTripExtension.id || idTripSelected
      ID_TRIP_SELECT(idTrip, isNewTrip, true, isDuplicatedTrip)
      // reset row selected if trip creation or new version
      if (putTripExtension.id) {
        GET_LIST_CIRCUIT(token, moment().subtract(1000, 'd').format('YYYY-MM'), moment().format('YYYY-MM'), userId)

        ROW_TRIP_SELECT([])
      }
      this.resetValidMsg()
      // validation of step
      VALIDATE_STEP('TripExtension', true)
    }
    // if there is no extension, server send ['empty']
    if (tripExtension !== tripExtensionOld && tripExtension[0] !== 'empty') {
      this.setState({
        tripExtension: [
          ...tripExtension,
          ...emptyExtension,
        ],
      })
    }
  }

  componentWillUnmount() {
    const { ACTIVE_STEP } = this.props
    ACTIVE_STEP('')
  }

  resetValidMsg = () => {
    const { navigateToDestination } = this.state
    const { history } = this.props
    setTimeout(() => {
      this.setState({
        validMsg: null,
      }, () => {
        if (navigateToDestination) history.push(navigateToDestination)
      })
    }, 2000)
  }

  goTo = () => {
    const { navigateToDestination } = this.state
    const { history } = this.props
    if (navigateToDestination) {
      history.push(navigateToDestination)
    }
  }

  reload = () => {
    const { tripExtension } = this.state
    const {
      tripContent,
      RESET_TRIP_EXTENSION,
      GET_TRIP_CONTENT,
      GET_TRIP_EXTENSION,
    } = this.props

    // Clear extension array from falsy object and return id list extension
    const tripExtensionList = tripExtension.filter(ex => !_.isEmpty(ex) && ex.extension).map(o => o.id_extension.toString()).join()
    RESET_TRIP_EXTENSION()
    GET_TRIP_CONTENT(tripContent[0].id_circuit)
    GET_TRIP_EXTENSION(tripExtensionList)
  }

  unvalidStep = () => {
    const { userId } = this.state
    const {
      userAccount,
      tripDetails,
      VALIDATE_STEP,
    } = this.props
    if (isAllowed(userId, userAccount.profil, tripDetails)) {
      VALIDATE_STEP('TripExtension', false)
    }
  }

  // **********************************************
  // if navigation by stepper
  saveData = (dest) => {
    const {
      isValidated,
      userId,
    } = this.state
    const {
      history,
      userAccount,
      tripDetails,
    } = this.props
    const isAllowedToChange = !isAllowed(userId, userAccount.profil, tripDetails)

    if (isValidated || isAllowedToChange) history.push(dest)
    else {
      this.setState({
        navigateToDestination: dest,
      })
      this.validate()
    }
  }

  //  *******************************************
  // on change inputs
  redirectToExtension = (newExtension, index) => {
    const { history } = this.props
    const { tripExtension } = this.state
    const info = {
      currentPage: 'tripExtension',
      newExtension,
      index,
      tripExtension,
    }
    if (newExtension.extension) {
      this.setState({
        isValidated: true,
      }, () => history.push('extension', { ...info }))
    }
  }

  updateTripExtension = (item, index) => {
    const {
      tripExtension,
    } = this.state

    const newTripExtension = _.cloneDeep(tripExtension)

    newTripExtension[index] = { ...item }

    this.unvalidStep()
    this.setState({
      tripExtension: newTripExtension,
      isValidated: false,
    })
  }

  onDeleteExtension = (index) => {
    const { tripExtension } = this.state
    const newArrayExtension = _.cloneDeep(tripExtension)
    newArrayExtension.splice(index, 1)
    this.unvalidStep()
    this.setState({
      tripExtension: newArrayExtension,
      isValidated: false,
    })
  }

  // ********************************
  // Modal
  handleOpen = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  createNewVersion = () => {
    const {
      token,
      tripExtension,
    } = this.state
    const {
      PUT_TRIP_EXTENSION,
      idTripSelected,
      tripContent,
      tripDetails,
    } = this.props

    const tripExtensionIdList = tripExtension.map((ted) => {
      if (ted.id_extension) return ted.id_extension
      return null
    })
    // extension list without null values
    const extensionContent = _.pull(tripExtensionIdList, null).join()

    this.handleClose()

    this.setState({
      isValidated: true,
    })
    PUT_TRIP_EXTENSION(token, extensionContent, tripContent[0].id_circuit, tripDetails[0].id_demandeur || null, idTripSelected, 'version')
  }

  // ******************************
  // display component

  displayAuthorizationWarning = () => {
    const {
      tripDetails,
      userAccount,
    } = this.props
    const {
      userId,
    } = this.state
    return (
      <AllowedToChange
        userId={userId}
        profil={userAccount.profil}
        tripDetails={tripDetails}
      />
    )
  }

  // *******************************
  // check version quote
  checkVersion = (e) => {
    e.preventDefault()
    const {
      isDetailModified,
    } = this.props

    this.setState({
      isValidated: true,
    })

    if (!isDetailModified) {
      this.handleOpen()
    } else {
      this.validate()
    }
  }

  // Validate
  validate = () => {
    const {
      token,
      tripExtension,
    } = this.state
    const {
      PUT_TRIP_EXTENSION,
      tripContent,
    } = this.props

    this.setState({
      isValidated: true,
    })

    const tripExtensionIdList = tripExtension.map((ted) => {
      if (ted.id_extension) return ted.id_extension
      return null
    })
    // extension list without null values
    const extensionContent = _.pull(tripExtensionIdList, null).join()

    this.handleClose()
    PUT_TRIP_EXTENSION(token, extensionContent, tripContent[0].id_circuit, null, null, 'update')
  }

  render() {
    const {
      userId,
      validMsg,
      tripExtension,
      isValidated,
      isModalOpen,
    } = this.state
    const {
      extensionList,
      locationList,
      userAccount,
      tripDetails,
    } = this.props

    const isActionButtonDisabled = !_.isEmpty(tripDetails) && !_.isEmpty(userAccount) ? !isAllowed(userId, userAccount.profil, tripDetails) : true

    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <Stepper saveData={dest => this.saveData(dest)} />
            <div className="dividerStepper" />
            <div className="extensionContainer">
              <h2>EXTENSION</h2>
              {this.displayAuthorizationWarning()}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {tripExtension.map((ted, i) => (
                <ExtensionItem
                  key={Math.random(100).toString()}
                  item={ted}
                  index={i}
                  extensionList={extensionList}
                  locationList={locationList}
                  onChange={(item, index) => this.updateTripExtension(item, index)}
                  onDelete={index => this.onDeleteExtension(index)}
                  redirectToExtension={(newValue, index) => this.redirectToExtension(newValue, index)}
                />
              ))}
            </div>
            <ActionButton
              label="Validate"
              onClick={e => this.checkVersion(e)}
              disabled={isActionButtonDisabled}
            />
          </div>
          <ModalComponent
            isModalOpen={isModalOpen}
            onClose={this.handleClose}
            onResponseNo={this.createNewVersion}
            onResponseYes={this.validate}
            type="version"
            title="do you want to updade the current version or create a new one?"
          />
        </Template>
        <React.Fragment>
          <Prompt
            when={!isValidated}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isTripStepValidated: state.api.isTripStepValidated,
  idTripSelected: state.api.idTripSelected,
  isNewTrip: state.api.isNewTrip,
  isDuplicatedTrip: state.api.isDuplicatedTrip,
  idTourSelected: !_.isEmpty(state.api.tripDetails) ? state.api.tripDetails[0].id_circuit : null,
  extensionList: state.extensionReducer.extensionList,
  tripDetails: state.api.tripDetails,
  tripContent: state.api.tripContent,
  putTripExtension: state.tripExtensionReducer.putTripExtension,
  locationList: state.api.locationList,
  tripExtension: state.tripExtensionReducer.tripExtension,
  isDetailModified: state.api.isDetailModified,
  userAccount: state.userAccountReducer.userAccount,
})

const mapDispatchToProps = dispatch => ({
  ACTIVE_STEP: activeStep => dispatch({
    type: 'ACTIVE_STEP',
    activeStep,
  }),
  VALIDATE_STEP: (page, value) => dispatch({
    type: 'VALIDATE_STEP',
    page,
    value,
  }),
  GET_LIST_CIRCUIT: (token, dateTampon, dateTamponFin, filterAdvisor) => dispatch({
    type: 'GET_LIST_CIRCUIT',
    token,
    dateTampon,
    dateTamponFin,
    filterAdvisor,
  }),
  GET_EXTENSION_LIST: userId => dispatch({
    type: 'GET_EXTENSION_LIST',
    userId,
  }),
  GET_TRIP_CONTENT: idTourSelected => dispatch({
    type: 'GET_TRIP_CONTENT',
    idTourSelected,
  }),
  GET_TRIP_EXTENSION: tripExtensionList => dispatch({
    type: 'GET_TRIP_EXTENSION',
    tripExtensionList,
  }),
  PUT_TRIP_EXTENSION: (token, extension, idTour, idDemandeur, idTrip, action) => dispatch({
    type: 'PUT_TRIP_EXTENSION',
    token,
    extension,
    idTour,
    idDemandeur,
    idTrip,
    action,
  }),
  RESET_TRIP_EXTENSION: () => dispatch({
    type: 'RESET_TRIP_EXTENSION',
  }),
  ID_TRIP_SELECT: (idTripSelected, isNewTrip, isDetailModified) => dispatch({
    type: 'ID_TRIP_SELECT',
    idTripSelected,
    isNewTrip,
    isDetailModified,
  }),
  ROW_TRIP_SELECT: allRows => dispatch({
    type: 'ROW_TRIP_SELECT',
    allRows,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TripExtension))
