import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import _, { isEmpty } from 'lodash'
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';


import {
  Template,
  ActionButton,
  Stepper,
  Loader,
  DayLineBilling,
  AllowedToChange,
  DayLineAcco,
  TabPanel,
  ModalComponent,
} from '../../components'


import {
  DaySelect,
} from '../tourOperator/component'

import {
  CssGrid,
  AntTabs,
} from '../../configCSS'
import isAllowed from '../../components/utils/profilAuthorization'

class TripSuppliers extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    LOADER: PropTypes.func.isRequired,
    idTripSelected: PropTypes.number,
    tripDetails: PropTypes.array,
    tripRoom: PropTypes.array,
    tripAllDays: PropTypes.array,
    ACTIVE_STEP: PropTypes.func.isRequired,
    tripAllDaysAccomodations: PropTypes.array,
    tripContent: PropTypes.array,
    tripAccomodation: PropTypes.array,
    tripAccomodationOpt1: PropTypes.array,
    tripAccomodationOpt2: PropTypes.array,
    GET_TRIP_ACCOMODATION: PropTypes.func.isRequired,
    GET_TRIP_ACCOMODATION_OPT1: PropTypes.func.isRequired,
    GET_TRIP_ACCOMODATION_OPT2: PropTypes.func.isRequired,
    GET_TRIP_ROOM: PropTypes.func.isRequired,
    GET_TRIP_ROOM_OPT1: PropTypes.func.isRequired,
    GET_TRIP_ROOM_OPT2: PropTypes.func.isRequired,
    PUT_TRIP_PRICE_ACTIVITY: PropTypes.func.isRequired,
    putTripTravellerActivity: PropTypes.object,
    PUT_TRIP_TRAVELLER_ACTIVITY: PropTypes.func.isRequired,
    tripRoomOpt1: PropTypes.array,
    tripRoomOpt2: PropTypes.array,
    tripBilling: PropTypes.object,
    tripActivities: PropTypes.object,
    userAccount: PropTypes.object,
    putTripPriceActivity: PropTypes.object,
    isTripDetailsStepValidated: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    GET_TRIP_BILLING: PropTypes.func.isRequired,
    GET_TRIP_DEVIS_FINAL: PropTypes.func.isRequired,
    RESET_PUT_TRIP_ACCOMODATION_ACTIVITY: PropTypes.func.isRequired,
    VALIDATE_STEP: PropTypes.func.isRequired,
    GET_TRIP_ACTIVITIES: PropTypes.func.isRequired,
    putTripContentAcco: PropTypes.object,
    PUT_TRIP_CONTENT_ACCO: PropTypes.func.isRequired,
    RESET_PUT_TRIP_CONTENT_ACCO: PropTypes.func.isRequired,
    GET_TRIP_CONTENT: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isLoading: true,
    idTripSelected: null,
    tripAllDays: [],
    tripAllDaysAccomodations: [],
    tripRoom: [],
    tripDetails: [],
    tripContent: [],
    tripAccomodation: [],
    tripAccomodationOpt1: [],
    tripAccomodationOpt2: [],
    tripRoomOpt1: [],
    tripRoomOpt2: [],
    tripBilling: {},
    putTripPriceActivity: {},
    tripActivities: {},
    putTripTravellerActivity: null,
    userAccount: {},
    putTripContentAcco: null,
  }

  constructor(props) {
    super(props);
    this.displayAuthorizationWarning = this.displayAuthorizationWarning.bind(this)
    this.getAllTripInformationLoad = this.getAllTripInformationLoad.bind(this)
    this.getAllTripInformationLoadAfterUpdate = this.getAllTripInformationLoadAfterUpdate.bind(this)
    this.validate = this.validate.bind(this)
    this.updateQueryStatusMsg = this.updateQueryStatusMsg.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.resetDisplay = this.resetDisplay.bind(this)
    this.updateItems = this.updateItems.bind(this)
    this.updateItemsAcco = this.updateItemsAcco.bind(this)
    this.updateItemsAccoOpt1 = this.updateItemsAccoOpt1.bind(this)
    this.updateItemsAccoOpt2 = this.updateItemsAccoOpt2.bind(this)
    this.isModalOpenInChildren = this.isModalOpenInChildren.bind(this)
    this.displayDisplayModalBilling = this.displayDisplayModalBilling.bind(this)
    this.displayDisplayModalDefaultTraveller = this.displayDisplayModalDefaultTraveller.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleOpenTraveller = this.handleOpenTraveller.bind(this)
    this.handleOpenModalTrav = this.handleOpenModalTrav.bind(this)
    this.reloadActivites = this.reloadActivites.bind(this)
    this.onChangeTab = this.onChangeTab.bind(this)
    this.tabProps = this.tabProps.bind(this)

    this.state = {
      userId: localStorage.getItem('userId'),
      token: localStorage.getItem('token'),
      isModalDisplayed: false,
      daySelected: null,
      dayIndexSelected: null,
      errorMsg: null,
      validMsg: null,
      isValidated: true,
      isModalOpenBilling: false,
      isModalOpenDefaultTraveller: false,
      displayFull: true,
      isActivityChanged: false,
      keyTab: 0,
      accoContent: '',
      accoContentOpt1: '',
      accoContentOpt2: '',
      roomContent: '',
      roomContentOpt1: '',
      roomContentOpt2: '',
      nbRoom: '',
      nbRoomOpt1: '',
      nbRoomOpt2: '',
      itemToChange: {},
      daysToChange: 0,
      optToChange: 0,
      reasonOfChange: '',
      indexToChange: 0,
    };
  }

  componentDidMount() {
    const {
      history,
      LOADER,
      isTripDetailsStepValidated,
      tripBilling,
      tripContent,
      tripActivities,
      tripAccomodation,
      tripAccomodationOpt1,
      tripAccomodationOpt2,
      tripRoom,
      tripRoomOpt1,
      tripRoomOpt2,
      GET_TRIP_ACTIVITIES,
      ACTIVE_STEP,
    } = this.props
    const { token } = this.state
    // check if user is logged

    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (!_.isEmpty(tripContent)) {
      LOADER(true)
      this.getAllTripInformationLoad(tripContent)
    }
    ACTIVE_STEP('tripSuppliers')
    if (_.isEmpty(tripActivities) && !_.isEmpty(tripContent)) {
      GET_TRIP_ACTIVITIES(token, tripContent[0].id_circuit)
    }
    // redirect if preview step is not active
    if (!isTripDetailsStepValidated) {
      history.push('trip')
    } else if (!_.isEmpty(tripBilling)) { // build table then fill it then build table price
      this.setState({
        accoContent: tripAccomodation,
        accoContentOpt1: tripAccomodationOpt1,
        accoContentOpt2: tripAccomodationOpt2,
        roomContent: tripRoom,
        roomContentOpt1: tripRoomOpt1,
        roomContentOpt2: tripRoomOpt2,
        nbRoom: tripContent[0].nb_chambre.split(','),
        nbRoomOpt1: tripContent[0].nb_chambre_opt1.split(','),
        nbRoomOpt2: tripContent[0].nb_chambre_opt2.split(','),
      })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      accoContent,
      accoContentOpt1,
      accoContentOpt2,
      roomContent,
      roomContentOpt1,
      roomContentOpt2,
      nbRoom,
      nbRoomOpt1,
      nbRoomOpt2,
    } = prevState
    const {
      tripAccomodation,
      tripAccomodationOpt1,
      tripAccomodationOpt2,
      tripRoom,
      tripRoomOpt1,
      tripRoomOpt2,
      tripContent,
    } = nextProps
    if (tripAccomodation && isEmpty(accoContent)) {
      return {
        accoContent: tripAccomodation,
      }
    }
    if (tripAccomodationOpt1 && isEmpty(accoContentOpt1)) {
      return {
        accoContentOpt1: tripAccomodationOpt1,
      }
    }
    if (tripAccomodationOpt2 && isEmpty(accoContentOpt2)) {
      return {
        accoContentOpt2: tripAccomodationOpt2,
      }
    }
    if (tripRoom && isEmpty(roomContent)) {
      return {
        roomContent: tripRoom,
      }
    }
    if (tripRoomOpt1 && isEmpty(roomContentOpt1)) {
      return {
        roomContentOpt1: tripRoomOpt1,
      }
    }
    if (tripRoomOpt2 && isEmpty(roomContentOpt2)) {
      return {
        roomContentOpt2: tripRoomOpt2,
      }
    }
    if (tripContent && isEmpty(nbRoom)) {
      return {
        nbRoom: tripContent[0].nb_chambre.split(','),
      }
    }
    if (tripContent && isEmpty(nbRoomOpt1)) {
      return {
        nbRoomOpt1: tripContent[0].nb_chambre_opt1.split(','),
      }
    }
    if (tripContent && isEmpty(nbRoomOpt2)) {
      return {
        nbRoomOpt2: tripContent[0].nb_chambre_opt2.split(','),
      }
    }
    return null
  }

  componentDidUpdate(prevProps) {
    const { token } = this.state
    const {
      LOADER,
      putTripTravellerActivity,
      putTripContentAcco,
      putTripPriceActivity,
      tripAccomodation,
      tripAccomodationOpt1,
      tripAccomodationOpt2,
      tripRoom,
      tripRoomOpt1,
      tripActivities,
      tripRoomOpt2,
      RESET_PUT_TRIP_ACCOMODATION_ACTIVITY,
      GET_TRIP_ACTIVITIES,
      tripContent,
      RESET_PUT_TRIP_CONTENT_ACCO,
      GET_TRIP_CONTENT,
      isLoading,
    } = this.props
    const {
      tripContent: tripContentOld,
      tripActivities: tripActivitiesOld,
    } = prevProps
    if (putTripTravellerActivity && !_.isEmpty(putTripTravellerActivity) && 'success' in putTripTravellerActivity) {
      GET_TRIP_ACTIVITIES(token, tripContent[0].id_circuit)
      RESET_PUT_TRIP_ACCOMODATION_ACTIVITY()
      this.resetDisplay()
    }
    if (putTripPriceActivity && putTripPriceActivity.success) {
      LOADER(true)
      GET_TRIP_ACTIVITIES(token, tripContent[0].id_circuit)
      RESET_PUT_TRIP_ACCOMODATION_ACTIVITY()
    }
    if (!_.isEmpty(tripContent) && putTripContentAcco && putTripContentAcco.success) {
      LOADER(true)
      this.updateQueryStatusMsg(putTripContentAcco.success, putTripContentAcco.queryStatusMsg)
      this.resetValidMsg()
      GET_TRIP_CONTENT(tripContent[0].id_circuit)
      GET_TRIP_ACTIVITIES(token, tripContent[0].id_circuit)
      RESET_PUT_TRIP_CONTENT_ACCO()
    }
    if (!_.isEqual(tripContent, tripContentOld)) {
      this.getAllTripInformationLoadAfterUpdate(tripContent)
      LOADER(false)
    }


    if (!_.isEqual(tripActivities, tripActivitiesOld)) {
      this.resetDisplay()
    }

    // check if all informations have been received adn valid step
    if (isLoading && tripAccomodation && tripAccomodationOpt1 && tripAccomodationOpt2
        && tripRoom && tripRoomOpt1 && tripRoomOpt2
    ) {
      LOADER(false)
    }
  }

  componentWillUnmount() {
    const {
      ACTIVE_STEP,
    } = this.props

    ACTIVE_STEP('')
  }

  async getAllTripInformationLoad(content, message = false) {
    const {
      GET_TRIP_ACCOMODATION,
      GET_TRIP_ACCOMODATION_OPT1,
      GET_TRIP_ACCOMODATION_OPT2,
      GET_TRIP_ROOM,
      GET_TRIP_ROOM_OPT1,
      GET_TRIP_ROOM_OPT2,
      tripAccomodation,
      tripAccomodationOpt1,
      tripAccomodationOpt2,
      tripRoom,
      tripRoomOpt1,
      tripRoomOpt2,
    } = this.props
    // get trip informations

    if (!_.isEmpty(content) && content[0].journee && content[0].hebergement && content[0].hebergement_opt && content[0].hebergement_opt1 && content[0].hebergement_opt2 && content[0].pension && content[0].type_chambre) {
      try {
        if (_.isEmpty(tripAccomodation)) await GET_TRIP_ACCOMODATION(content[0].hebergement)
        if (_.isEmpty(tripAccomodationOpt1)) await GET_TRIP_ACCOMODATION_OPT1(content[0].hebergement_opt1)
        if (_.isEmpty(tripAccomodationOpt2)) await GET_TRIP_ACCOMODATION_OPT2(content[0].hebergement_opt2)
        if (_.isEmpty(tripRoom)) await GET_TRIP_ROOM(content[0].type_chambre, content[0].hebergement)
        if (_.isEmpty(tripRoomOpt1)) await GET_TRIP_ROOM_OPT1(content[0].type_chambre_opt1, content[0].hebergement_opt1)
        if (_.isEmpty(tripRoomOpt2)) await GET_TRIP_ROOM_OPT2(content[0].type_chambre_opt2, content[0].hebergement_opt2)


        if (message) {
          this.setState({
            isValidated: true,
            validMsg: 'Changes were successfully recorded !',
          }, () => this.resetValidMsg())
        }
      } catch (error) {
        console.log('Route await error', error)
      }
    }
    return null
  }

  async getAllTripInformationLoadAfterUpdate(content, message = false) {
    const {
      GET_TRIP_ACCOMODATION,
      GET_TRIP_ACCOMODATION_OPT1,
      GET_TRIP_ACCOMODATION_OPT2,
      GET_TRIP_ROOM,
      GET_TRIP_ROOM_OPT1,
      GET_TRIP_ROOM_OPT2,
      LOADER,
    } = this.props
    // get trip informations
    LOADER(true)
    if (!_.isEmpty(content) && content[0].journee && content[0].hebergement && content[0].hebergement_opt && content[0].hebergement_opt1 && content[0].hebergement_opt2 && content[0].pension && content[0].type_chambre) {
      try {
        await GET_TRIP_ACCOMODATION(content[0].hebergement)
        await GET_TRIP_ACCOMODATION_OPT1(content[0].hebergement_opt1)
        await GET_TRIP_ACCOMODATION_OPT2(content[0].hebergement_opt2)
        await GET_TRIP_ROOM(content[0].type_chambre, content[0].hebergement)
        await GET_TRIP_ROOM_OPT1(content[0].type_chambre_opt1, content[0].hebergement_opt1)
        await GET_TRIP_ROOM_OPT2(content[0].type_chambre_opt2, content[0].hebergement_opt2)


        if (message) {
          this.setState({
            isValidated: true,
            validMsg: 'Changes were successfully recorded !',
          }, () => this.resetValidMsg())
        }
      } catch (error) {
        console.log('Route await error', error)
      }
    }
    return null
  }


  // Message management **********************
  updateQueryStatusMsg = (isValid, msg) => {
    this.setState({
      validMsg: isValid ? msg : null,
      errorMsg: isValid ? null : msg,
      isValidated: true,
    })
  }

  resetValidMsg =() => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  resetDisplay =() => {
    this.setState({
      displayFull: true,
    })
  }

  reloadActivites =(e) => {
    const {
      GET_TRIP_ACTIVITIES,
      tripContent,
    } = this.props
    const {
      token,
    } = this.state
    e.preventDefault()
    this.setState({
      isActivityChanged: false,
      displayFull: false,
    })

    GET_TRIP_ACTIVITIES(token, tripContent[0].id_circuit)
  }

  isModalOpenInChildren = (state) => {
    this.setState({ isModalDisplayed: state })
  }

  sendDayIndexSelected = (rank) => {
    this.setState({
      dayIndexSelected: rank,
    })
  }

  sendDaySelected = (day) => {
    this.setState({
      daySelected: day,
    })
  }


  // save input change

    handleClose = () => {
      const { scrollY } = window;
      this.setState({
        isModalOpenBilling: false,
        isModalOpenDefaultTraveller: false,
      });
      setTimeout(() => {
        window.scrollTo(0, scrollY);
      }, 0);
    };


    handleOpen = () => {
      const {
        daysToChange,
        itemToChange,
        accoContent,
        accoContentOpt2,
        accoContentOpt1,
        roomContent,
        roomContentOpt1,
        roomContentOpt2,
        nbRoom,
        nbRoomOpt1,
        nbRoomOpt2,
        optToChange,
        reasonOfChange,
        indexToChange,
      } = this.state
      if (reasonOfChange === 'accoChange') {
        if (optToChange === 0) {
          const updatedAccoContent = accoContent.map((element, index) => {
            if (daysToChange.includes(index)) {
              return { ...itemToChange };
            }
            return { ...element };
          });
          const updatedRoomContent = roomContent
          daysToChange.forEach((index) => {
            // Utiliser la déstructuration d'array pour extraire la première entrée de itemToChange
            updatedRoomContent[index] = [];
          });
          const updatedNbRoomContent = nbRoom
          daysToChange.forEach((index) => {
            updatedNbRoomContent[index] = '1';
          });
          this.setState({
            accoContent: updatedAccoContent,
            roomContent: updatedRoomContent,
            nbRoom: updatedNbRoomContent,
          });
        }
        if (optToChange === 1) {
          const updatedAccoContent = accoContentOpt1.map((element, index) => {
            if (daysToChange.includes(index)) {
              return { ...itemToChange };
            }
            return { ...element };
          });
          const updatedRoomContent = roomContentOpt1
          daysToChange.forEach((index) => {
            // Utiliser la déstructuration d'array pour extraire la première entrée de itemToChange
            updatedRoomContent[index] = [];
          });
          const updatedNbRoomContent = nbRoomOpt1
          daysToChange.forEach((index) => {
            updatedNbRoomContent[index] = '1';
          });
          this.setState({
            accoContentOpt1: updatedAccoContent,
            roomContentOpt1: updatedRoomContent,
            nbRoomOpt1: updatedNbRoomContent,
          });
        }
        if (optToChange === 2) {
          const updatedAccoContent = accoContentOpt2.map((element, index) => {
            if (daysToChange.includes(index)) {
              return { ...itemToChange };
            }
            return { ...element };
          });
          const updatedRoomContent = roomContentOpt2
          daysToChange.forEach((index) => {
            // Utiliser la déstructuration d'array pour extraire la première entrée de itemToChange
            updatedRoomContent[index] = [];
          });
          const updatedNbRoomContent = nbRoomOpt2
          daysToChange.forEach((index) => {
            updatedNbRoomContent[index] = '1';
          });
          this.setState({
            accoContentOpt2: updatedAccoContent,
            roomContentOpt2: updatedRoomContent,
            nbRoomOpt2: updatedNbRoomContent,
          });
        }
      }
      if (reasonOfChange === 'nbRoomChange') {
        const stringItem = _.join(itemToChange, '-')
        if (optToChange === 0) {
          const updatedRoomContent = roomContent
          const roomToChange = roomContent[indexToChange]
          daysToChange.forEach((index) => {
            // Utiliser la déstructuration d'array pour extraire la première entrée de itemToChange
            updatedRoomContent[index] = roomToChange;
          });
          const updatedNbRoomContent = nbRoom
          daysToChange.forEach((index) => {
            updatedNbRoomContent[index] = stringItem;
          });
          this.setState({
            roomContent: updatedRoomContent,
            nbRoom: updatedNbRoomContent,
          });
        }
        if (optToChange === 1) {
          const updatedRoomContent = roomContentOpt1
          const roomToChange = roomContentOpt1[indexToChange]
          daysToChange.forEach((index) => {
            // Utiliser la déstructuration d'array pour extraire la première entrée de itemToChange
            updatedRoomContent[index] = roomToChange;
          });
          const updatedNbRoomContent = nbRoomOpt1
          daysToChange.forEach((index) => {
            updatedNbRoomContent[index] = stringItem;
          });
          this.setState({
            roomContentOpt1: updatedRoomContent,
            nbRoomOpt1: updatedNbRoomContent,
          });
        }
        if (optToChange === 2) {
          const updatedRoomContent = roomContentOpt2
          const roomToChange = roomContentOpt2[indexToChange]
          daysToChange.forEach((index) => {
            // Utiliser la déstructuration d'array pour extraire la première entrée de itemToChange
            updatedRoomContent[index] = roomToChange;
          });
          const updatedNbRoomContent = nbRoomOpt2
          daysToChange.forEach((index) => {
            updatedNbRoomContent[index] = stringItem;
          });
          this.setState({
            roomContentOpt2: updatedRoomContent,
            nbRoomOpt2: updatedNbRoomContent,
          });
        }
      }
      this.setState({
        isModalOpenBilling: false,
      });
    }

    displayDisplayModalBilling = () => {
      const {
        isModalOpenBilling,
      } = this.state
      return (
        <ModalComponent
          isModalOpen={isModalOpenBilling}
          onClose={this.handleClose}
          onResponseNo={this.handleClose}
          onResponseYes={this.handleOpen}
          title="Other days ends at the same location, do you also want to update for these location?"
        />
      )
    }

    handleOpenTraveller = () => {
      const {
        PUT_TRIP_TRAVELLER_ACTIVITY,
        tripDetails,
        tripActivities,
      } = this.props

      const {
        token,
      } = this.state

      const {
        nb_senior,
        nb_adulte,
        nb_enfant,
      } = tripDetails[0]
      PUT_TRIP_TRAVELLER_ACTIVITY(token, tripActivities, nb_senior, nb_adulte, nb_enfant)
      this.setState({
        isModalOpenDefaultTraveller: false,
        displayFull: false,
      });
    }

    handleOpenModalTrav = () => {
      this.setState({
        isModalOpenDefaultTraveller: true,
      })
    }

    displayDisplayModalDefaultTraveller = () => {
      const {
        isModalOpenDefaultTraveller,
      } = this.state
      return (
        <ModalComponent
          isModalOpen={isModalOpenDefaultTraveller}
          onClose={this.handleClose}
          onResponseNo={this.handleClose}
          onResponseYes={this.handleOpenTraveller}
          title="You will update all the activite with the travel number, are you sure?"
        />
      )
    }


  updateItems = (item) => {
    // replace item when update
    const {
      tripAllDays,
    } = this.props

    const indexItem = _.findIndex(tripAllDays, o => o.id_journee === item.id_journee)
    _.map(tripAllDays, (o, index) => {
      if (index === indexItem) {
        return item
      }
      return o
    })

    // this.setState({
    //   items: newItems,
    //   isValidated: false,
    // })
  }

  updateItemsAcco = (item, indexItemAcco, reason, nbOcc) => {
    // replace item when update
    const { accoContent, roomContent, nbRoom } = this.state
    if (reason === 'accoChange') {
      if (nbOcc.length > 1) {
        this.setState({
          isModalOpenBilling: true,
          isModalDisplayed: true,
          itemToChange: item,
          daysToChange: nbOcc,
          optToChange: 0,
          reasonOfChange: reason,
        })
      }
      if (item === null) {
        item = {
          id_hebergement: 1,
          nom_hebergement: 'none',
        }
      }
      const newItems = _.map(accoContent, (o, index) => {
        if (index === indexItemAcco) {
          return item
        }
        return o
      })
      const newItemsRoom = _.map(roomContent, (o, index) => {
        if (index === indexItemAcco) {
          return []
        }
        return o
      })
      const newItemsNbRoom = _.map(nbRoom, (o, index) => {
        if (index === indexItemAcco) {
          return '1'
        }
        return o
      })
      this.setState({
        isValidated: false,
        accoContent: newItems,
        roomContent: newItemsRoom,
        nbRoom: newItemsNbRoom,
      })
    }
    if (reason === 'roomChange') {
      const newItems = _.map(roomContent, (o, index) => {
        if (index === indexItemAcco) {
          return item
        }
        return o
      })
      this.setState({
        isValidated: false,
        roomContent: newItems,
      })
    }
    if (reason === 'nbRoomChange') {
    //  if (nbOcc.length > 1) {
    //    this.setState({
    //      isModalOpenBilling: true,
    //      isModalDisplayed: true,
    //      itemToChange: item,
    //      daysToChange: nbOcc,
    //      optToChange: 0,
      //     reasonOfChange: reason,
      //     indexToChange: indexItemAcco,
      //   })
      // }
      const stringItem = _.join(item, '-')
      const newItems = _.map(nbRoom, (o, index) => {
        if (index === indexItemAcco) {
          return stringItem
        }
        return o
      })
      this.setState({
        isValidated: false,
        nbRoom: newItems,
      })
    }
  }

  updateItemsAccoOpt1 = (item, indexItemAcco, reason, nbOcc) => {
    // replace item when update
    const { accoContentOpt1, roomContentOpt1, nbRoomOpt1 } = this.state
    if (reason === 'accoChange') {
      if (nbOcc.length > 1) {
        this.setState({
          isModalOpenBilling: true,
          isModalDisplayed: true,
          itemToChange: item,
          daysToChange: nbOcc,
          optToChange: 1,
          reasonOfChange: reason,
        })
      }
      if (item === null) {
        item = {
          id_hebergement: 1,
          nom_hebergement: 'none',
        }
      }
      const newItemsAcco = _.map(accoContentOpt1, (o, index) => {
        if (index === indexItemAcco) {
          return item
        }
        return o
      })
      const newItemsRoom = _.map(roomContentOpt1, (o, index) => {
        if (index === indexItemAcco) {
          return []
        }
        return o
      })
      const newItemsNbRoom = _.map(nbRoomOpt1, (o, index) => {
        if (index === indexItemAcco) {
          return '1'
        }
        return o
      })
      this.setState({
        isValidated: false,
        accoContentOpt1: newItemsAcco,
        roomContentOpt1: newItemsRoom,
        nbRoomOpt1: newItemsNbRoom,
      })
    }
    if (reason === 'roomChange') {
      const newItemsRoom = _.map(roomContentOpt1, (o, index) => {
        if (index === indexItemAcco) {
          return item
        }
        return o
      })
      this.setState({
        isValidated: false,
        roomContentOpt1: newItemsRoom,
      })
    }
    if (reason === 'nbRoomChange') {
    //  if (nbOcc.length > 1) {
      //   this.setState({
      //     isModalOpenBilling: true,
      //     isModalDisplayed: true,
      //     itemToChange: item,
      //     daysToChange: nbOcc,
      //     optToChange: 1,
      //     reasonOfChange: reason,
      //     indexToChange: indexItemAcco,
      //   })
      // }
      const stringItem = _.join(item, '-')
      const newItemsNb = _.map(nbRoomOpt1, (o, index) => {
        if (index === indexItemAcco) {
          return stringItem
        }
        return o
      })
      this.setState({
        isValidated: false,
        nbRoomOpt1: newItemsNb,
      })
    }
  }

  updateItemsAccoOpt2 = (item, indexItemAcco, reason, nbOcc) => {
    // replace item when update
    const { accoContentOpt2, roomContentOpt2, nbRoomOpt2 } = this.state
    if (reason === 'accoChange') {
      if (nbOcc.length > 1) {
        this.setState({
          isModalOpenBilling: true,
          isModalDisplayed: true,
          itemToChange: item,
          daysToChange: nbOcc,
          optToChange: 2,
          reasonOfChange: reason,
        })
      }
      if (item === null) {
        item = {
          id_hebergement: 1,
          nom_hebergement: 'none',
        }
      }
      const newItems = _.map(accoContentOpt2, (o, index) => {
        if (index === indexItemAcco) {
          return item
        }
        return o
      })
      const newItemsRoom = _.map(roomContentOpt2, (o, index) => {
        if (index === indexItemAcco) {
          return []
        }
        return o
      })
      const newItemsNbRoom = _.map(nbRoomOpt2, (o, index) => {
        if (index === indexItemAcco) {
          return '1'
        }
        return o
      })
      this.setState({
        isValidated: false,
        accoContentOpt2: newItems,
        roomContentOpt2: newItemsRoom,
        nbRoomOpt2: newItemsNbRoom,
      })
    }
    if (reason === 'roomChange') {
      const newItems = _.map(roomContentOpt2, (o, index) => {
        if (index === indexItemAcco) {
          return item
        }
        return o
      })
      this.setState({
        isValidated: false,
        roomContentOpt2: newItems,
      })
    }
    if (reason === 'nbRoomChange') {
      //   if (nbOcc.length > 1) {
    //    this.setState({
      //    isModalOpenBilling: true,
      //    isModalDisplayed: true,
      //   itemToChange: item,
      //   daysToChange: nbOcc,
      //   optToChange: 2,
      //   reasonOfChange: reason,
      //  indexToChange: indexItemAcco,
      //  })
      // }
      const stringItem = _.join(item, '-')
      const newItems = _.map(nbRoomOpt2, (o, index) => {
        if (index === indexItemAcco) {
          return stringItem
        }
        return o
      })
      this.setState({
        isValidated: false,
        nbRoomOpt2: newItems,
      })
    }
  }

  displayAuthorizationWarning = () => {
    const {
      tripDetails,
      userAccount,
    } = this.props
    const {
      userId,
    } = this.state
    return (
      <AllowedToChange
        userId={userId}
        profil={userAccount.profil}
        tripDetails={tripDetails}
      />
    )
  }

    // TABS
    onChangeTab = (event, keyTab) => {
      this.setState({
        keyTab,
      })
    }

    tabProps = index => ({
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    })


  // Validate **********************
  validate = (e) => {
    e.preventDefault()
    const {
      token,
      accoContent,
      accoContentOpt1,
      accoContentOpt2,
      roomContent,
      roomContentOpt1,
      roomContentOpt2,
      nbRoom,
      nbRoomOpt1,
      nbRoomOpt2,
    } = this.state
    const {
      PUT_TRIP_CONTENT_ACCO,
      tripContent,
    } = this.props

    const tableauAcco = []
    const tableauRoom = []
    const tableauNbRoom = []
    tableauAcco.push(accoContent.map(objet => (objet.id_hebergement ? objet.id_hebergement : '1')))
    tableauAcco.push(accoContentOpt1.map(objet => (objet.id_hebergement ? objet.id_hebergement : '1')))
    tableauAcco.push(accoContentOpt2.map(objet => (objet.id_hebergement ? objet.id_hebergement : '1')))
    tableauRoom.push(roomContent.map((roomByDay) => {
      if (roomByDay.length === 0) {
        return 1; // Mettre la valeur 1 si le tableau est vide
      }
      return roomByDay.map(objet => objet.id_heb_ch).join('-');
      // Utiliser join pour séparer les valeurs par '-'
    }))
    tableauRoom.push(roomContentOpt1.map((roomByDay) => {
      if (roomByDay.length === 0) {
        return 1; // Mettre la valeur 1 si le tableau est vide
      }
      return roomByDay.map(objet => objet.id_heb_ch).join('-');
      // Utiliser join pour séparer les valeurs par '-'
    }))
    tableauRoom.push(roomContentOpt2.map((roomByDay) => {
      if (roomByDay.length === 0) {
        return 1; // Mettre la valeur 1 si le tableau est vide
      }
      return roomByDay.map(objet => objet.id_heb_ch).join('-');
      // Utiliser join pour séparer les valeurs par '-'
    }))

    tableauNbRoom.push(nbRoom)
    tableauNbRoom.push(nbRoomOpt1)
    tableauNbRoom.push(nbRoomOpt2)
    // Copie de l'objet tripactivities en conservant uniquement les valeurs day_index et id_activity

    // Parcourir les tableaux copiés et supprimer les autres clés


    PUT_TRIP_CONTENT_ACCO(token, tripContent[0].id_circuit, tableauAcco, tableauRoom, tableauNbRoom)
  }


  // *********************************************************

  render() {
    const {
      userId,
      validMsg,
      errorMsg,
      isValidated,
      displayFull,
      accoContent,
      accoContentOpt1,
      accoContentOpt2,
      roomContent,
      roomContentOpt1,
      roomContentOpt2,
      nbRoom,
      nbRoomOpt1,
      nbRoomOpt2,
      isModalDisplayed,
      isModalOpenBilling,
      isModalOpenDefaultTraveller,
      dayIndexSelected,
      daySelected,
      isActivityChanged,
      keyTab,
    } = this.state
    const {
      isLoading,
      userAccount,
      tripDetails,
      tripAllDays,
      tripContent,
    } = this.props
    // fit table when resizing window
    const isActionButtonDisabled = !isActivityChanged && !_.isEmpty(tripDetails) && !_.isEmpty(userAccount) ? !isAllowed(userId, userAccount.profil, tripDetails) : true
    const FondDefautIndividual = {
      backgroundColor: '#CB8562',
    };
    const FondDefautGroup = {
      backgroundColor: '#CBC062',
    };
    const classContainer = (isLoading || !displayFull) ? 'container2' : 'container'
    return (
      <div id="main" className={classContainer}>
        <Template>
          <div className="requestContainer">
            <Stepper saveData={dest => this.saveData(dest)} />
            <div className="dividerStepper" />
            {isLoading ? <Loader /> : <div className="loader-null" />}
            <div className={classContainer}>
              <AppBar
                position="static"
                className="tab-bar"
                sx={{ fontWeight: 'bold' }}
              >
                <AntTabs value={keyTab} onChange={this.onChangeTab} aria-label="simple tabs example">
                  <Tab label="Accomodation" {...this.tabProps(0)} />
                  <Tab label="Services" {...this.tabProps(1)} />
                </AntTabs>
              </AppBar>
              <TabPanel value={keyTab} index={0}>
                <CssGrid
                  container
                  spacing={1}
                >
                  <CssGrid item md={4} xs={12}>
                    <div className="dayLineBilling-container">
                      <div className="billingTitleCenter">
                        Accomodation Option 1
                      </div>
                      {accoContent && roomContent && accoContent.map((item, index) => (


                        <DayLineAcco
                             // eslint-disable-next-line react/no-array-index-key
                          key={`cleacco${item.id_hebergement}${item.nom_hebergement}${index}`}
                          item={item}
                          room={roomContent && roomContent[index]}
                          nbRoom={nbRoom && nbRoom[index].split('-')}
                          index={index}
                          updateItemsAcco={(newItem, newIndex, reason, nbOcc) => this.updateItemsAcco(newItem, newIndex, reason, nbOcc)}
                          isModalOpenInChildren={modalState => this.isModalOpenInChildren(modalState)}
                        />
                      ))}
                    </div>

                  </CssGrid>
                  <CssGrid item md={4} xs={12}>

                    <div className="dayLineBilling-container">
                      <div className="billingTitleCenter">
                        Accomodation Option 2
                      </div>
                      {accoContentOpt1 && roomContentOpt1 && accoContentOpt1.map((item, index) => (
                        <DayLineAcco
                            // eslint-disable-next-line react/no-array-index-key
                          key={`cleacco${item.id_hebergement}${item.nom_hebergement}${index}`}
                          item={item}
                          room={roomContentOpt1 && roomContentOpt1[index]}
                          nbRoom={nbRoomOpt1 && nbRoomOpt1[index].split('-')}
                          index={index}
                          updateItemsAcco={(newItem, newIndex, reason, nbOcc) => this.updateItemsAccoOpt1(newItem, newIndex, reason, nbOcc)}
                          isModalOpenInChildren={modalState => this.isModalOpenInChildren(modalState)}
                        />
                      ))}
                    </div>

                  </CssGrid>
                  <CssGrid item md={4} xs={12}>

                    <div className="dayLineBilling-container">
                      <div className="billingTitleCenter">
                        Accomodation Option 3
                      </div>
                      {accoContentOpt2 && roomContentOpt2 && accoContentOpt2.map((item, index) => (
                        <DayLineAcco
                             // eslint-disable-next-line react/no-array-index-key
                          key={`cleacco${item.id_hebergement}${item.nom_hebergement}${index}`}
                          item={item}
                          room={roomContentOpt2 && roomContentOpt2[index]}
                          nbRoom={nbRoomOpt2 && nbRoomOpt2[index].split('-')}
                          index={index}
                          updateItemsAcco={(newItem, newIndex, reason, nbOcc) => this.updateItemsAccoOpt2(newItem, newIndex, reason, nbOcc)}
                          isModalOpenInChildren={modalState => this.isModalOpenInChildren(modalState)}
                        />
                      ))}
                    </div>

                  </CssGrid>
                </CssGrid>
              </TabPanel>
              <TabPanel value={keyTab} index={1}>
                <CssGrid
                  container
                  spacing={1}
                >
                  <CssGrid item md={12} xs={12}>
                    <div className="dayLineBilling-container">
                      <DaySelect
                        dayIndexSelected={dayIndexSelected || 0}
                        daySelected={daySelected || tripAllDays[0]}
                        tripAllDays={tripAllDays}
                        tripContent={tripContent}
                        tripDetails={tripDetails}
                        sendDayIndexSelected={rank => this.sendDayIndexSelected(rank)}
                        sendDaySelected={day => this.sendDaySelected(day)}
                      />
                      <DayLineBilling
                        key="clejour"
                        item={daySelected || tripAllDays[0]}
                        index={dayIndexSelected || 0}
                        tripDetails={tripDetails[0]}
                        isValidated={isValidated}
                        updateItems={newItem => this.updateItems(newItem)}
                        redirectManagement={stateBack => this.redirectManagement(stateBack)}
                        isModalOpenInChildren={modalState => this.isModalOpenInChildren(modalState)}
                        upDateIsActivityChanged={change => this.setState({ isActivityChanged: change })}
                      />
                      <span style={FondDefautIndividual}>&nbsp;&nbsp; </span> <span>Individual</span><span style={FondDefautGroup}>&nbsp;&nbsp; </span> <span>Group</span>

                    </div>
                  </CssGrid>
                </CssGrid>

                {isActionButtonDisabled
                  ? (

                    <ActionButton
                      label="RELOAD DATA ACTIVTIES BEFORE SAVING OR LOAD TRAVELER NUMBER"
                      onClick={e => this.reloadActivites(e)}
                    />
                  )
                  : null}
                <ActionButton
                  label="Load traveller number"
                  onClick={this.handleOpenModalTrav}
                  title="Update ALL INDIVIDUAL service and activities with traveller number of the program details"
                  disabled={_.isEmpty(tripDetails[0].nb_adulte) || isActionButtonDisabled}
                />
              </TabPanel>
              {displayFull
                ? (
                  <ActionButton
                    label="Validate"
                    onClick={e => this.validate(e)}
                    disabled={isActionButtonDisabled}
                  />

                )
                : null}
              {this.displayAuthorizationWarning()}
              <div className="billingContainer">
                {errorMsg ? <div className="errorMsg">{errorMsg}</div> : null}
                {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              </div>
            </div>
          </div>
        </Template>
        <React.Fragment>
          <Prompt
            when={!isValidated && !isModalDisplayed}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
        {isModalOpenBilling && this.displayDisplayModalBilling()}
        {isModalOpenDefaultTraveller && this.displayDisplayModalDefaultTraveller()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.api.isLoading,
  isTripDetailsStepValidated: state.api.isTripDetailsStepValidated,
  idTripSelected: state.api.idTripSelected,
  tripDetails: state.api.tripDetails,
  tripAllDays: state.api.tripAllDays,
  tripAllDaysAccomodations: state.api.tripAllDaysAccomodations,
  putTripTravellerActivity: state.billingReducer.putTripTravellerActivity,
  tripContent: state.api.tripContent,
  tripAccomodation: state.api.tripAccomodation,
  tripAccomodationOpt1: state.api.tripAccomodationOpt1,
  tripAccomodationOpt2: state.api.tripAccomodationOpt2,
  tripSeasonByAccomodation: state.api.tripSeasonByAccomodation,
  tripRoom: state.api.tripRoom,
  tripRoomOpt1: state.api.tripRoomOpt1,
  tripRoomOpt2: state.api.tripRoomOpt2,
  roomList: state.api.roomList,
  pensionList: state.api.pensionList,
  tripPrice: state.billingReducer.tripPrice,
  informationsContent: state.informationsReducer.informationsContent,
  tripPension: state.api.tripPension,
  tripBilling: state.billingReducer.tripBilling,
  tripActivities: state.billingReducer.tripActivities,
  putTripPriceActivity: state.billingReducer.putTripPriceActivity,
  userAccount: state.userAccountReducer.userAccount,
  putTripContentAcco: state.billingReducer.putTripContentAcco,
  seasonByAccomodation: state.api.seasonByAccomodation,
  seasonByActivityAll: state.api.seasonByActivityAll,
})

const mapDispatchToProps = dispatch => ({
  LOADER: isLoading => dispatch({
    type: 'LOADER',
    isLoading,
  }),
  GET_TRIP_BILLING: (token, idDevis) => dispatch({
    type: 'GET_TRIP_BILLING',
    token,
    idDevis,
  }),
  PUT_TRIP_BILLING: (token, idDevis, billingDetails) => dispatch({
    type: 'PUT_TRIP_BILLING',
    token,
    idDevis,
    billingDetails,
  }),
  GET_TRIP_ACCOMODATION: tripAccomodationList => dispatch({
    type: 'GET_TRIP_ACCOMODATION',
    tripAccomodationList,
  }),
  GET_TRIP_ACCOMODATION_OPT1: tripAccomodationList => dispatch({
    type: 'GET_TRIP_ACCOMODATION_OPT1',
    tripAccomodationList,
  }),
  GET_TRIP_ACCOMODATION_OPT2: tripAccomodationList => dispatch({
    type: 'GET_TRIP_ACCOMODATION_OPT2',
    tripAccomodationList,
  }),
  GET_TRIP_ROOM: (tripRoomList, tripAccoList) => dispatch({
    type: 'GET_TRIP_ROOM',
    tripRoomList,
    tripAccoList,
  }),
  GET_TRIP_ROOM_OPT1: (tripRoomList, tripAccoList) => dispatch({
    type: 'GET_TRIP_ROOM_OPT1',
    tripRoomList,
    tripAccoList,
  }),
  GET_TRIP_ROOM_OPT2: (tripRoomList, tripAccoList) => dispatch({
    type: 'GET_TRIP_ROOM_OPT2',
    tripRoomList,
    tripAccoList,
  }),


  RESET_PUT_TRIP_CONTENT_ACCO: () => dispatch({
    type: 'RESET_PUT_TRIP_CONTENT_ACCO',
  }),
  GET_TRIP_DEVIS_FINAL: (token, idDevis) => dispatch({
    type: 'GET_TRIP_DEVIS_FINAL',
    token,
    idDevis,
  }),
  GET_TRIP_CONTENT: idTourSelected => dispatch({
    type: 'GET_TRIP_CONTENT',
    idTourSelected,
  }),
  VALIDATE_STEP: (page, value) => dispatch({
    type: 'VALIDATE_STEP',
    page,
    value,
  }),
  GET_TRIP_ACTIVITIES: (token, idCircuit) => dispatch({
    type: 'GET_TRIP_ACTIVITIES',
    token,
    idCircuit,
  }),
  RESET_PUT_TRIP_ACCOMODATION_ACTIVITY: () => dispatch({
    type: 'RESET_PUT_TRIP_ACCOMODATION_ACTIVITY',
  }),
  PUT_TRIP_CONTENT_ACCO: (token, idCircuit, tableauAcco, tableauRoom, nbRoom) => dispatch({
    type: 'PUT_TRIP_CONTENT_ACCO',
    token,
    idCircuit,
    tableauAcco,
    tableauRoom,
    nbRoom,
  }),
  ACTIVE_STEP: activeStep => dispatch({
    type: 'ACTIVE_STEP',
    activeStep,
  }),
  PUT_TRIP_PRICE_ACTIVITY: (token, priceChange) => dispatch({
    type: 'PUT_TRIP_PRICE_ACTIVITY',
    token,
    priceChange,
  }),
  PUT_TRIP_TRAVELLER_ACTIVITY: (token, tripActivities, nbSenior, nbAdult, nbChild) => dispatch({
    type: 'PUT_TRIP_TRAVELLER_ACTIVITY',
    token,
    tripActivities,
    nbSenior,
    nbAdult,
    nbChild,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TripSuppliers))
