/* eslint-disable react/prefer-stateless-function */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import GenerateHtmlDuyen3 from './component/GenerateHtmlDuyen3';
import GenerateHtmlDuyen from './component/GenerateHtmlDuyen';
import GenerateHtmlHorizonSpecific from './component/GenerateHtmlHorizonSpecific';
import GenerateHtmlTypic from './component/GenerateHtmlTypic';
import GenerateHtmlEvasion from './component/GenerateHtmlEvasion';
import GenerateHtmlAtypique from './component/GenerateHtmlAtypique';
import GenerateHtmlCap from './component/GenerateHtmlCap';
import GenerateHtmlExploration from './component/GenerateHtmlExploration';
import GenerateHtmlExplorationPro from './component/GenerateHtmlExplorationPro';
import GenerateHtmlPanoramic from './component/GenerateHtmlPanoramic';
import GenerateHtmlParfum from './component/GenerateHtmlParfum';
import GenerateHtmlParfum2 from './component/GenerateHtmlParfum2';
import GenerateHtmlCiel from './component/GenerateHtmlCiel';
import GenerateHtmlCielPro from './component/GenerateHtmlCielPro';
import GenerateHtmlCielProWord from './component/GenerateHtmlCielProWord';


class GenerateHtml extends PureComponent {
  static propTypes = {
    informationsContent: PropTypes.object,
    theme: PropTypes.string,
  }

  static defaultProps = {
    informationsContent: {},
    theme: '',
  }


  render() {
    const {
      informationsContent,
      theme,
    } = this.props

    const { theme_devis } = informationsContent
    console.log(theme_devis)
    let displayDevis = 'vide'

    switch (theme_devis) {
      case 'standard':
        displayDevis = <GenerateHtmlDuyen3 />;
        break;
      case 'duyen':
        displayDevis = <GenerateHtmlDuyen />;
        break;
      case 'standard2':
        displayDevis = <GenerateHtmlDuyen3 />;
        break;
      case 'prenium':
        displayDevis = <GenerateHtmlCiel />;
        break;
      case 'panoramic':
        displayDevis = <GenerateHtmlPanoramic />;
        break;
      case 'dream':
        displayDevis = <GenerateHtmlCap />;
        break;
      case 'exploration':
        displayDevis = <GenerateHtmlExploration />;
        break;
      case 'exploration2':
        displayDevis = <GenerateHtmlExplorationPro />;
        break;
      case 'duyen-template':
        displayDevis = <GenerateHtmlDuyen />;
        break;
      case 'Horizon':
        displayDevis = <GenerateHtmlHorizonSpecific />;
        break;
      case 'Ciel2':
        if (theme === 'word') {
          displayDevis = <GenerateHtmlCielProWord />;
        } else {
          displayDevis = <GenerateHtmlCielPro />;
        }
        break;
      case 'Ciel':
        if (theme === 'word') {
          displayDevis = <GenerateHtmlCielProWord />;
        } else {
          displayDevis = <GenerateHtmlCiel />;
        }
        break;
      case 'Typic':
        displayDevis = <GenerateHtmlTypic />;
        break;
      case 'evasion':
        displayDevis = <GenerateHtmlEvasion />;
        break;
      case 'evasionDuyen':
        displayDevis = <GenerateHtmlEvasion />;
        break;
      case 'parfum':
        displayDevis = <GenerateHtmlParfum />;
        break;
      case 'parfum2':
        displayDevis = <GenerateHtmlParfum2 />;
        break;
      case 'atypique':
        displayDevis = <GenerateHtmlAtypique />;
        break;
      default:
        displayDevis = <GenerateHtmlCap />;
    }
    return (


      <div>
        {displayDevis}

      </div>
    );
  }
}


const mapStateToProps = state => ({
  tripDetails: state.api.tripDetails,
  informationsContent: state.informationsReducer.informationsContent,

})

export default withRouter(connect(mapStateToProps)(GenerateHtml))
