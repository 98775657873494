import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import moment from 'moment'
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';


import _, { isEmpty } from 'lodash'
import Spreadsheet from 'react-spreadsheet'
import * as XLSX from 'xlsx';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import checked from '../../assets/images/checked.png'
import checked2x from '../../assets/images/checked@2x.png'
import checked3x from '../../assets/images/checked@3x.png'
import unchecked from '../../assets/images/unchecked.png'
import unchecked2x from '../../assets/images/unchecked@2x.png'
import unchecked3x from '../../assets/images/unchecked@3x.png'

import {
  Template,
  ActionButton,
  Stepper,
  Loader,
  AllowedToChange,
  Input,
  TextEditor,
  ModalComponent,
  TabPanel,
  RedirectionButton,
} from '../../components'

import {
  TableService,
  ReservationListSummary,
} from './components'


import {
  DaySelect,
} from '../tourOperator/component'

import { CssGrid, AntTabs } from '../../configCSS'
import isAllowed from '../../components/utils/profilAuthorization'
import scrollTo from '../../components/utils/scrollTo/scrollTo'

class Billing extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    LOADER: PropTypes.func.isRequired,
    idTripSelected: PropTypes.number,
    ACTIVE_STEP: PropTypes.func.isRequired,
    tripDetails: PropTypes.array,
    tripRoom: PropTypes.array,
    tripAllDays: PropTypes.array,
    tripContent: PropTypes.array,
    tripSeasonByAccomodation: PropTypes.array,
    tripAccomodation: PropTypes.array,
    tripAccomodationOpt1: PropTypes.array,
    tripAccomodationOpt2: PropTypes.array,
    companyContent: PropTypes.object,
    collaborators: PropTypes.array,
    GET_TRIP_ACCOMODATION: PropTypes.func.isRequired,
    GET_TRIP_ACCOMODATION_OPT1: PropTypes.func.isRequired,
    GET_TRIP_ACCOMODATION_OPT2: PropTypes.func.isRequired,
    PUT_TRIP_ACCOMODATION_ACTIVITY: PropTypes.func.isRequired,
    RESET_PUT_TRIP_ACCOMODATION_ACTIVITY: PropTypes.func.isRequired,
    putTripAccomodationActivity: PropTypes.object,
    GET_TRIP_ROOM: PropTypes.func.isRequired,
    GET_TRIP_ROOM_OPT1: PropTypes.func.isRequired,
    GET_TRIP_ROOM_OPT2: PropTypes.func.isRequired,
    PUT_TRIP_PRICE_ACTIVITY: PropTypes.func.isRequired,
    putTripTravellerActivity: PropTypes.object,
    PUT_TRIP_TRAVELLER_ACTIVITY: PropTypes.func.isRequired,
    DELETE_TRIP_ACTIVITY: PropTypes.func.isRequired,
    RESET_DELETE_TRIP_ACTIVITIES: PropTypes.func.isRequired,
    deleteTripActivity: PropTypes.object.isRequired,
    tripRoomOpt1: PropTypes.array,
    tripRoomOpt2: PropTypes.array,
    tripBilling: PropTypes.object,
    tripActivities: PropTypes.object,
    tripPension: PropTypes.array,
    userAccount: PropTypes.object,
    putTripBilling: PropTypes.object,
    putTripPriceActivity: PropTypes.object,
    isTripDetailsStepValidated: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    GET_TRIP_BILLING: PropTypes.func.isRequired,
    GET_TRIP_DEVIS_FINAL: PropTypes.func.isRequired,
    PUT_TRIP_BILLING: PropTypes.func.isRequired,
    RESET_PUT_TRIP_BILLING: PropTypes.func.isRequired,
    VALIDATE_STEP: PropTypes.func.isRequired,
    GET_TRIP_ACTIVITIES: PropTypes.func.isRequired,
    seasonByActivityAll: PropTypes.array,
    informationsContent: PropTypes.object,
  }

  static defaultProps = {
    isLoading: true,
    idTripSelected: null,
    tripAllDays: [],
    seasonByActivityAll: [],
    tripRoom: [],
    tripPension: [],
    tripDetails: [],
    tripContent: [],
    putTripAccomodationActivity: null,
    tripSeasonByAccomodation: [],
    companyContent: {},
    collaborators: [],
    tripAccomodation: [],
    tripAccomodationOpt1: [],
    tripAccomodationOpt2: [],
    tripRoomOpt1: [],
    tripRoomOpt2: [],
    tripBilling: {},
    putTripPriceActivity: {},
    tripActivities: {},
    putTripBilling: null,
    putTripTravellerActivity: null,
    userAccount: {},
    informationsContent: {},
  }

  constructor(props) {
    super(props);
    this.displayAuthorizationWarning = this.displayAuthorizationWarning.bind(this)
    this.getAllTripInformationLoad = this.getAllTripInformationLoad.bind(this)
    this.getAllTripInformationLoadAfterUpdate = this.getAllTripInformationLoadAfterUpdate.bind(this)
    this.displayPriceCalculation = this.displayPriceCalculation.bind(this)
    this.priceCalculation = this.priceCalculation.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.loadAccoInActi = this.loadAccoInActi.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.changePrice = this.changePrice.bind(this)
    this.validate = this.validate.bind(this)
    this.updateQueryStatusMsg = this.updateQueryStatusMsg.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.resetDisplay = this.resetDisplay.bind(this)
    this.updateItems = this.updateItems.bind(this)
    this.isModalOpenInChildren = this.isModalOpenInChildren.bind(this)
    this.displayDisplayModalBilling = this.displayDisplayModalBilling.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleOpenTraveller = this.handleOpenTraveller.bind(this)


    this.state = {
      userId: localStorage.getItem('userId'),
      token: localStorage.getItem('token'),
      isModalDisplayed: false,
      daySelected: null,
      dayIndexSelected: null,
      errorMsg: null,
      keyTab: 0,
      validMsg: null,
      isValidated: true,
      isModalOpenBilling: false,
      tripBillingEditor: {},
      tripBillingEditorCheck: [],
      displayFull: true,
      isActivityChanged: false,
      billingContent: '',
      totalPrice: '',
      totalPriceAccomodation: '',
      totalPriceGroup: '',
      totalPriceIndAdult: '',
      totalPriceIndChild: '',
      totalPriceIndSenior: '',
      dataCotationDisplay: '',
      dataCotationDisplayAdult: '',
      dataCotationDisplayIndividualChild: '',
      dataCotationDisplayIndividualSenior: '',
      dataCotationDisplayAcco: '',
      dataCotationDisplayGroup: '',
      accoContent: '',
      accoContentOpt1: '',
      accoContentOpt2: '',
      roomContent: '',
      roomContentOpt1: '',
      roomContentOpt2: '',
      nbRoom: '',
      nbRoomOpt1: '',
      nbRoomOpt2: '',
      itemToChange: {},
      daysToChange: 0,
      optToChange: 0,
      reasonOfChange: '',
      indexToChange: 0,
    };
  }

  componentDidMount() {
    const {
      history,
      LOADER,
      isTripDetailsStepValidated,
      tripBilling,
      tripContent,
      tripActivities,
      tripAccomodation,
      tripAccomodationOpt1,
      tripAccomodationOpt2,
      tripRoom,
      tripRoomOpt1,
      tripRoomOpt2,
      GET_TRIP_ACTIVITIES,
      ACTIVE_STEP,
    } = this.props
    const { token } = this.state
    // check if user is logged

    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (!_.isEmpty(tripContent)) {
      LOADER(true)
      this.getAllTripInformationLoad(tripContent)
    }

    ACTIVE_STEP('billing')

    if (_.isEmpty(tripActivities) && !_.isEmpty(tripContent)) {
      GET_TRIP_ACTIVITIES(token, tripContent[0].id_circuit)
    }
    // redirect if preview step is not active
    if (!isTripDetailsStepValidated) {
      history.push('trip')
    } else if (!_.isEmpty(tripBilling)) { // build table then fill it then build table price
      this.setState({
        tripBillingEditor: {
          tabPrice: tripBilling.tabPrice,
        },
      })
      this.setState({
        tripBillingEditorCheck: {
          displayTabCheck: tripBilling.displayTabCheck,
        },
      })
      this.setState({
        billingContent: tripBilling,
        accoContent: tripAccomodation,
        accoContentOpt1: tripAccomodationOpt1,
        accoContentOpt2: tripAccomodationOpt2,
        roomContent: tripRoom,
        roomContentOpt1: tripRoomOpt1,
        roomContentOpt2: tripRoomOpt2,
        nbRoom: tripContent[0].nb_chambre.split(','),
        nbRoomOpt1: tripContent[0].nb_chambre_opt1.split(','),
        nbRoomOpt2: tripContent[0].nb_chambre_opt2.split(','),
      })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      accoContent,
      accoContentOpt1,
      accoContentOpt2,
      roomContent,
      roomContentOpt1,
      roomContentOpt2,
      nbRoom,
      nbRoomOpt1,
      nbRoomOpt2,
    } = prevState
    const {
      tripAccomodation,
      tripAccomodationOpt1,
      tripAccomodationOpt2,
      tripRoom,
      tripRoomOpt1,
      tripRoomOpt2,
      tripContent,
    } = nextProps
    if (tripAccomodation && isEmpty(accoContent)) {
      return {
        accoContent: tripAccomodation,
      }
    }
    if (tripAccomodationOpt1 && isEmpty(accoContentOpt1)) {
      return {
        accoContentOpt1: tripAccomodationOpt1,
      }
    }
    if (tripAccomodationOpt2 && isEmpty(accoContentOpt2)) {
      return {
        accoContentOpt2: tripAccomodationOpt2,
      }
    }
    if (tripRoom && isEmpty(roomContent)) {
      return {
        roomContent: tripRoom,
      }
    }
    if (tripRoomOpt1 && isEmpty(roomContentOpt1)) {
      return {
        roomContentOpt1: tripRoomOpt1,
      }
    }
    if (tripRoomOpt2 && isEmpty(roomContentOpt2)) {
      return {
        roomContentOpt2: tripRoomOpt2,
      }
    }
    if (tripContent && isEmpty(nbRoom)) {
      return {
        nbRoom: tripContent[0].nb_chambre.split(','),
      }
    }
    if (tripContent && isEmpty(nbRoomOpt1)) {
      return {
        nbRoomOpt1: tripContent[0].nb_chambre_opt1.split(','),
      }
    }
    if (tripContent && isEmpty(nbRoomOpt2)) {
      return {
        nbRoomOpt2: tripContent[0].nb_chambre_opt2.split(','),
      }
    }
    return null
  }

  componentDidUpdate(prevProps) {
    const { token } = this.state
    const {
      LOADER,
      idTripSelected,
      putTripBilling,
      putTripTravellerActivity,
      putTripPriceActivity,
      GET_TRIP_BILLING,
      RESET_PUT_TRIP_BILLING,
      GET_TRIP_DEVIS_FINAL,
      tripAccomodation,
      tripAccomodationOpt1,
      tripAccomodationOpt2,
      tripRoom,
      tripRoomOpt1,
      tripActivities,
      tripRoomOpt2,
      tripDetails,
      RESET_PUT_TRIP_ACCOMODATION_ACTIVITY,
      VALIDATE_STEP,
      GET_TRIP_ACTIVITIES,
      tripContent,
      RESET_DELETE_TRIP_ACTIVITIES,
      deleteTripActivity,
      putTripAccomodationActivity,
      isLoading,
    } = this.props
    const {
      tripContent: tripContentOld,
      tripActivities: tripActivitiesOld,
    } = prevProps
    if (putTripBilling) {
      this.updateQueryStatusMsg(putTripBilling.success, putTripBilling.queryStatusMsg)
      this.resetValidMsg()
      VALIDATE_STEP('Billing', true)
      RESET_PUT_TRIP_BILLING()
      // reset tripDevisFinal
      GET_TRIP_DEVIS_FINAL(token, tripDetails[0].id_devis)
      GET_TRIP_BILLING(token, idTripSelected)
    }

    if (putTripTravellerActivity && !_.isEmpty(putTripTravellerActivity) && 'success' in putTripTravellerActivity) {
      GET_TRIP_ACTIVITIES(token, tripContent[0].id_circuit)
      RESET_PUT_TRIP_ACCOMODATION_ACTIVITY()
      this.resetDisplay()
    }
    if (putTripPriceActivity && putTripPriceActivity.success) {
      LOADER(true)
      GET_TRIP_ACTIVITIES(token, tripContent[0].id_circuit)
      RESET_PUT_TRIP_ACCOMODATION_ACTIVITY()
    }
    if (!_.isEqual(tripContent, tripContentOld)) {
      this.getAllTripInformationLoadAfterUpdate(tripContent)
      LOADER(false)
    }
    if (!_.isEmpty(deleteTripActivity) && 'success' in deleteTripActivity) {
      GET_TRIP_ACTIVITIES(token, tripContent[0].id_circuit)
      RESET_DELETE_TRIP_ACTIVITIES()
    }
    if (putTripAccomodationActivity && putTripAccomodationActivity.success) {
      LOADER(false)
      GET_TRIP_ACTIVITIES(token, tripContent[0].id_circuit)
      RESET_PUT_TRIP_ACCOMODATION_ACTIVITY()
    }


    if (!_.isEqual(tripActivities, tripActivitiesOld)) {
      this.resetDisplay()
    }

    // check if all informations have been received adn valid step
    if (isLoading && tripAccomodation && tripAccomodationOpt1 && tripAccomodationOpt2
        && tripRoom && tripRoomOpt1 && tripRoomOpt2
    ) {
      LOADER(false)
    }
  }

  componentWillUnmount() {
    const {
      ACTIVE_STEP,
    } = this.props

    ACTIVE_STEP('')
  }

  async getAllTripInformationLoad(content, message = false) {
    const {
      GET_TRIP_ACCOMODATION,
      GET_TRIP_ACCOMODATION_OPT1,
      GET_TRIP_ACCOMODATION_OPT2,
      GET_TRIP_ROOM,
      GET_TRIP_ROOM_OPT1,
      GET_TRIP_ROOM_OPT2,
      tripAccomodation,
      tripAccomodationOpt1,
      tripAccomodationOpt2,
      tripRoom,
      tripRoomOpt1,
      tripRoomOpt2,
    } = this.props
    // get trip informations

    if (!_.isEmpty(content) && content[0].journee && content[0].hebergement && content[0].hebergement_opt && content[0].hebergement_opt1 && content[0].hebergement_opt2 && content[0].pension && content[0].type_chambre) {
      try {
        if (_.isEmpty(tripAccomodation)) await GET_TRIP_ACCOMODATION(content[0].hebergement)
        if (_.isEmpty(tripAccomodationOpt1)) await GET_TRIP_ACCOMODATION_OPT1(content[0].hebergement_opt1)
        if (_.isEmpty(tripAccomodationOpt2)) await GET_TRIP_ACCOMODATION_OPT2(content[0].hebergement_opt2)
        if (_.isEmpty(tripRoom)) await GET_TRIP_ROOM(content[0].type_chambre, content[0].hebergement)
        if (_.isEmpty(tripRoomOpt1)) await GET_TRIP_ROOM_OPT1(content[0].type_chambre_opt1, content[0].hebergement_opt1)
        if (_.isEmpty(tripRoomOpt2)) await GET_TRIP_ROOM_OPT2(content[0].type_chambre_opt2, content[0].hebergement_opt2)


        if (message) {
          this.setState({
            isValidated: true,
            validMsg: 'Changes were successfully recorded !',
          }, () => this.resetValidMsg())
        }
      } catch (error) {
        console.log('Route await error', error)
      }
    }
    return null
  }

  async getAllTripInformationLoadAfterUpdate(content, message = false) {
    const {
      GET_TRIP_ACCOMODATION,
      GET_TRIP_ACCOMODATION_OPT1,
      GET_TRIP_ACCOMODATION_OPT2,
      GET_TRIP_ROOM,
      GET_TRIP_ROOM_OPT1,
      GET_TRIP_ROOM_OPT2,
      LOADER,
    } = this.props
    // get trip informations
    LOADER(true)
    if (!_.isEmpty(content) && content[0].journee && content[0].hebergement && content[0].hebergement_opt && content[0].hebergement_opt1 && content[0].hebergement_opt2 && content[0].pension && content[0].type_chambre) {
      try {
        await GET_TRIP_ACCOMODATION(content[0].hebergement)
        await GET_TRIP_ACCOMODATION_OPT1(content[0].hebergement_opt1)
        await GET_TRIP_ACCOMODATION_OPT2(content[0].hebergement_opt2)
        await GET_TRIP_ROOM(content[0].type_chambre, content[0].hebergement)
        await GET_TRIP_ROOM_OPT1(content[0].type_chambre_opt1, content[0].hebergement_opt1)
        await GET_TRIP_ROOM_OPT2(content[0].type_chambre_opt2, content[0].hebergement_opt2)


        if (message) {
          this.setState({
            isValidated: true,
            validMsg: 'Changes were successfully recorded !',
          }, () => this.resetValidMsg())
        }
      } catch (error) {
        console.log('Route await error', error)
      }
    }
    return null
  }

    // TABS
    onChangeTab = (event, keyTab) => {
      this.setState({
        keyTab,
      })
    }

    tabProps = index => ({
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    })


  // Message management **********************
  updateQueryStatusMsg = (isValid, msg) => {
    this.setState({
      validMsg: isValid ? msg : null,
      errorMsg: isValid ? null : msg,
      isValidated: true,
    })
  }

  resetValidMsg =() => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  resetDisplay =() => {
    this.setState({
      displayFull: true,
    })
  }

  loadAccoInActi = () => {
    const {
      PUT_TRIP_ACCOMODATION_ACTIVITY,
      tripAllDays,
      tripAccomodation,
      tripAccomodationOpt1,
      tripAccomodationOpt2,
      tripBilling,
      tripContent,
      LOADER,
    } = this.props
    let tripSelect = ''
    switch (tripBilling.accoChoice) {
      case 1:
        tripSelect = tripAccomodation
        break;
      case 2:
        tripSelect = tripAccomodationOpt1
        break;
      case 3:
        tripSelect = tripAccomodationOpt2
        break;
      default:
        tripSelect = tripAccomodation
    }
    const dayIndex = tripAllDays.map((objet, index) => ({
      dayIndex: objet.dayIndex,
      id_journee: objet.id_journee,
      id_acco: tripSelect[index].id_hebergement,
    }));
    const {
      token,
    } = this.state
    LOADER(true)
    PUT_TRIP_ACCOMODATION_ACTIVITY(token, dayIndex, '', tripContent[0].id_circuit, '')
  }


  isModalOpenInChildren = (state) => {
    this.setState({ isModalDisplayed: state })
  }

  sendDayIndexSelected = (rank) => {
    this.setState({
      dayIndexSelected: rank,
    })
  }

  sendDaySelected = (day) => {
    this.setState({
      daySelected: day,
    })
  }


 exportToExcel = (dataCotationDisplay) => {
   const {
     tripDetails,
   } = this.props
   const exelName = `${tripDetails[0].nom_devis}-datacotation.xlsx`
   const dataCotationDisplayAdultXLSX = dataCotationDisplay.map(row => row.map(cell => cell.value));
   const ws = XLSX.utils.aoa_to_sheet(dataCotationDisplayAdultXLSX);
   const wb = XLSX.utils.book_new();
   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
   const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
   const url = URL.createObjectURL(data);
   const a = document.createElement('a');
   a.href = url;
   a.download = exelName;
   a.click();
 };


  handleEditorChange = field => (content) => {
    this.setState({
      tripBillingEditor: {
        [field]: content,
      },
      isValidated: false,
    });
  }

    // save input change
    handleChange = field => (event) => {
      const { billingContent } = this.state
      const valueBenefit = field === 'benefit' ? event.target.value : billingContent.benefit
      const valueTripCost = field === 'tripCost' ? event.target.value : billingContent.tripCost
      const valueDiscountUnit = field === 'discountUnit' ? event.target.value : billingContent.discountUnit

      if (field.startsWith('exchangeRate')) {
        this.setState({
          totalPrice: '',
        })
      }
      if (field === 'benefit' || field === 'tripCost' || field === 'discountUnit') {
        const tripCost = parseFloat(valueTripCost);
        const benef = parseFloat(valueBenefit);
        const sellingPriceCalc = valueDiscountUnit === '%' ? tripCost + tripCost * benef / 100 : tripCost + benef;
        this.setState({
          billingContent: {
            ...billingContent,
            [field]: event.target.value,
            sellingPrice: sellingPriceCalc,
          },
        })
      } else {
        this.setState({
          billingContent: {
            ...billingContent,
            [field]: field === 'accoChoice' ? Number(event.target.value) : event.target.value, // Conversion en nombre pour accoChoice
          },
          isValidated: false,
        })
      }
    }

    handleDefaultChange = field => () => {
      const { informationsContent, userAccount } = this.props
      let source = ''
      switch (field) {
        case 'tabPrice':
          source = `${userAccount.language}_information_2_description`
          break;
        case 'tabPrice2':
          source = `${userAccount.language}_information_5_description`
          break;
        default:
          source = ''
      }
      this.setState({
        tripBillingEditor: {
          tabPrice: informationsContent[source],
        },
        isValidated: false,
      });
    }

    handleClose = () => {
      const { scrollY } = window;
      this.setState({
        isModalOpenBilling: false,
      });
      setTimeout(() => {
        window.scrollTo(0, scrollY);
      }, 0);
    };

    changePrice = (priceChange) => {
      const {
        token,
      } = this.state
      const {
        PUT_TRIP_PRICE_ACTIVITY,
      } = this.props
      PUT_TRIP_PRICE_ACTIVITY(token, priceChange)
      this.setState({
        displayFull: false,
        totalPrice: '',
        totalPriceAccomodation: '',
        totalPriceGroup: '',
        totalPriceIndAdult: '',
        totalPriceIndChild: '',
        totalPriceIndSenior: '',
        dataCotationDisplay: '',
        dataCotationDisplayAdult: '',
        dataCotationDisplayIndividualChild: '',
        dataCotationDisplayIndividualSenior: '',
        dataCotationDisplayAcco: '',
        dataCotationDisplayGroup: '',
      });
    };

    deleteActivity = (actToDelete) => {
      const {
        DELETE_TRIP_ACTIVITY,
      } = this.props
      const {
        token,
      } = this.state
      DELETE_TRIP_ACTIVITY(token, actToDelete.id_act_cir)
      this.setState({
        displayFull: false,
      })
    }

    handleOpen = () => {
      const {
        daysToChange,
        itemToChange,
        accoContent,
        accoContentOpt2,
        accoContentOpt1,
        roomContent,
        roomContentOpt1,
        roomContentOpt2,
        nbRoom,
        nbRoomOpt1,
        nbRoomOpt2,
        optToChange,
        reasonOfChange,
        indexToChange,
      } = this.state
      if (reasonOfChange === 'accoChange') {
        if (optToChange === 0) {
          const updatedAccoContent = accoContent.map((element, index) => {
            if (daysToChange.includes(index)) {
              return { ...itemToChange };
            }
            return { ...element };
          });
          const updatedRoomContent = roomContent
          daysToChange.forEach((index) => {
            // Utiliser la déstructuration d'array pour extraire la première entrée de itemToChange
            updatedRoomContent[index] = [];
          });
          const updatedNbRoomContent = nbRoom
          daysToChange.forEach((index) => {
            updatedNbRoomContent[index] = '1';
          });
          this.setState({
            accoContent: updatedAccoContent,
            roomContent: updatedRoomContent,
            nbRoom: updatedNbRoomContent,
          });
        }
        if (optToChange === 1) {
          const updatedAccoContent = accoContentOpt1.map((element, index) => {
            if (daysToChange.includes(index)) {
              return { ...itemToChange };
            }
            return { ...element };
          });
          const updatedRoomContent = roomContentOpt1
          daysToChange.forEach((index) => {
            // Utiliser la déstructuration d'array pour extraire la première entrée de itemToChange
            updatedRoomContent[index] = [];
          });
          const updatedNbRoomContent = nbRoomOpt1
          daysToChange.forEach((index) => {
            updatedNbRoomContent[index] = '1';
          });
          this.setState({
            accoContentOpt1: updatedAccoContent,
            roomContentOpt1: updatedRoomContent,
            nbRoomOpt1: updatedNbRoomContent,
          });
        }
        if (optToChange === 2) {
          const updatedAccoContent = accoContentOpt2.map((element, index) => {
            if (daysToChange.includes(index)) {
              return { ...itemToChange };
            }
            return { ...element };
          });
          const updatedRoomContent = roomContentOpt2
          daysToChange.forEach((index) => {
            // Utiliser la déstructuration d'array pour extraire la première entrée de itemToChange
            updatedRoomContent[index] = [];
          });
          const updatedNbRoomContent = nbRoomOpt2
          daysToChange.forEach((index) => {
            updatedNbRoomContent[index] = '1';
          });
          this.setState({
            accoContentOpt2: updatedAccoContent,
            roomContentOpt2: updatedRoomContent,
            nbRoomOpt2: updatedNbRoomContent,
          });
        }
      }
      if (reasonOfChange === 'nbRoomChange') {
        const stringItem = _.join(itemToChange, '-')
        if (optToChange === 0) {
          const updatedRoomContent = roomContent
          const roomToChange = roomContent[indexToChange]
          daysToChange.forEach((index) => {
            // Utiliser la déstructuration d'array pour extraire la première entrée de itemToChange
            updatedRoomContent[index] = roomToChange;
          });
          const updatedNbRoomContent = nbRoom
          daysToChange.forEach((index) => {
            updatedNbRoomContent[index] = stringItem;
          });
          this.setState({
            roomContent: updatedRoomContent,
            nbRoom: updatedNbRoomContent,
          });
        }
        if (optToChange === 1) {
          const updatedRoomContent = roomContentOpt1
          const roomToChange = roomContentOpt1[indexToChange]
          daysToChange.forEach((index) => {
            // Utiliser la déstructuration d'array pour extraire la première entrée de itemToChange
            updatedRoomContent[index] = roomToChange;
          });
          const updatedNbRoomContent = nbRoomOpt1
          daysToChange.forEach((index) => {
            updatedNbRoomContent[index] = stringItem;
          });
          this.setState({
            roomContentOpt1: updatedRoomContent,
            nbRoomOpt1: updatedNbRoomContent,
          });
        }
        if (optToChange === 2) {
          const updatedRoomContent = roomContentOpt2
          const roomToChange = roomContentOpt2[indexToChange]
          daysToChange.forEach((index) => {
            // Utiliser la déstructuration d'array pour extraire la première entrée de itemToChange
            updatedRoomContent[index] = roomToChange;
          });
          const updatedNbRoomContent = nbRoomOpt2
          daysToChange.forEach((index) => {
            updatedNbRoomContent[index] = stringItem;
          });
          this.setState({
            roomContentOpt2: updatedRoomContent,
            nbRoomOpt2: updatedNbRoomContent,
          });
        }
      }
      this.setState({
        isModalOpenBilling: false,
      });
    }

    displayDisplayModalBilling = () => {
      const {
        isModalOpenBilling,
      } = this.state
      return (
        <ModalComponent
          isModalOpen={isModalOpenBilling}
          onClose={this.handleClose}
          onResponseNo={this.handleClose}
          onResponseYes={this.handleOpen}
          title="Other days ends at the same location, do you also want to update for these location?"
        />
      )
    }

    handleOpenTraveller = () => {
      const {
        PUT_TRIP_TRAVELLER_ACTIVITY,
        tripDetails,
        tripActivities,
      } = this.props

      const {
        token,
      } = this.state

      const {
        nb_senior,
        nb_adulte,
        nb_enfant,
      } = tripDetails[0]
      PUT_TRIP_TRAVELLER_ACTIVITY(token, tripActivities, nb_senior, nb_adulte, nb_enfant)
      this.setState({
        displayFull: false,
      });
    }


  handleCheck = field => () => {
    const { tripBillingEditorCheck } = this.state

    this.setState({
      tripBillingEditorCheck: {
        ...tripBillingEditorCheck,
        [field]: tripBillingEditorCheck[field] === 1 ? 0 : 1,
      },
      isValidated: false,
    });
  }

  updateItems = (item) => {
    // replace item when update
    const {
      tripAllDays,
    } = this.props

    const indexItem = _.findIndex(tripAllDays, o => o.id_journee === item.id_journee)
    _.map(tripAllDays, (o, index) => {
      if (index === indexItem) {
        return item
      }
      return o
    })

    // this.setState({
    //   items: newItems,
    //   isValidated: false,
    // })
  }


  displayAuthorizationWarning = () => {
    const {
      tripDetails,
      userAccount,
    } = this.props
    const {
      userId,
    } = this.state
    return (
      <AllowedToChange
        userId={userId}
        profil={userAccount.profil}
        tripDetails={tripDetails}
      />
    )
  }

  priceCalculation = (e) => {
    e.preventDefault()
    const {
      billingContent,
    } = this.state
    const {
      tripDetails,
      tripAllDays,
      tripRoom,
      tripContent,
      tripAccomodation,
      tripPension,
      tripSeasonByAccomodation,
      tripActivities,
      seasonByActivityAll,
    } = this.props

    const themeList = [
      { id_activity_theme: '', activity_theme: '' },
      { id_activity_theme: 1, activity_theme: 'Accomodation' },
      { id_activity_theme: 2, activity_theme: 'Food' },
      { id_activity_theme: 3, activity_theme: 'Transportation' },
      { id_activity_theme: 4, activity_theme: 'Activity' },
      { id_activity_theme: 5, activity_theme: 'Service guide' },
      { id_activity_theme: 6, activity_theme: 'Flight' },
      { id_activity_theme: 7, activity_theme: 'Extra' },
      { id_activity_theme: 8, activity_theme: 'Other' },
    ]
    const tripActivitiesNoAccom = Object.keys(tripActivities).reduce((acc, key) => {
      acc[key] = tripActivities[key].filter(activity => activity.id_activity !== 75);
      return acc;
    }, {});
    const tripActivitiesGroup = Object.keys(tripActivitiesNoAccom).reduce((acc, key) => {
      acc[key] = tripActivitiesNoAccom[key].filter(activity => activity.activity_type === 'group');
      return acc;
    }, {});
    const tripActivitiesIndividual = Object.keys(tripActivitiesNoAccom).reduce((acc, key) => {
      acc[key] = tripActivitiesNoAccom[key].filter(activity => activity.activity_type === 'individual');
      return acc;
    }, {});

    const TabIncJour = tripAllDays ? tripAllDays.map(item => (item.day_duration)) : []
    const roomTypeArray = tripContent ? tripContent[0].type_chambre.split(',') : []
    const roomsNumberArray = tripContent && tripContent[0].nb_chambre ? tripContent[0].nb_chambre.split(',') : null
    // determinate the header, isolate the unique value on activity_theme AND currency

    const uniqueThemeCurrencyActivity = Object.values(tripActivitiesNoAccom)
      .flat()
      .reduce((acc, item) => {
        if (item.id_activity_theme) {
          const themeName = themeList.filter(tH => tH.id_activity_theme === item.id_activity_theme)
          const pair = `${item.id_activity_theme ? themeName[0].activity_theme : '[no_theme]'}(${item.currency ? item.currency : '[no_currency]'})`;
          if (!acc.includes(pair)) {
            acc.push(pair);
          }
        } else {
          const { currency } = item;
          if (!acc.includes(currency)) {
            acc.push(currency);
          }
        }
        return acc;
      }, []);


    const uniqueCurrencies = [...new Set(Object.values(tripActivitiesNoAccom)
      .flat()
      .map(item => item.currency))];

    // determinate the header for the currency
    const tripAccomodationNoNone = tripAccomodation.filter(tAD => tAD.id_hebergement !== 1)
    const currencyHeb = tripAccomodationNoNone.map(item => (item.currency))
    const currencyHebSansDoublons = _.uniq(currencyHeb);
    const uniqueCurrenciesHebAct = _.uniq(uniqueCurrencies.concat(currencyHebSansDoublons))


    const nbOfRoomLabel = (index) => {
      const roomsTypeForDay = roomTypeArray[index] && roomTypeArray[index] !== '1' ? roomTypeArray[index].split('-') : []
      const roomsNbForDay = roomsNumberArray && roomsNumberArray[index] !== 'null' ? roomsNumberArray[index].split('-') : []
      const roomsNameForDay = roomsTypeForDay.map(rT => (tripRoom[index] && !_.isEmpty(tripRoom[index]) ? tripRoom[index].find(r => r.id_heb_ch === parseInt(rT, 10)).type_chambre : 'none'))
      const label = _.isEmpty(roomsNbForDay) ? [] : roomsNameForDay.map((r, j) => `${r} (x${roomsNbForDay[j]})${roomsNameForDay.length === j + 1 ? '' : ', '}`)
      return label.join(''); // Utilisation de slice() pour retirer la dernière virgule
    }

    // creation du tableau de cotation
    const dataCotation = [
      [{ value: 'Day' }, { value: 'date' }, { value: 'title' }, { value: 'Accomodation' }, { value: 'Room type' }, { value: 'Transport' }, { value: 'Guide' }, { value: 'Driver' }, { value: 'Pension' }],
    ]
    const dataCotationAcco = [
      [{ value: 'Day' }, { value: 'date' }, { value: 'title' }, { value: 'Accomodation' }, { value: 'Room type' }],
    ]
    const dataCotationSimple = [
      [{ value: 'Day' }, { value: 'date' }, { value: 'title' }],
    ]
    const newDataCotation = _.cloneDeep(dataCotation)
    const newDataCotationGroup = _.cloneDeep(dataCotationSimple)
    const newDataCotationAcco = _.cloneDeep(dataCotationAcco)
    const newDataCotationIndividualAdult = _.cloneDeep(dataCotationSimple)
    const newDataCotationIndividualChild = _.cloneDeep(dataCotationSimple)
    const newDataCotationIndividualSenior = _.cloneDeep(dataCotationSimple)
    // ajout des valeurs propsée de currency dans l entete des colonne

    currencyHebSansDoublons.forEach((value) => {
      newDataCotation[0].push({ value: `accomodation (${value || 'no currency define'})` });
      newDataCotationAcco[0].push({ value: `accomodation (${value || 'no currency define'})` });
    });
    uniqueThemeCurrencyActivity.forEach((value) => {
      newDataCotation[0].push({ value: value });
    });
    const uniqueThemeCurrencyActivityGroup = Object.values(tripActivitiesGroup)
      .flat()
      .reduce((acc, item) => {
        if (item.id_activity_theme) {
          const themeName = themeList.filter(tH => tH.id_activity_theme === item.id_activity_theme)
          const pair = `${item.id_activity_theme ? themeName[0].activity_theme : '[no_theme]'}(${item.currency ? item.currency : '[no_currency]'})`;
          if (!acc.includes(pair)) {
            acc.push(pair);
          }
        } else {
          const { currency } = item;
          if (!acc.includes(currency)) {
            acc.push(currency);
          }
        }
        return acc;
      }, []);

    uniqueThemeCurrencyActivityGroup.forEach((value) => {
      newDataCotationGroup[0].push({ value: value });
    });
    const uniqueThemeCurrencyActivityIndividual = Object.values(tripActivitiesIndividual)
      .flat()
      .reduce((acc, item) => {
        if (item.id_activity_theme) {
          const themeName = themeList.filter(tH => tH.id_activity_theme === item.id_activity_theme)
          const pair = `${item.id_activity_theme ? themeName[0].activity_theme : '[no_theme]'}(${item.currency ? item.currency : '[no_currency]'})`;
          if (!acc.includes(pair)) {
            acc.push(pair);
          }
        } else {
          const { currency } = item;
          if (!acc.includes(currency)) {
            acc.push(currency);
          }
        }
        return acc;
      }, []);
    uniqueThemeCurrencyActivityIndividual.forEach((value) => {
      newDataCotationIndividualAdult[0].push({ value: value });
      newDataCotationIndividualChild[0].push({ value: value });
      newDataCotationIndividualSenior[0].push({ value: value });
    });


    let totalPrice1 = 0
    let totalPriceGroup = 0
    let totalPriceIndAdult = 0
    let totalPriceIndChild = 0
    let totalPriceIndSenior = 0
    let totalPriceAccomodation = 0

    _.map(tripAllDays, (o, index) => {
      const IncDay = _.sum(TabIncJour.slice(0, index))
      const activitiesOfDay = tripActivitiesNoAccom[index] ? [...tripActivitiesNoAccom[index]] : [];
      const activitiesOfDayGroup = tripActivitiesGroup[index] ? [...tripActivitiesGroup[index]] : [];

      const datejour = tripDetails[0].date_depart ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').add(IncDay, 'd').format('dddd DD MMMM YYYY'))
        : ''
      const guideTab = tripContent[0].guide.split(',')
      const driverTab = tripContent[0].chauffeur.split(',')
      const PriceRoom = tripRoom[index]
      const roomsNbForDay = roomsNumberArray && roomsNumberArray[index] !== 'null' ? roomsNumberArray[index].split('-') : []
      const seasonByAccomodation = tripSeasonByAccomodation.filter(c => c.id_hebergement === tripAccomodation[index].id_hebergement)
      let datejour1 = tripDetails[0].number_day_departure ? tripDetails[0].number_day_departure + IncDay : ''
      const annee = _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').add(index, 'days').format('YYYY'))
      const ily = ((annee % 4 === 0) && (annee % 100 !== 0)) || (annee % 400 === 0);
      let seasonName = ''
      let PriceRoomCalculated = 0
      let PriceRoomCalculatedForDisplay = ''

      // check pour annee bisextilz

      if (ily && (datejour1 > 59)) (datejour1 -= 1)
      // verif de la saison

      if (!_.isEmpty(seasonByAccomodation)) {
        seasonByAccomodation.map((sBA) => {
          if (datejour1 >= sBA.number_day_start && datejour1 <= sBA.number_day_end) {
            seasonName = `price_${sBA.period_name}`
          }
          return null
        })
      }
      if (PriceRoom) {
        PriceRoom.map((PR, index2) => {
          if (PR[`${seasonName}`] && PR[`${seasonName}`] !== 0) {
            PriceRoomCalculated += (PR[`${seasonName}`] * roomsNbForDay[index2] * (o.day_duration ? o.day_duration : 1))
            PriceRoomCalculatedForDisplay = `${PriceRoomCalculatedForDisplay} + ${(PR[`${seasonName}`] * roomsNbForDay[index2] * (o.day_duration ? o.day_duration : 1))}`
          } else {
            PriceRoomCalculated += (PR.price_peakseason * roomsNbForDay[index2] * (o.day_duration ? o.day_duration : 1))
            PriceRoomCalculatedForDisplay = `${PriceRoomCalculatedForDisplay} + ${(PR.price_peakseason * roomsNbForDay[index2] * (o.day_duration ? o.day_duration : 1))}`
          }
          return null
        })
      }
      PriceRoomCalculatedForDisplay = PriceRoomCalculatedForDisplay.slice(3);
      // filter the activity accomodation
      const tripActivityAcco = tripActivities[index].filter(tA => tA.id_activity === 75)
      // check if a price have been define on the activity by day table if yes put this price
      PriceRoomCalculated = tripActivityAcco[0].final_price !== null ? tripActivityAcco[0].final_price : PriceRoomCalculated
      uniqueCurrenciesHebAct.map((uC, ic) => {
        if (uC === tripAccomodation[index].currency) {
          totalPrice1 += PriceRoomCalculated * billingContent[`exchangeRate${ic + 1}`]
          totalPriceAccomodation += PriceRoomCalculated * billingContent[`exchangeRate${ic + 1}`]
        }
        return null
      })
      // rajoute le prix en fonction de la currency

      const valueToAdd = [{ value: index + 1 }, { value: datejour }, { value: o.nom_journee }, { value: tripAccomodation[index].nom_hebergemen }, { value: nbOfRoomLabel(index) }, { value: o.transport }, { value: guideTab[index] }, { value: driverTab[index] }, { value: tripPension[index].pension }]
      const valueToAddAcco = [{ value: index + 1 }, { value: datejour }, { value: o.nom_journee }, { value: tripAccomodation[index].nom_hebergemen }, { value: nbOfRoomLabel(index) }]
      currencyHebSansDoublons.map((cHSD) => {
        if (tripAccomodation[index].currency === cHSD) {
          valueToAdd.push({ value: PriceRoomCalculated })
          valueToAddAcco.push({ value: tripActivityAcco[0].final_price !== null ? tripActivityAcco[0].final_price : PriceRoomCalculatedForDisplay })
        } else {
          valueToAdd.push({ value: '' })
          valueToAddAcco.push({ value: '' })
        }
        return null
      });
      // price management for activities ALL
      if (activitiesOfDay) {
        activitiesOfDay.map((aOD) => {
          const seasonByActivities = seasonByActivityAll.filter(sBA => sBA.id_activity === aOD.id_activity)
          let priceSeasonNameSenior = 0
          let priceSeasonNameAdult = 0
          let priceSeasonNameChild = 0
          let isSeasonFound = false;
          if (!_.isEmpty(seasonByActivities)) {
            seasonByActivities.map((sBA) => {
              if (!isSeasonFound && datejour1 >= sBA.number_day_start && datejour1 <= sBA.number_day_end) {
                priceSeasonNameSenior = `price_senior${sBA.period_name}`
                priceSeasonNameAdult = `price_adult${sBA.period_name}`
                priceSeasonNameChild = `price_child${sBA.period_name}`
                isSeasonFound = true;
              } else if (!isSeasonFound) {
                priceSeasonNameSenior = 'price_seniorpeakseason'
                priceSeasonNameAdult = 'price_adultpeakseason'
                priceSeasonNameChild = 'price_childpeakseason'
              }
              return null
            })
          } else {
            priceSeasonNameSenior = 'price_seniorpeakseason'
            priceSeasonNameAdult = 'price_adultpeakseason'
            priceSeasonNameChild = 'price_childpeakseason'
          }
          if (aOD.activity_type === 'individual') {
            aOD.priceCalculated = (aOD.final_price_senior != null ? aOD.final_price_senior : aOD[priceSeasonNameSenior] * aOD.nb_senior)
            + (aOD.final_price != null ? aOD.final_price : aOD[priceSeasonNameAdult] * aOD.nb_adult)
            + (aOD.final_price_child !== null ? aOD.final_price_child : aOD[priceSeasonNameChild] * aOD.nb_child)
          } else {
            aOD.priceCalculated = aOD.final_price != null ? aOD.final_price : aOD[priceSeasonNameAdult] * (aOD.nb_adult ? aOD.nb_adult : 1)
          }
          return (
            null
          )
        })
        // calculation of the price, regroup by theme and currency ALL
        const priceActivitiesOfDayThemeCurrencyConcat = activitiesOfDay.reduce((acc, cur) => {
          const key = cur.id_activity_theme + cur.currency;
          if (acc[key]) {
            acc[key].priceCalculated += cur.priceCalculated;
          } else {
            acc[key] = { ...cur };
          }
          return acc;
        }, {});


        const longPreviousColumn = valueToAdd.length
        uniqueThemeCurrencyActivity.map(() => {
          valueToAdd.push({ value: '' })
          return null
        })

        // ADD PRICING VALUES FOR ALL


        const activitiesOfDayConcat = Object.values(priceActivitiesOfDayThemeCurrencyConcat);
        if (activitiesOfDayConcat) {
          activitiesOfDayConcat.map((aOD) => {
            const themeName = themeList.filter(tH => tH.id_activity_theme === aOD.id_activity_theme)
            const currencyTheme = `${aOD.id_activity_theme ? themeName[0].activity_theme : '[no_theme]'}(${aOD.currency ? aOD.currency : '[no_currency]'})`
            uniqueThemeCurrencyActivity.map((uTCA, indexTheme) => {
              if (uTCA === currencyTheme.trim()) {
                valueToAdd[indexTheme + longPreviousColumn].value = aOD.priceCalculated
              }
              return null
            })


            uniqueCurrenciesHebAct.map((uC, ic) => {
              if (uC === aOD.currency) { totalPrice1 += aOD.priceCalculated * billingContent[`exchangeRate${ic + 1}`] }
              return null
            })
            return null
          })
        }
      }

      //* *************************************************************************************************
      // price management for activities GROUP


      const valueToAddGroup = [{ value: index + 1 }, { value: datejour }, { value: o.nom_journee }]

      if (activitiesOfDayGroup) {
        activitiesOfDayGroup.map((aOD) => {
          const seasonByActivities = seasonByActivityAll.filter(sBA => sBA.id_activity === aOD.id_activity)
          let priceSeasonNameSenior = 0
          let priceSeasonNameAdult = 0
          let priceSeasonNameChild = 0
          let isSeasonFound = false;
          if (!_.isEmpty(seasonByActivities)) {
            seasonByActivities.map((sBA) => {
              if (!isSeasonFound && datejour1 >= sBA.number_day_start && datejour1 <= sBA.number_day_end) {
                priceSeasonNameSenior = `price_senior${sBA.period_name}`
                priceSeasonNameAdult = `price_adult${sBA.period_name}`
                priceSeasonNameChild = `price_child${sBA.period_name}`
                isSeasonFound = true;
              } else if (!isSeasonFound) {
                priceSeasonNameSenior = 'price_seniorpeakseason'
                priceSeasonNameAdult = 'price_adultpeakseason'
                priceSeasonNameChild = 'price_childpeakseason'
              }
              return null
            })
          } else {
            priceSeasonNameSenior = 'price_seniorpeakseason'
            priceSeasonNameAdult = 'price_adultpeakseason'
            priceSeasonNameChild = 'price_childpeakseason'
          }
          if (aOD.activity_type === 'individual') {
            aOD.priceCalculated = (aOD.final_price_senior != null ? aOD.final_price_senior : aOD[priceSeasonNameSenior] * aOD.nb_senior)
            + (aOD.final_price != null ? aOD.final_price : aOD[priceSeasonNameAdult] * aOD.nb_adult)
            + (aOD.final_price_child !== null ? aOD.final_price_child : aOD[priceSeasonNameChild] * aOD.nb_child)
          } else {
            aOD.priceCalculated = aOD.final_price != null ? aOD.final_price : aOD[priceSeasonNameAdult] * (aOD.nb_adult ? aOD.nb_adult : 1)
          }
          return (
            null
          )
        })
        // calculation of the price, regroup by theme and currency
        const priceActivitiesOfDayThemeCurrencyConcatGroup = activitiesOfDayGroup.reduce((acc, cur) => {
          const key = cur.id_activity_theme + cur.currency;
          if (acc[key]) {
            acc[key].priceCalculated += cur.priceCalculated;
          } else {
            acc[key] = { ...cur };
          }
          return acc;
        }, {});
        const longPreviousColumn = valueToAddGroup.length
        uniqueThemeCurrencyActivityGroup.map(() => {
          valueToAddGroup.push({ value: '' })
          return null
        })
        // ADD PRICING VALUES FOR GROUP

        const activitiesOfDayConcatGroup = Object.values(priceActivitiesOfDayThemeCurrencyConcatGroup);
        if (activitiesOfDayConcatGroup) {
          activitiesOfDayConcatGroup.map((aOD) => {
            const themeName = themeList.filter(tH => tH.id_activity_theme === aOD.id_activity_theme)
            const currencyTheme = `${aOD.id_activity_theme ? themeName[0].activity_theme : '[no_theme]'}(${aOD.currency ? aOD.currency : '[no_currency]'})`
            uniqueThemeCurrencyActivityGroup.map((uTCA, indexTheme) => {
              if (uTCA === currencyTheme.trim()) {
                valueToAddGroup[indexTheme + longPreviousColumn].value = aOD.priceCalculated
              }
              return null
            })
            uniqueCurrenciesHebAct.map((uC, ic) => {
              if (uC === aOD.currency) { totalPriceGroup += aOD.priceCalculated * billingContent[`exchangeRate${ic + 1}`] }
              return null
            })
            return null
          })
        }
      }

      //* ************************************************************************************************ */
      // price management for activities INDIVIDUAL ADULT AND CHILD


      const valueToAddIndividualAdult = [{ value: index + 1 }, { value: datejour }, { value: o.nom_journee }]
      const valueToAddIndividualChild = [{ value: index + 1 }, { value: datejour }, { value: o.nom_journee }]
      const valueToAddIndividualSenior = [{ value: index + 1 }, { value: datejour }, { value: o.nom_journee }]

      const activitiesOfDayIndividual = tripActivitiesIndividual[index] ? [...tripActivitiesIndividual[index]] : [];
      if (activitiesOfDayIndividual) {
        activitiesOfDayIndividual.map((aOD) => {
          const seasonByActivities = seasonByActivityAll.filter(sBA => sBA.id_activity === aOD.id_activity)
          let priceSeasonNameSenior = 0
          let priceSeasonNameAdult = 0
          let priceSeasonNameChild = 0
          let isSeasonFound = false;
          if (!_.isEmpty(seasonByActivities)) {
            seasonByActivities.map((sBA) => {
              if (!isSeasonFound && datejour1 >= sBA.number_day_start && datejour1 <= sBA.number_day_end) {
                priceSeasonNameSenior = `price_senior${sBA.period_name}`
                priceSeasonNameAdult = `price_adult${sBA.period_name}`
                priceSeasonNameChild = `price_child${sBA.period_name}`
                isSeasonFound = true;
              } else if (!isSeasonFound) {
                priceSeasonNameSenior = 'price_seniorpeakseason'
                priceSeasonNameAdult = 'price_adultpeakseason'
                priceSeasonNameChild = 'price_childpeakseason'
              }
              return null
            })
          } else {
            priceSeasonNameSenior = 'price_seniorpeakseason'
            priceSeasonNameAdult = 'price_adultpeakseason'
            priceSeasonNameChild = 'price_childpeakseason'
          }
          if (aOD.activity_type === 'individual') {
            aOD.priceCalculatedSenior = aOD.final_price_senior != null ? aOD.final_price_senior : aOD[priceSeasonNameSenior] * aOD.nb_senior
            aOD.priceCalculatedAdult = aOD.final_price != null ? aOD.final_price : aOD[priceSeasonNameAdult] * aOD.nb_adult
            aOD.priceCalculatedChild = aOD.final_price_child !== null ? aOD.final_price_child : aOD[priceSeasonNameChild] * aOD.nb_child
          } else {
            aOD.priceCalculatedAdult = aOD.final_price != null ? aOD.final_price : aOD[priceSeasonNameAdult] * (aOD.nb_adult ? aOD.nb_adult : 1)
          }
          return (
            null
          )
        })
        const longPreviousColumn = valueToAddIndividualAdult.length
        uniqueThemeCurrencyActivityIndividual.map(() => {
          valueToAddIndividualAdult.push({ value: '' })
          return null
        })
        // calculation of the price, regroup by theme and currency ADULT ONLY
        const priceActivitiesOfDayThemeCurrencyConcatAdultOnly = activitiesOfDayIndividual.reduce((acc, cur) => {
          const key = cur.id_activity_theme + cur.currency;
          if (acc[key]) {
            acc[key].priceCalculatedAdult += cur.final_price != null ? cur.final_price : cur.priceCalculatedAdult;
          } else {
            acc[key] = { ...cur };
          }
          return acc;
        }, {});

        // ADD PRICING VALUES FOR ADULT
        const activitiesOfDayConcatAdult = Object.values(priceActivitiesOfDayThemeCurrencyConcatAdultOnly);
        if (activitiesOfDayConcatAdult) {
          activitiesOfDayConcatAdult.map((aOD) => {
            const themeName = themeList.filter(tH => tH.id_activity_theme === aOD.id_activity_theme)

            const currencyTheme = `${aOD.id_activity_theme ? themeName[0].activity_theme : '[no_theme]'}(${aOD.currency ? aOD.currency : '[no_currency]'})`
            uniqueThemeCurrencyActivityIndividual.map((uTCA, indexTheme) => {
              if (uTCA === currencyTheme.trim()) {
                valueToAddIndividualAdult[indexTheme + longPreviousColumn].value = aOD.priceCalculatedAdult
              }
              return null
            })
            uniqueCurrenciesHebAct.map((uC, ic) => {
              if (uC === aOD.currency) { totalPriceIndAdult += aOD.priceCalculatedAdult * billingContent[`exchangeRate${ic + 1}`] }
              return null
            })
            return null
          })
        }

        // calculation of the price, regroup by theme and currency CHILD ONLY

        uniqueThemeCurrencyActivityIndividual.map(() => {
          valueToAddIndividualChild.push({ value: '' })
          return null
        })
        const priceActivitiesOfDayThemeCurrencyConcatChildOnly = activitiesOfDayIndividual.reduce((acc, cur) => {
          const key = cur.id_activity_theme + cur.currency;
          if (acc[key]) {
            acc[key].priceCalculatedChild += cur.final_price_child != null ? cur.final_price_child : cur.priceCalculatedChild;
          } else {
            acc[key] = { ...cur };
          }
          return acc;
        }, {});
        // ADD PRICING VALUES FOR CHILD
        const activitiesOfDayConcatChild = Object.values(priceActivitiesOfDayThemeCurrencyConcatChildOnly);
        if (activitiesOfDayConcatChild) {
          activitiesOfDayConcatChild.map((aOD) => {
            const themeName = themeList.filter(tH => tH.id_activity_theme === aOD.id_activity_theme)

            const currencyTheme = `${aOD.id_activity_theme ? themeName[0].activity_theme : '[no_theme]'}(${aOD.currency ? aOD.currency : '[no_currency]'})`
            uniqueThemeCurrencyActivityIndividual.map((uTCA, indexTheme) => {
              if (uTCA === currencyTheme.trim()) {
                valueToAddIndividualChild[indexTheme + longPreviousColumn].value = aOD.priceCalculatedChild
              }
              return null
            })
            uniqueCurrenciesHebAct.map((uC, ic) => {
              if (uC === aOD.currency) { totalPriceIndChild += aOD.priceCalculatedChild * billingContent[`exchangeRate${ic + 1}`] }
              return null
            })
            return null
          })
        }

        // calculation of the price, regroup by theme and currency SENIOR ONLY

        uniqueThemeCurrencyActivityIndividual.map(() => {
          valueToAddIndividualSenior.push({ value: '' })
          return null
        })
        const priceActivitiesOfDayThemeCurrencyConcatSeniorOnly = activitiesOfDayIndividual.reduce((acc, cur) => {
          const key = cur.id_activity_theme + cur.currency;
          if (acc[key]) {
            acc[key].priceCalculatedSenior += cur.final_price_senior != null ? cur.final_price_senior : cur.priceCalculatedSenior;
          } else {
            acc[key] = { ...cur };
          }
          return acc;
        }, {});
        // ADD PRICING VALUES FOR SENIOR
        const activitiesOfDayConcatSenior = Object.values(priceActivitiesOfDayThemeCurrencyConcatSeniorOnly);
        if (activitiesOfDayConcatSenior) {
          activitiesOfDayConcatSenior.map((aOD) => {
            const themeName = themeList.filter(tH => tH.id_activity_theme === aOD.id_activity_theme)

            const currencyTheme = `${aOD.id_activity_theme ? themeName[0].activity_theme : '[no_theme]'}(${aOD.currency ? aOD.currency : '[no_currency]'})`
            uniqueThemeCurrencyActivityIndividual.map((uTCA, indexTheme) => {
              if (uTCA === currencyTheme.trim()) {
                valueToAddIndividualSenior[indexTheme + longPreviousColumn].value = aOD.priceCalculatedSenior
              }
              return null
            })
            uniqueCurrenciesHebAct.map((uC, ic) => {
              if (uC === aOD.currency) { totalPriceIndSenior += aOD.priceCalculatedSenior * billingContent[`exchangeRate${ic + 1}`] }
              return null
            })
            return null
          })
        }
      }
      newDataCotationIndividualAdult.push(valueToAddIndividualAdult)
      newDataCotationIndividualChild.push(valueToAddIndividualChild)
      newDataCotationIndividualSenior.push(valueToAddIndividualSenior)
      newDataCotationGroup.push(valueToAddGroup)
      newDataCotationAcco.push(valueToAddAcco)

      return (
        newDataCotation.push(valueToAdd)
      )
    })
    this.setState({
      totalPrice: totalPrice1,
      totalPriceAccomodation: totalPriceAccomodation,
      totalPriceGroup: totalPriceGroup,
      totalPriceIndAdult: totalPriceIndAdult,
      totalPriceIndChild: totalPriceIndChild,
      totalPriceIndSenior: totalPriceIndSenior,
      dataCotationDisplay: newDataCotation,
      dataCotationDisplayAdult: newDataCotationIndividualAdult,
      dataCotationDisplayIndividualChild: newDataCotationIndividualChild,
      dataCotationDisplayIndividualSenior: newDataCotationIndividualSenior,
      dataCotationDisplayAcco: newDataCotationAcco,
      dataCotationDisplayGroup: newDataCotationGroup,
      isValidated: true,
    })
  }


  displayPriceCalculation = () => {
    const {
      billingContent,
      totalPrice,
      totalPriceAccomodation,
      totalPriceIndAdult,
      totalPriceIndChild,
      totalPriceIndSenior,
      totalPriceGroup,
      dataCotationDisplay,
      dataCotationDisplayAdult,
      dataCotationDisplayIndividualChild,
      dataCotationDisplayIndividualSenior,
      dataCotationDisplayAcco,
      dataCotationDisplayGroup,
    } = this.state
    const {
      tripActivities,
      tripDetails,
      tripAccomodation,
    } = this.props

    const {
      nb_senior,
      nb_adulte,
      nb_enfant,
    } = tripDetails[0]
    const themeList = [
      { id_activity_theme: '', activity_theme: '' },
      { id_activity_theme: 1, activity_theme: 'Accomodation' },
      { id_activity_theme: 2, activity_theme: 'Food' },
      { id_activity_theme: 3, activity_theme: 'Transportation' },
      { id_activity_theme: 4, activity_theme: 'Activity' },
      { id_activity_theme: 5, activity_theme: 'Service guide' },
      { id_activity_theme: 6, activity_theme: 'Flight' },
      { id_activity_theme: 7, activity_theme: 'Extra' },
      { id_activity_theme: 8, activity_theme: 'Other' },
    ]
    const totalTrav = nb_senior + nb_adulte + nb_enfant
    // determinate the header, isolate the unique value on activity_theme AND currency
    const tripActivitiesNoAccom = Object.keys(tripActivities).reduce((acc, key) => {
      acc[key] = tripActivities[key].filter(activity => activity.id_activity !== 75);
      return acc;
    }, {});
    const uniqueThemeCurrencyActivity = Object.values(tripActivitiesNoAccom)
      .flat()
      .reduce((acc, item) => {
        if (item.id_activity_theme) {
          const themeName = themeList.filter(tH => tH.id_activity_theme === item.id_activity_theme)

          const pair = `${item.id_activity_theme ? themeName[0].activity_theme : '[no_theme]'}(${item.currency ? item.currency : '[no_currency]'})`;
          if (!acc.includes(pair)) {
            acc.push(pair);
          }
        } else {
          const { currency } = item;
          if (!acc.includes(currency)) {
            acc.push(currency);
          }
        }
        return acc;
      }, []);

    const uniqueCurrencies = [...new Set(Object.values(tripActivitiesNoAccom)
      .flat()
      .map(item => item.currency))];
    // determinate the header for the currency
    const tripAccomodationNoNone = tripAccomodation.filter(tAD => tAD.id_hebergement !== 1)
    const currencyHeb = tripAccomodationNoNone.map(item => (item.currency))
    const currencyHebSansDoublons = _.uniq(currencyHeb);
    const uniqueCurrenciesHebAct = _.uniq(uniqueCurrencies.concat(currencyHebSansDoublons))


    // creation du tableau de cotation
    const dataCotation = [
      [{ value: 'Day' }, { value: 'date' }, { value: 'title' }, { value: 'Accomodation' }, { value: 'Room type' }, { value: 'Transport' }, { value: 'Guide' }, { value: 'Driver' }, { value: 'Pension' }],
    ]
    const newDataCotation = _.cloneDeep(dataCotation)
    // ajout des valeurs propsée de currency dans l entete des colonne

    currencyHebSansDoublons.forEach((value) => {
      newDataCotation[0].push({ value: `accomodation (${value || 'no currency define'})` });
    });
    uniqueThemeCurrencyActivity.forEach((value) => {
      newDataCotation[0].push({ value: value });
    });


    return (
      <div>
        <CssGrid
          container
          spacing={1}
        >
          <ActionButton
            label="Calculate"
            onClick={e => this.priceCalculation(e)}
            title="Launch price calculation"
          />
          <CssGrid className="currency" item md={3} xs={3}>
            <h2>Currency</h2>
            <Input
              id="currency"
              label="currency *"
              select
              value={!_.isEmpty(billingContent) && billingContent.currency ? billingContent.currency : ''}
              onChange={this.handleChange('currency')}
              margin="normal"
              variant="outlined"
              className="largeWidth"
            >
              <MenuItem value="$">$</MenuItem>
              <MenuItem value="€">€</MenuItem>
              <MenuItem value="VND">VND</MenuItem>
              <MenuItem value="THB">THB</MenuItem>
              <MenuItem value="CNY">CNY</MenuItem>
            </Input>
            {uniqueCurrenciesHebAct.map((item, index) => {
              const exchangeRate = `exchangeRate${index + 1}`
              return (
                <Input
                  id={`curencyRate${index}`}
                  key={item}
                  label={`exchange rate ${item} to ${billingContent.currency}`}
                  type="number"
                  value={!_.isEmpty(billingContent) && billingContent[exchangeRate] ? billingContent[exchangeRate] : ''}
                  onChange={this.handleChange(exchangeRate)}
                  margin="normal"
                  variant="outlined"
                  className="largeWidth"
                  required
                  error={billingContent[exchangeRate] === null || billingContent[exchangeRate] === undefined}
                />
              )
            })}
          </CssGrid>
          <CssGrid item md={2} xs={2}>
            <h2>Price decomposition</h2>
            <div>
              Total trip cost calculated is : {totalPrice.toLocaleString()} {billingContent.currency}
            </div>
            <div>
              Price calculated by seniort is: {((totalPriceIndSenior / nb_senior) + ((totalPriceGroup + totalPriceAccomodation / totalTrav))).toLocaleString()} {billingContent.currency}
            </div>
            <div>
              Price calculated by adult is: {((totalPriceIndAdult / nb_adulte) + ((totalPriceGroup + totalPriceAccomodation / totalTrav))).toLocaleString()} {billingContent.currency}
            </div>
            <div>
              Price calculated by children is: {((totalPriceIndChild / nb_enfant) + ((totalPriceGroup + totalPriceAccomodation / totalTrav))).toLocaleString()} {billingContent.currency}
            </div>
            <Input
              id="Tripcost"
              label="trip cost"
              type="number"
              value={!_.isEmpty(billingContent) && billingContent.tripCost ? billingContent.tripCost : ''}
              onChange={this.handleChange('tripCost')}
              margin="normal"
              variant="outlined"
              className="largeWidth"
            />
            <Input
              id="unitBenefit"
              label="benefit unit"
              select
              margin="normal"
              variant="outlined"
              className="largeWidth"
              value={!_.isEmpty(billingContent) && billingContent.discountUnit ? billingContent.discountUnit : ''}
              onChange={this.handleChange('discountUnit')}
            >
              <MenuItem value="%">%</MenuItem>
              <MenuItem value={billingContent.currency}>{billingContent.currency}</MenuItem>
            </Input>
            <Input
              id="benefit"
              label="benefit"
              type="number"
              value={!_.isEmpty(billingContent) && billingContent.benefit ? billingContent.benefit : ''}
              onChange={this.handleChange('benefit')}
              margin="normal"
              variant="outlined"
              className="largeWidth"
            />
            <Input
              id="sellingPrice"
              label="selling price"
              type="number"
              value={!_.isEmpty(billingContent) && billingContent.sellingPrice ? billingContent.sellingPrice : ''}
              onChange={this.handleChange('sellingPrice')}
              margin="normal"
              variant="outlined"
              className="largeWidth"
            />
            <Input
              id="pricePerPerson"
              label="price by pax"
              type="number"
              value={!_.isEmpty(billingContent) && billingContent.pricePerPerson ? billingContent.pricePerPerson : ''}
              onChange={this.handleChange('pricePerPerson')}
              margin="normal"
              variant="outlined"
              className="largeWidth"
            />
            <Input
              id="deposit"
              label="deposit"
              type="number"
              value={!_.isEmpty(billingContent) && billingContent.deposit ? billingContent.deposit : ''}
              onChange={this.handleChange('deposit')}
              margin="normal"
              variant="outlined"
              className="largeWidth"
            />
            <Input
              id="soldToPAy"
              label="sold to pay (selling price - deposit)"
              value={!_.isEmpty(billingContent) && billingContent.sellingPrice ? billingContent.sellingPrice - billingContent.deposit : ''}
              margin="normal"
              variant="outlined"
              className="largeWidth"
            />
          </CssGrid>
          <CssGrid item md={5} xs={5}>
            <Input
              id="Comments"
              label="Comments"
              value={!_.isEmpty(billingContent) && billingContent.comment ? billingContent.comment : ''}
              onChange={this.handleChange('comment')}
              margin="normal"
              variant="outlined"
              className="largeWidth"
              multiline // ajout de la propriété multiline
              rows={6}
            />
          </CssGrid>
          <CssGrid item md={12} xs={12}>
            <h2>Cotation detail table ALL  - cost calculated for ALL is : {totalPrice.toLocaleString()} {billingContent.currency} </h2>
            <div className="widthCotationTable">
              <Spreadsheet
                data={dataCotationDisplay}
              />
              <ActionButton
                label="export to Excel"
                onClick={() => this.exportToExcel(dataCotationDisplay)}
              />
            </div>
          </CssGrid>
          <CssGrid item md={12} xs={12}>
            <h2>Cotation detail table GROUP - cost calculated for activity type GROUP is : {totalPriceGroup.toLocaleString()} {billingContent.currency}</h2>
            <div className="widthCotationTable">
              <Spreadsheet
                data={dataCotationDisplayGroup}
              />
              <ActionButton
                label="export to Excel"
                onClick={() => this.exportToExcel(dataCotationDisplayGroup)}
              />
            </div>
          </CssGrid>
          <CssGrid item md={12} xs={12}>
            <h2>Cotation detail table INDIVIDUAL SENIOR - cost calculated for senior activity individual  is : {totalPriceIndSenior.toLocaleString()} {billingContent.currency}</h2>
            <div className="widthCotationTable">
              <Spreadsheet
                data={dataCotationDisplayIndividualSenior}
              />
              <ActionButton
                label="export to Excel"
                onClick={() => this.exportToExcel(dataCotationDisplayIndividualSenior)}
              />
            </div>
          </CssGrid>
          <CssGrid item md={12} xs={12}>
            <h2>Cotation detail table INDIVIDUAL ADULT - cost calculated for adult activity individual  is : {totalPriceIndAdult.toLocaleString()} {billingContent.currency}</h2>
            <div className="widthCotationTable">
              <Spreadsheet
                data={dataCotationDisplayAdult}
              />
              <ActionButton
                label="export to Excel"
                onClick={() => this.exportToExcel(dataCotationDisplayAdult)}
              />
            </div>
          </CssGrid>
          <CssGrid item md={12} xs={12}>
            <h2>Cotation detail table INDIVIDUAL CHILD - cost calculated for child activity individual  is : {totalPriceIndChild.toLocaleString()} {billingContent.currency}</h2>
            <div className="widthCotationTable">
              <Spreadsheet
                data={dataCotationDisplayIndividualChild}
              />
              <ActionButton
                label="export to Excel"
                onClick={() => this.exportToExcel(dataCotationDisplayIndividualChild)}
              />
            </div>
          </CssGrid>

          <CssGrid item md={12} xs={12}>
            <h2>Cotation detail table ACCOMODATION - cost calculated is : {totalPriceAccomodation.toLocaleString()} {billingContent.currency}</h2>
            <div className="widthCotationTable">
              <Spreadsheet
                data={dataCotationDisplayAcco}
              />
              <ActionButton
                label="export to Excel"
                onClick={() => this.exportToExcel(dataCotationDisplayAcco)}
              />
            </div>
          </CssGrid>
        </CssGrid>
      </div>
    )
  }


  // Validate **********************
  validate = (e) => {
    e.preventDefault()
    const {
      token,
      tripBillingEditor,
      tripBillingEditorCheck,
      billingContent,
    } = this.state
    const {
      idTripSelected,
      tripBilling,
      PUT_TRIP_BILLING,
    } = this.props


    // Copie de l'objet tripactivities en conservant uniquement les valeurs day_index et id_activity


    // Parcourir les tableaux copiés et supprimer les autres clés
    scrollTo('main')
    const billingDetails = !_.isEmpty(billingContent) ? billingContent : {}
    billingDetails.id_billing = _.isEmpty(tripBilling) ? null : tripBilling.id_billing;
    billingDetails.tabPrice = !_.isEmpty(tripBillingEditor) ? tripBillingEditor.tabPrice : ''
    billingDetails.displayTabCheck = !_.isEmpty(tripBillingEditorCheck) ? tripBillingEditorCheck.displayTabCheck : 0
    PUT_TRIP_BILLING(token, idTripSelected, billingDetails)
  }


  // *********************************************************

  render() {
    const {
      userId,
      validMsg,
      errorMsg,
      isValidated,
      tripBillingEditor,
      tripBillingEditorCheck,
      displayFull,
      isModalDisplayed,
      isModalOpenBilling,
      dayIndexSelected,
      daySelected,
      isActivityChanged,
      keyTab,
      billingContent,
    } = this.state
    const {
      isLoading,
      userAccount,
      tripDetails,
      tripAllDays,
      tripActivities,
      tripContent,
      informationsContent,
      tripAccomodation,
      tripAccomodationOpt1,
      tripAccomodationOpt2,
      tripRoom,
      tripRoomOpt1,
      tripRoomOpt2,
      tripSeasonByAccomodation,
      seasonByActivityAll,
      tripBilling,
      companyContent,
      collaborators,
    } = this.props
    const { theme_devis } = informationsContent
    // fit table when resizing window
    const isActionButtonDisabled = !isActivityChanged && !_.isEmpty(tripDetails) && !_.isEmpty(userAccount) ? !isAllowed(userId, userAccount.profil, tripDetails) : true
    const FondDefautIndividual = {
      backgroundColor: '#CB8562',
    };
    const FondDefautGroup = {
      backgroundColor: '#CBC062',
    };
    const classContainer = (isLoading || !displayFull) ? 'container2' : 'container'
    let tripSelect = ''
    let tripSelectRoom = ''
    switch (tripBilling.accoChoice) {
      case 1:
        tripSelect = tripAccomodation
        tripSelectRoom = tripRoom
        break;
      case 2:
        tripSelect = tripAccomodationOpt1
        tripSelectRoom = tripRoomOpt1
        break;
      case 3:
        tripSelect = tripAccomodationOpt2
        tripSelectRoom = tripRoomOpt2
        break;
      default:
        tripSelect = tripAccomodation
        tripSelectRoom = tripRoom
    }
    const tripActivitiesFlat = [...new Set(Object.values(tripActivities)
      .flat())];
    const filteredtripActivitiesFlat = tripActivities && !_.isEmpty(tripActivities) ? tripActivitiesFlat.filter(tAF => tAF.id_activity === 75) : []
    const areHotelEqual = () => {
      if (!tripSelect || !filteredtripActivitiesFlat) {
        return false;
      }
      // Vérifiez si les longueurs sont différentes
      if (tripSelect.length !== filteredtripActivitiesFlat.length) {
        return false;
      }

      // Comparez les objets à chaque index
      for (let i = 0; i < tripSelect.length; i += 1) {
        if (tripSelect[i].id_hebergement !== filteredtripActivitiesFlat[i].id_accomodation) {
          return false;
        }
      }

      // Si toutes les conditions sont remplies, renvoyer true
      return true;
    };
    return (
      <div id="main" className={classContainer}>
        <Template>
          <div className="requestContainer">
            <Stepper saveData={dest => this.saveData(dest)} />
            <div className="dividerStepper" />
            {isLoading ? <Loader /> : <div className="loader-null" />}
            <div className={classContainer}>
              <AppBar position="static" className="tab-bar">
                <AntTabs value={keyTab} onChange={this.onChangeTab} aria-label="simple tabs example">
                  <Tab label="Price tab in the proposal" {...this.tabProps(0)} />
                  <Tab label="Price finalisation" {...this.tabProps(1)} />
                  <Tab label="Price calculation" {...this.tabProps(2)} />
                  <Tab label="Price summary" {...this.tabProps(3)} />
                </AntTabs>
              </AppBar>
              <TabPanel value={keyTab} index={0}>
                <CssGrid
                  container
                  spacing={1}
                >
                  <CssGrid item md={6} xs={6}>
                    <h2>Price to display</h2>
                    <div className="editorStyle">
                      <p className="editorTitle background-details">Description</p>
                      <TextEditor
                        object={tripBillingEditor}
                        value={tripBillingEditor.tabPrice}
                        handleEditorChange={this.handleEditorChange('tabPrice')}
                      />
                      <CssGrid
                        container
                        spacing={1}
                      >
                        <CssGrid item md={4} xs={4}>
                          <div className="buttons-collection">
                            <button
                              type="submit"
                              className="select-localisation-button"
                              onClick={this.handleDefaultChange('tabPrice')}
                            >
                              load default price tab
                            </button>
                          </div>
                        </CssGrid>
                        {(theme_devis === 'exploration2' || theme_devis === 'parfum2') && (
                        <CssGrid item md={4} xs={4}>
                          <div className="buttons-collection">
                            <button
                              type="submit"
                              className="select-localisation-button"
                              onClick={this.handleDefaultChange('tabPrice2')}
                            >
                              load default price tab 2
                            </button>
                          </div>
                        </CssGrid>
                        )}
                      </CssGrid>
                    </div>
                  </CssGrid>
                  <CssGrid item md={12} xs={12} className="deposit-item-checkbox" onClick={this.handleCheck('displayTabCheck')}>
                    <h3>  display price on the program:  &nbsp;</h3>
                    {tripBillingEditorCheck.displayTabCheck === 1
                      ? (
                        <img className="imgChecked" src={checked} srcSet={`${checked2x} 2x, ${checked3x} 3x`} alt="Checked" />
                      )
                      : (
                        <img className="imgChecked" src={unchecked} srcSet={`${unchecked2x} 2x, ${unchecked3x} 3x`} alt="Checked" />
                      )}
                  </CssGrid>
                </CssGrid>
              </TabPanel>
              <TabPanel value={keyTab} index={1}>
                <CssGrid
                  container
                  spacing={1}
                >
                  <CssGrid item md={12} xs={12} className="deposit-item-checkbox">
                    <h2>Price finalisation &nbsp;   </h2>
                  </CssGrid>
                  {tripAccomodation && tripRoom
                  && (
                    <div>
                      <CssGrid item md={12} xs={12}>

                        <div>
                          Selected accomodation (to be define when tour is win):
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="gender"
                              name="gender1"
                              style={{ flexDirection: 'row' }}
                              value={!_.isEmpty(billingContent) && billingContent.accoChoice !== undefined ? billingContent.accoChoice : ''}
                              onChange={this.handleChange('accoChoice')}
                            >
                              <FormControlLabel value={0} control={<Radio />} label="No choice" />
                              <FormControlLabel value={1} control={<Radio />} label="opt 1" />
                              <FormControlLabel value={2} control={<Radio />} label="opt 2" />
                              <FormControlLabel value={3} control={<Radio />} label="opt 3" />
                            </RadioGroup>
                          </FormControl>
                        </div>

                      </CssGrid>
                      {!_.isEmpty(tripAllDays) && !_.isEmpty(tripAccomodation) && !_.isEmpty(tripAccomodationOpt1) && !_.isEmpty(tripAccomodationOpt2) && !areHotelEqual() && (
                      <RedirectionButton
                        label="Load Hotel"
                        goTo={() => this.loadAccoInActi()}
                      />
                      )}
                      {!_.isEmpty(tripAllDays) && !_.isEmpty(tripAccomodation) && !_.isEmpty(tripAccomodationOpt1) && !_.isEmpty(tripAccomodationOpt2) && areHotelEqual() && (

                      <CssGrid item md={12} xs={12}>

                        <div className="dayLineBilling-container">
                          <DaySelect
                            dayIndexSelected={dayIndexSelected || 0}
                            daySelected={daySelected || tripAllDays[0]}
                            tripAllDays={tripAllDays}
                            tripContent={tripContent}
                            tripDetails={tripDetails}
                            sendDayIndexSelected={rank => this.sendDayIndexSelected(rank)}
                            sendDaySelected={day => this.sendDaySelected(day)}
                          />
                          <span style={FondDefautIndividual}>&nbsp;&nbsp; </span> <span>Individual</span><span style={FondDefautGroup}>&nbsp;&nbsp; </span> <span>Group</span>
                          <TableService
                            dayIndexSelected={dayIndexSelected || 0}
                            tripContent={tripContent}
                            tripAccomodation={tripSelect}
                            tripRoom={tripSelectRoom}
                            tripDetails={tripDetails}
                            tripAllDays={tripAllDays}
                            activityListByCircuit={tripActivities[dayIndexSelected || 0]}
                            tripSeasonByAccomodation={tripSeasonByAccomodation}
                            seasonByActivityAll={seasonByActivityAll}
                            nbOfDays={tripAllDays.length}
                            changePrice={priceChange => this.changePrice(priceChange)}
                            deleteActivity={actToDelete => this.deleteActivity(actToDelete)}
                          />
                        </div>

                      </CssGrid>
                      )}
                    </div>
                  )
  }
                </CssGrid>
              </TabPanel>
              <TabPanel value={keyTab} index={2}>
                <CssGrid
                  container
                  spacing={1}
                >
                  <CssGrid item md={12} xs={12} className="deposit-item-checkbox">
                    <h2>Price calculation &nbsp;   </h2>
                  </CssGrid>
                </CssGrid>
                {tripAccomodation && tripRoom
                  && (
                    this.displayPriceCalculation()
                  )}
              </TabPanel>
              <TabPanel value={keyTab} index={3}>
                <CssGrid
                  container
                  spacing={1}
                >
                  <CssGrid item md={12} xs={12} className="deposit-item-checkbox">
                    <h2>Price summary &nbsp;   </h2>
                  </CssGrid>
                </CssGrid>
                {tripAccomodation && tripRoom
                  && (
                    <ReservationListSummary
                      tripActivities={tripActivities}
                      tripContent={tripContent}
                      tripDetails={tripDetails}
                      tripBilling={tripBilling}
                      tripAllDays={tripAllDays}
                      tripRoom={tripRoom}
                      companyContent={companyContent}
                      tripSeasonByAccomodation={tripSeasonByAccomodation}
                      seasonByActivityAll={seasonByActivityAll}
                      collaborators={collaborators}
                      tripAccomodation={tripAccomodation}
                      resetValidation={() => this.resetValidation()}
                    />
                  )}
              </TabPanel>
              {displayFull
                ? (
                  <ActionButton
                    label="Validate"
                    onClick={e => this.validate(e)}
                    disabled={isActionButtonDisabled}
                  />
                )
                : null}
              {this.displayAuthorizationWarning()}
              <div className="billingContainer">
                {errorMsg ? <div className="errorMsg">{errorMsg}</div> : null}
                {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              </div>
            </div>
          </div>
        </Template>
        <React.Fragment>
          <Prompt
            when={!isValidated && !isModalDisplayed}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
        {isModalOpenBilling && this.displayDisplayModalBilling()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.api.isLoading,
  isTripDetailsStepValidated: state.api.isTripDetailsStepValidated,
  idTripSelected: state.api.idTripSelected,
  tripDetails: state.api.tripDetails,
  tripAllDays: state.api.tripAllDays,
  putTripTravellerActivity: state.billingReducer.putTripTravellerActivity,
  tripContent: state.api.tripContent,
  tripAccomodation: state.api.tripAccomodation,
  tripAccomodationOpt1: state.api.tripAccomodationOpt1,
  tripAccomodationOpt2: state.api.tripAccomodationOpt2,
  tripSeasonByAccomodation: state.api.tripSeasonByAccomodation,
  tripRoom: state.api.tripRoom,
  tripRoomOpt1: state.api.tripRoomOpt1,
  putTripAccomodationActivity: state.billingReducer.putTripAccomodationActivity,
  tripRoomOpt2: state.api.tripRoomOpt2,
  pensionList: state.api.pensionList,
  tripPrice: state.billingReducer.tripPrice,
  informationsContent: state.informationsReducer.informationsContent,
  tripPension: state.api.tripPension,
  tripBilling: state.billingReducer.tripBilling,
  tripActivities: state.billingReducer.tripActivities,
  putTripPriceActivity: state.billingReducer.putTripPriceActivity,
  putTripBilling: state.billingReducer.putTripBilling,
  userAccount: state.userAccountReducer.userAccount,
  deleteTripActivity: state.billingReducer.deleteTripActivity,
  seasonByAccomodation: state.api.seasonByAccomodation,
  seasonByActivityAll: state.api.seasonByActivityAll,
  collaborators: state.collaboratorsReducer.collaborators,
  companyContent: state.companyReducer.companyContent,
})

const mapDispatchToProps = dispatch => ({
  LOADER: isLoading => dispatch({
    type: 'LOADER',
    isLoading,
  }),
  GET_TRIP_BILLING: (token, idDevis) => dispatch({
    type: 'GET_TRIP_BILLING',
    token,
    idDevis,
  }),
  DELETE_TRIP_ACTIVITY: (token, id_act_cir) => dispatch({
    type: 'DELETE_TRIP_ACTIVITY',
    token,
    id_act_cir,
  }),
  RESET_DELETE_TRIP_ACTIVITIES: () => dispatch({
    type: 'RESET_DELETE_TRIP_ACTIVITIES',
  }),
  PUT_TRIP_BILLING: (token, idDevis, billingDetails) => dispatch({
    type: 'PUT_TRIP_BILLING',
    token,
    idDevis,
    billingDetails,
  }),
  GET_TRIP_ACCOMODATION: tripAccomodationList => dispatch({
    type: 'GET_TRIP_ACCOMODATION',
    tripAccomodationList,
  }),
  GET_TRIP_ACCOMODATION_OPT1: tripAccomodationList => dispatch({
    type: 'GET_TRIP_ACCOMODATION_OPT1',
    tripAccomodationList,
  }),
  GET_TRIP_ACCOMODATION_OPT2: tripAccomodationList => dispatch({
    type: 'GET_TRIP_ACCOMODATION_OPT2',
    tripAccomodationList,
  }),
  GET_TRIP_ROOM: (tripRoomList, tripAccoList) => dispatch({
    type: 'GET_TRIP_ROOM',
    tripRoomList,
    tripAccoList,
  }),
  GET_TRIP_ROOM_OPT1: (tripRoomList, tripAccoList) => dispatch({
    type: 'GET_TRIP_ROOM_OPT1',
    tripRoomList,
    tripAccoList,
  }),
  GET_TRIP_ROOM_OPT2: (tripRoomList, tripAccoList) => dispatch({
    type: 'GET_TRIP_ROOM_OPT2',
    tripRoomList,
    tripAccoList,
  }),
  ACTIVE_STEP: activeStep => dispatch({
    type: 'ACTIVE_STEP',
    activeStep,
  }),
  RESET_PUT_TRIP_BILLING: () => dispatch({
    type: 'RESET_PUT_TRIP_BILLING',
  }),

  GET_TRIP_DEVIS_FINAL: (token, idDevis) => dispatch({
    type: 'GET_TRIP_DEVIS_FINAL',
    token,
    idDevis,
  }),
  RESET_PUT_TRIP_ACCOMODATION_ACTIVITY: () => dispatch({
    type: 'RESET_PUT_TRIP_ACCOMODATION_ACTIVITY',
  }),
  PUT_TRIP_ACCOMODATION_ACTIVITY: (token, tripAllDaysAccomodations, tripActivities, idTripSelected, idAcco) => dispatch({
    type: 'PUT_TRIP_ACCOMODATION_ACTIVITY',
    token,
    tripAllDaysAccomodations,
    tripActivities,
    idTripSelected,
    idAcco,
  }),
  VALIDATE_STEP: (page, value) => dispatch({
    type: 'VALIDATE_STEP',
    page,
    value,
  }),
  GET_TRIP_ACTIVITIES: (token, idCircuit) => dispatch({
    type: 'GET_TRIP_ACTIVITIES',
    token,
    idCircuit,
  }),


  PUT_TRIP_PRICE_ACTIVITY: (token, priceChange) => dispatch({
    type: 'PUT_TRIP_PRICE_ACTIVITY',
    token,
    priceChange,
  }),
  PUT_TRIP_TRAVELLER_ACTIVITY: (token, tripActivities, nbSenior, nbAdult, nbChild) => dispatch({
    type: 'PUT_TRIP_TRAVELLER_ACTIVITY',
    token,
    tripActivities,
    nbSenior,
    nbAdult,
    nbChild,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Billing))
