/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import Interweave from 'interweave';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Geocode from 'react-geocode';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import tableIcons from '../utils/icons/icons'
import duplicate from '../../assets/images/duplicate.png'
import {
  Input,
  ModalComponent,
  TextEditor,
  Uploader,
} from '..'
import { CssGrid, CSSRadio, CssCheckbox } from '../../configCSS'
import underlineCurvedBottom from '../../assets/images/underline-curved-bottom.png'
import underlineCurvedBottom2x from '../../assets/images/underline-curved-bottom@2x.png'
import underlineCurvedBottom3x from '../../assets/images/underline-curved-bottom@3x.png'
import underlineCurvedRight from '../../assets/images/underline-curved-right.png'
import underlineCurvedRight2x from '../../assets/images/underline-curved-right@2x.png'
import underlineCurvedRight3x from '../../assets/images/underline-curved-right@3x.png'
import stage from '../../config'

const {
  Show,
  Accept,
  Edit,
  Remove,
  Copy,
  Copyhtml,
} = tableIcons

const locationFormatRegExp = new RegExp(' ', 'g')
const savedImgUrl = stage.imgStorage
const apiUrl = stage.api.duyen.url

class DayLine extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    item: PropTypes.object,
    index: PropTypes.number,
    userAccount: PropTypes.object,
    validateError: PropTypes.bool,
    isValidated: PropTypes.bool,
    tripActivities: PropTypes.object,
    activityList: PropTypes.array,
    daysList: PropTypes.array,
    tripDetails: PropTypes.array,
    locationList: PropTypes.array,
    pensionList: PropTypes.array,
    accomodationList: PropTypes.array,
    roomList: PropTypes.array,
    roomByAccomodationList: PropTypes.array,
    updateItems: PropTypes.func.isRequired,
    putRoomContent: PropTypes.object,
    putPensionContent: PropTypes.object,
    putDayContent: PropTypes.object,
    putLocationContent: PropTypes.object,
    putRoomByAccomodation: PropTypes.object,
    redirectManagement: PropTypes.func,
    deleteDay: PropTypes.func,
    redirectFlag: PropTypes.func,
    tripAllDays: PropTypes.array,
    isModalOpenInChildren: PropTypes.func.isRequired,
    putTripActivity: PropTypes.object.isRequired,
    isActionButtonDisabled: PropTypes.bool.isRequired,
    informationsContent: PropTypes.object,
    GET_ROOM_LIST: PropTypes.func.isRequired,
    GET_PENSION_LIST: PropTypes.func.isRequired,
    GET_DAYS_LIST: PropTypes.func.isRequired,
    GET_LOCATION_LIST: PropTypes.func.isRequired,
    GET_ROOM_BY_ACCOMODATION_LIST: PropTypes.func.isRequired,
    PUT_TRIP_ACTIVITY: PropTypes.func.isRequired,
    PUT_PENSION_CONTENT: PropTypes.func.isRequired,
    PUT_LOCATION_CONTENT: PropTypes.func.isRequired,
    RESET_ROOM_CONTENT: PropTypes.func.isRequired,
    deleteTripActivities: PropTypes.object.isRequired,
    RESET_PENSION_CONTENT: PropTypes.func.isRequired,
    RESET_LOCATION_CONTENT: PropTypes.func.isRequired,
    RESET_ROOM_BY_ACCOMODATION: PropTypes.func.isRequired,
    RESET_PUT_TRIP_ACTIVITY: PropTypes.func.isRequired,
    PUT_DAY_CONTENT: PropTypes.func.isRequired,
    RESET_DAY_CONTENT: PropTypes.func.isRequired,
    VALIDATE_STEP: PropTypes.func.isRequired,
    DELETE_TRIP_ACTIVITIES: PropTypes.func.isRequired,
    RESET_DELETE_TRIP_ACTIVITIES: PropTypes.func.isRequired,
  }

  static defaultProps = {
    item: {},
    redirectManagement: () => {},
    deleteDay: () => {},
    redirectFlag: () => {},
    index: 0,
    validateError: false,
    isValidated: false,
    daysList: [],
    tripActivities: {},
    tripDetails: [],
    activityList: [],
    locationList: [],
    tripAllDays: [],
    pensionList: [],
    accomodationList: [],
    userAccount: {},
    roomList: [],
    roomByAccomodationList: [],
    putRoomByAccomodation: {},
    putRoomContent: {},
    putPensionContent: {},
    putDayContent: {},
    putLocationContent: {},
    informationsContent: {},
  }

  constructor(props) {
    super(props);
    this.compare = this.compare.bind(this)
    this.removeDuplicates = this.removeDuplicates.bind(this)
    this.sortedUniqDayList = this.sortedUniqDayList.bind(this)
    this.callback = this.callback.bind(this)
    this.displayAssistance = this.displayAssistance.bind(this)
    this.displayInput = this.displayInput.bind(this)
    this.displayInputActivity = this.displayInputActivity.bind(this)
    this.displayAutocompleteDay = this.displayAutocompleteDay.bind(this)
    this.getImageUrl = this.getImageUrl.bind(this)
    this.onChangeDay = this.onChangeDay.bind(this)
    this.saveImage = this.saveImage.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
    this.filterOptionsDay = this.filterOptionsDay.bind(this)
    this.getOptionLabelDay = this.getOptionLabelDay.bind(this)
    this.displayAutocompleteAccomodation = this.displayAutocompleteAccomodation.bind(this)
    this.onChangeAccomodation = this.onChangeAccomodation.bind(this)
    this.filterOptionsAccomodation = this.filterOptionsAccomodation.bind(this)
    this.getOptionLabelAccomodation = this.getOptionLabelAccomodation.bind(this)
    this.displayAutocompleteRoom = this.displayAutocompleteRoom.bind(this)
    this.displayServiceInfo = this.displayServiceInfo.bind(this)
    this.displayAutocompleteRoom_opt = this.displayAutocompleteRoom_opt.bind(this)
    this.onChangeRoom = this.onChangeRoom.bind(this)
    this.onChangeRoom_opt = this.onChangeRoom_opt.bind(this)
    this.filterOptionsRoom = this.filterOptionsRoom.bind(this)
    this.getOptionLabelRoom = this.getOptionLabelRoom.bind(this)
    this.displayAutocompletePension = this.displayAutocompletePension.bind(this)
    this.onChangePension = this.onChangePension.bind(this)
    this.filterOptionsPension = this.filterOptionsPension.bind(this)
    this.getOptionLabelPension = this.getOptionLabelPension.bind(this)
    this.displayDayClassement = this.displayDayClassement.bind(this)
    this.displayDeleteBtn = this.displayDeleteBtn.bind(this)
    this.displayDescritption = this.displayDescritption.bind(this)
    this.displayDayInfo = this.displayDayInfo.bind(this)
    this.displayServiceInfo = this.displayServiceInfo.bind(this)
    this.HTMLToPlainText = this.HTMLToPlainText.bind(this)
    this.HTMLToHTML = this.HTMLToHTML.bind(this)
    this.updateItem = this.updateItem.bind(this)
    this.toggleDescriptionDay = this.toggleDescriptionDay.bind(this)
    this.deleteDay = this.deleteDay.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onChangeActivity = this.onChangeActivity.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.handleRadioChange = this.handleRadioChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.clearField = this.clearField.bind(this)
    this.checkNewItem = this.checkNewItem.bind(this)
    this.displayArchiveRadioButton = this.displayArchiveRadioButton.bind(this)
    this.redirectManagement = this.redirectManagement.bind(this)
    this.createNewLocation = this.createNewLocation.bind(this)
    this.createNewLocation_withGeoLoc = this.createNewLocation_withGeoLoc.bind(this)
    this.createNewPension = this.createNewPension.bind(this)
    this.createNewDayPersonalized = this.createNewDayPersonalized.bind(this)
    this.addNewNbRoom = this.addNewNbRoom.bind(this)
    this.getPlaces = this.getPlaces.bind(this)
    this.getObjectValue = this.getObjectValue.bind(this)
    this.handleActivityDayChange = this.handleActivityDayChange.bind(this)
    this.insertActivity = this.insertActivity.bind(this)
    this.deleteActivity = this.deleteActivity.bind(this)
    this.displayDisplayModal = this.displayDisplayModal.bind(this)

    this.state = {
      userId: localStorage.getItem('userId'),
      token: localStorage.getItem('token'),
      item: {},
      versionValue: '',
      versionList: [],
      idItemModified: null,
      filterLoc: true,
      daysListFiltered: [],
      accomodationFiltered: [],
      roomList: [],
      pensionList: [],
      locationList: [],
      isModalOpen: false,
      modalContent: '',
      activityItem: {},
      modalType: '',
      modalTitle: '',
      inputValue: '',
      newImageArray: [],
      newURLArray: [],
      isDisplayedDescription: false,
      displayActivityContent: false,
      displayPerso: false,
    }
  }

  componentDidMount() {
    // const { userId } = this.state
    const {
      item,
      daysList,
      accomodationList,
      pensionList,
      roomList,
      locationList,
      // roomByAccomodationList,
      // GET_ROOM_BY_ACCOMODATION_LIST,
    } = this.props
    this.setState({
      item,
      daysList,
      versionValue: item.version,
      versionList: daysList.filter(day => day.nom_journee === item.title),
      daysListFiltered: this.sortedUniqDayList(daysList),
      accomodationFiltered: _.filter(accomodationList, a => (a.id_location === item.id_end || a.universel === 1)),
      roomList,
      pensionList,
      locationList,
    })
    // if (!_.isEmpty(roomByAccomodationList)) {
    //   GET_ROOM_BY_ACCOMODATION_LIST(userId)
    // }
  }


  componentWillReceiveProps(nextProps) {
    const {
      userId,
      token,
      item: itemOld,
      idItemModified,
      roomList: roomListOld,
      pensionList: pensionListOld,
      locationList: locationListOld,
      daysList: daysListOld,
      modalContent: field,
    } = this.state
    const {
      item,
      daysList,
      accomodationList,
      roomList,
      pensionList,
      locationList,
      putPensionContent,
      putDayContent,
      putLocationContent,
      putRoomByAccomodation,
      isModalOpenInChildren,
      GET_ROOM_BY_ACCOMODATION_LIST,
      RESET_ROOM_BY_ACCOMODATION,
    } = nextProps
    // When a new room is saved, alert and update item

    // When a new pension is saved, alert and update item
    if (putPensionContent && putPensionContent.success && item.id === idItemModified) {
      const {
        GET_PENSION_LIST,
        RESET_PENSION_CONTENT,
      } = this.props
      const {
        inputValue: value,
      } = this.state
      RESET_PENSION_CONTENT()
      GET_PENSION_LIST(userId)
      this.updateItem(field, value, putPensionContent.id, false)
      isModalOpenInChildren(false)
    }


    // When a new day personalized is saved, alert and update item
    if (putDayContent && putDayContent.success && item.id === idItemModified) {
      const {
        RESET_DAY_CONTENT,
        GET_DAYS_LIST,
      } = this.props
      const {
        inputValue: value,
      } = this.state
      RESET_DAY_CONTENT()
      GET_DAYS_LIST(userId)
      this.updateItem('personalized', value, putDayContent.id, false)
      this.setState({
        idItemModified: '',
      })
      isModalOpenInChildren(false)
    }


    // When a new location is saved, alert and update item
    if (putLocationContent && putLocationContent.success && item.id === idItemModified) {
      const {
        GET_LOCATION_LIST,
        RESET_LOCATION_CONTENT,
      } = this.props
      const {
        modalContent,
        inputValue,
      } = this.state
      RESET_LOCATION_CONTENT()
      GET_LOCATION_LIST(userId)
      this.updateItem(modalContent, inputValue, putLocationContent.id, true)
      isModalOpenInChildren(false)
    }
    if (putRoomByAccomodation && putRoomByAccomodation.success) {
      RESET_ROOM_BY_ACCOMODATION()
      GET_ROOM_BY_ACCOMODATION_LIST(userId)
    }
    if (item !== itemOld) {
      this.setState({
        item,
        versionValue: item.version,
        versionList: daysList.filter(day => day.nom_journee === item.title),
        accomodationFiltered: _.filter(accomodationList, a => (a.id_location === item.id_end || a.universel === 1)),
      })
    }
    if (roomList !== roomListOld) this.setState({ roomList })
    if (pensionList !== pensionListOld) this.setState({ pensionList })
    if (locationList !== locationListOld) this.setState({ locationList })
    if (!_.isEqual(daysList, daysListOld)) {
      this.setState({
        daysList,
      })
    }
  }


  // ********************************************
  // Sort and remove duplicate title program

  // Sort all days A -> Z
  compare = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const titleA = a.nom_journee.toUpperCase();
    const titleB = b.nom_journee.toUpperCase();

    let comparison = 0;
    if (titleA > titleB) {
      comparison = 1;
    } else if (titleA < titleB) {
      comparison = -1;
    }
    return comparison;
  }

  // keep only version 1 remove all others
  removeDuplicates = data => data.filter(o => o.version === 1)

  // global function sorted and keep uniq day in list
  sortedUniqDayList = (list) => {
    const {
      tripDetails,
    } = this.props
    const { langage } = tripDetails[0]
    const preparedList = this.removeDuplicates(list.sort(this.compare))
    return preparedList.filter(d => d.language === langage)
  }

  // ***************************
  // Modal
  handleOpen = () => {
    const { isModalOpenInChildren } = this.props
    this.setState({
      isModalOpen: true,
    });
    isModalOpenInChildren(true)
  };

  isModalOpenInChildrenEditor = (state) => {
    const { isModalOpenInChildren } = this.props
    isModalOpenInChildren(state)
  }


  handleClose = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  isModalOpenInChildrenBilling = (state) => {
    const { isModalOpenInChildren } = this.props
    isModalOpenInChildren(state)
  }

  // NO response : clear field
  clearField = () => {
    const {
      item,
      modalContent,
    } = this.state
    const field = modalContent
    if (field === 'day_personalized') {
      this.setState({
        item: {
          ...item,
          personalized: '0',
        },
      }, () => this.callback())
    } else {
      this.setState({
        item: {
          ...item,
          [field]: '',
          [`id_${field}`]: null,
        },
      })
      const isPlace = field === 'start' || field === 'end'
      this.updateItem(field, '', null, isPlace)
    }
    this.handleClose()
  }

  // YES response
  createNewLocation = (value) => {
    Geocode.fromAddress(value).then(
      (response) => {
        this.createNewLocation_withGeoLoc(response, value);
      },
      (error) => {
        alert('cannot find GPS information for this location, please contact your administrator to create it manually.')
      },
    )
  }

  createNewLocation_withGeoLoc = (response, value) => {
    const { token } = this.state
    const { PUT_LOCATION_CONTENT } = this.props
    this.handleClose()
    const locationDetails = {}
    locationDetails.lieu = value
    locationDetails.geocode = response.results[0].geometry.location

    PUT_LOCATION_CONTENT(token, locationDetails, 'create')
  }

  createNewPension = (value) => {
    const { token } = this.state
    const { PUT_PENSION_CONTENT } = this.props
    this.handleClose()
    PUT_PENSION_CONTENT(token, { pension: value }, 'create')
  }

  createNewDayPersonalized = (newDayContent) => {
    const { token } = this.state
    const { PUT_DAY_CONTENT } = this.props
    this.handleClose()
    PUT_DAY_CONTENT(token, newDayContent, 'create')
  }

  redirectManagement = (page) => {
    const {
      redirectManagement,
      redirectFlag,
    } = this.props
    const {
      item,
      modalContent: field,
      inputValue: value,
    } = this.state

    const stateBack = {
      page,
      state: {
        idDayLineModified: item.id,
        end: item.end,
        id_end: item.id_end,
        field,
        value,
        id_accomodation: item.id_accomodation,
      },
      isSelectionAction: true,
      nbMaxImg: 5,
      currentImg: item.image ? item.image.split(',') : [],
      goBack: 'day-personalized',
    }
    redirectFlag(true)
    setTimeout(() => {
      redirectManagement(stateBack)
    }, 200)
  }

  addNewNbRoom = (item, nbRoomCheck) => {
    const number_room = `number_room${nbRoomCheck}`
    const nb_roomcheck = `nb_room${nbRoomCheck}`
    const room = `room${nbRoomCheck}`
    const newNbRoomArray = _.takeRight([...item[nb_roomcheck], item[number_room]], item[room].length)
    this.setState({
      item: {
        ...item,
        [nb_roomcheck]: newNbRoomArray,
      },
    }, () => this.callback())
  }

  saveImage = (imagesDataArray, field) => {
    this.setState({
      newImageArray: imagesDataArray.map(img => img.imageName),
      newURLArray: imagesDataArray.map(img => img.imageUrl),
    });
  }

  deleteImage = (field) => {
    const { tripDetails } = this.state
    this.setState({
      tripDetails: [{
        ...tripDetails[0],
        [field]: '',
      }],
      [`newImageArray_${field}`]: [],
      [`newURLArray_${field}`]: [],
    })
  }

    // Redirect
    redirectToGallery = () => {
      const {
        item,
      } = this.state
      const {
        history,
      } = this.props
      this.redirectManagement('gallery')
    }


    getImageUrl = () => {
      const { userAccount } = this.props
      const { company } = userAccount
      const {
        item,
        newURLArray,
        newImageArray,
      } = this.state

      // display image(s) modified
      if (!_.isEmpty(newURLArray)) return [newURLArray, newImageArray]
      const imageNameArray = item.image ? item.image.split(',') : null
      // display default image
      if (_.isEmpty(imageNameArray)) return [[`${apiUrl}/images/default.png`], []]

      // display image(s) saved in database
      const imageUrlArray = []
      imageNameArray.map((imgName) => {
        imageUrlArray.push(`${savedImgUrl}/${company.toLowerCase()}/${imgName}`)
        return null
      })
      return [imageUrlArray, imageNameArray]
    }

  // Action will be different depending of field changed
  // 1 Create directly a new item or
  // 2 Redirect to management page for this field
  checkNewItem = (item) => {
    const {
      modalContent: field,
      inputValue: value,
    } = this.state
    let page = ''
    switch (field) {
      case 'title':
        page = 'day'
        this.redirectManagement(page)
        break;
      case 'accomodation':
        page = 'accomodation'
        this.redirectManagement(page)
        break;
      case 'start':
      case 'end':
        this.createNewLocation(value)
        break;
      case 'room':
        this.createNewRoom(value)
        break;
      case 'pension':
        this.createNewPension(value)
        break;
      case 'number_room_opt2':
        this.addNewNbRoom(item, '_opt2')
        break;
      case 'number_room':
        this.addNewNbRoom(item, '')
        break;
      case 'number_room_opt1':
        this.addNewNbRoom(item, '_opt1')
        break;
      case 'day_personalized':
        this.createNewDayPersonalized(value)
        break;
      default:
        break;
    }
    this.handleClose()
    return null
  }

  // ***************************
  // update items (parent)
  callback = () => {
    const { item } = this.state
    const {
      updateItems,
      isActionButtonDisabled,
      VALIDATE_STEP,
    } = this.props
    if (!isActionButtonDisabled) {
      VALIDATE_STEP('DaysList', false)
      updateItems(item)
    }
  }

  deleteDay = () => {
    const { item } = this.state
    const { deleteDay } = this.props
    if (confirm('Do you really want to delete this day ?')) {
      deleteDay(item.id)
    }
  }
  // *************************************
  // Update item

  // get start and end place from title
  getPlaces = (id) => {
    const {
      locationList,
      daysList,
    } = this.state
    // if a day is changed return its start and end place
    if (id) {
      const day = _.find(daysList, o => o.id_journee === id)
      const start = !_.isEmpty(day) ? _.find(locationList, o => o.id_lieu === day.id_start) : {}
      const end = !_.isEmpty(day) ? _.find(locationList, o => o.id_lieu === day.id_end) : {}
      return { start, end }
    }
    // if no id day, the input was cleared, return the whole daysList
    this.setState({
      daysListFiltered: this.sortedUniqDayList(daysList),
    })
    return null
  }

  // update item and send for updates global items
  updateItem = (field, value, id, isPlace, indexRoomDeleted = -1) => {
    const {
      item,
      versionValue,
      daysList,
      roomList,
    } = this.state
    const {
      accomodationList,
    } = this.props
    // start or end are changed
    // reduce days list with only days begining or ending by the new place erase all others fields
    if (isPlace) {
      const otherPlace = field === 'start' ? 'end' : 'start'
      const idOtherPlace = field === 'start' ? 'id_end' : 'id_start'
      let daysListFiltered = ''
      switch (item[otherPlace]) {
        case '':
          daysListFiltered = isNaN(id) ? this.sortedUniqDayList(daysList) : this.sortedUniqDayList(daysList.filter(o => o[`id_${field}`] === id))
          break;
        case null:
          daysListFiltered = isNaN(id) ? this.sortedUniqDayList(daysList) : this.sortedUniqDayList(daysList.filter(o => o[`id_${field}`] === id))
          break;
        default:
          daysListFiltered = isNaN(id) ? this.sortedUniqDayList(daysList.filter(o => o[`id_${otherPlace}`] === item[idOtherPlace])) : this.sortedUniqDayList(daysList.filter(o => o[`id_${field}`] === id).filter(o => o[`id_${otherPlace}`] === item[idOtherPlace]))
      }
      const accomodationFiltered = isNaN(id) || field !== 'end' ? [] : _.filter(accomodationList, o => (o.id_location === id || o.universel === 1))
      switch (item.personalized) {
        case '1':
          this.setState({
            item: {
              ...item,
              [field]: value,
              [`id_${field}`]: id,
              accomodation: '',
              id_accomodation: null,
            },
            daysListFiltered,
            accomodationFiltered,
            versionValue: '',
            versionList: [],
          }, () => this.callback())
          break;
        default:
          this.setState({
            item: {
              ...item,
              [field]: value,
              [`id_${field}`]: id,
              id_title: '',
              title: '',
              description: '',
              transport: '',
              distance: '',
              day_duration: 1,
              day_activity: '',
              categorie: '',
              shortTitle: '',
              accomodation: '',
              id_accomodation: null,
              accomodation_opt_1: '',
              id_accomodation_opt_1: null,
              accomodation_opt_2: '',
              id_accomodation_opt_2: null,
              room: [],
              id_room: [],
              nb_room: [],
              room_opt1: [],
              id_room_opt1: [],
              nb_room_opt1: [],
              room_opt2: [],
              id_room_opt2: [],
              nb_room_opt2: [],
              pension: '',
              id_pension: null,
              version: '',
              personalized: '0',
            },
            daysListFiltered,
            accomodationFiltered,
            versionValue: '',
            versionList: [],
          }, () => this.callback())
      }
    // if title day changed
    } else if (field === 'title') {
      const place = this.getPlaces(id)
      const newDay = _.filter(daysList, o => o.nom_journee === value && o.version === 1)[0]
      const accomodationFiltered = !place || isNaN(place.end.id_lieu) ? [] : _.filter(accomodationList, o => (o.id_location === place.end.id_lieu || o.universel === 1))
      const newVersionList = daysList.filter(day => day.nom_journee === value)
      // Update title, start, end and id ... in items object
      this.setState({
        item: {
          ...item,
          title: value,
          id_title: id,
          version: versionValue,
          start: !_.isEmpty(place) ? place.start.lieu : '',
          id_start: !_.isEmpty(place) ? place.start.id_lieu : '',
          end: !_.isEmpty(place) ? place.end.lieu : '',
          id_end: !_.isEmpty(place) ? place.end.id_lieu : '',
          id_journee: !_.isEmpty(newDay) ? newDay.id_journee : '',
          description: !_.isEmpty(newDay) ? newDay.desc_journee : '',
          transport: !_.isEmpty(newDay) ? newDay.transport : '',
          distance: !_.isEmpty(newDay) ? newDay.distance : '',
          day_duration: !_.isEmpty(newDay) ? newDay.day_duration : 1,
          day_activity: !_.isEmpty(newDay) ? newDay.day_activity : '',
          categorie: !_.isEmpty(newDay) ? newDay.categorie : '',
          shortTitle: !_.isEmpty(newDay) ? newDay.shortTitle : '',
          image: !_.isEmpty(newDay) ? newDay.image : '',
          accomodation: '',
          id_accomodation: null,
          room: [],
          id_room: [],
          nb_room: [],
          room_opt1: [],
          id_room_opt1: [],
          nb_room_opt1: [],
          room_opt2: [],
          id_room_opt2: [],
          nb_room_opt2: [],
          pension: '',
          id_pension: null,
          personalized: '0',
        },
        accomodationFiltered,
        versionList: newVersionList,
        versionValue: _.isEmpty(newVersionList) ? 1 : newVersionList[0].version,
        daysListFiltered: this.sortedUniqDayList(daysList),
      }, () => this.callback())


    // if version change
    } else if (field === 'version') {
      const newDay = _.filter(daysList, o => o.nom_journee === value && o.version === versionValue)[0]
      this.setState({
        item: {
          ...item,
          id_title: newDay.id_journee,
          description: !_.isEmpty(newDay) ? newDay.desc_journee : '',
          transport: !_.isEmpty(newDay) ? newDay.transport : '',
          distance: !_.isEmpty(newDay) ? newDay.distance : '',
          day_duration: !_.isEmpty(newDay) ? newDay.day_duration : 1,
          day_activity: !_.isEmpty(newDay) ? newDay.day_activity : '',
          categorie: !_.isEmpty(newDay) ? newDay.categorie : '',
          shortTitle: !_.isEmpty(newDay) ? newDay.shortTitle : '',
        },
        daysListFiltered: this.sortedUniqDayList(daysList),
      }, () => this.callback())
    } else if (field === 'accomodation') {
      this.setState({
        item: {
          ...item,
          [field]: !value ? 'none' : value,
          [`id_${field}`]: isNaN(id) ? 1 : id,
          room: [],
          id_room: [],
          nb_room: [],
        },
      }, () => this.callback())
    // for day personalized
    } else if (field === 'accomodation_opt1') {
      this.setState({
        item: {
          ...item,
          [field]: !value ? 'none' : value,
          [`id_${field}`]: isNaN(id) ? 1 : id,
          room_opt1: [],
          id_room_opt1: [],
          nb_room_opt1: [],
        },
      }, () => this.callback())
    // for day personalized
    } else if (field === 'accomodation_opt2') {
      this.setState({
        item: {
          ...item,
          [field]: !value ? 'none' : value,
          [`id_${field}`]: isNaN(id) ? 1 : id,
          room_opt2: [],
          id_room_opt2: [],
          nb_room_opt2: [],
        },
      }, () => this.callback())
    // for day personalized
    } else if (field === 'personalized') {
      this.setState({
        item: {
          ...item,
          id_title: id,
          personalized: '1',
          active: '2',
        },
      }, () => this.callback())
    // for all other autocomplete input changed
    } else if (field === 'personalized') {
      this.setState({
        item: {
          ...item,
          id_title: id,
          personalized: '1',
        },
      }, () => this.callback())
    // for all other autocomplete input changed
    } else if (field === 'room' || field === 'room_opt1' || field === 'room_opt2') {
      const id_roomcheck = `id_${field}`
      const nb_roomcheck = `nb_${field}`
      const number_roomcheck = `number_${field}`

      const valueToUpdate = _.isEmpty(value) ? ['none'] : [...value]
      // Ask for the number of room is needed
      if (!_.isEmpty(value) && value !== 'none' && indexRoomDeleted === -1) {
        this.setState({
          modalTitle: 'How many room in this category ?',
          modalContent: number_roomcheck,
          modalType: 'number',
          inputValue: '1',
          modalSimpleAction: true,
          modalDisabled: false,
        }, () => this.handleOpen())
      }
      // Build the new array of 'id room'
      let idRoomList = []
      if (!id) {
        idRoomList = _.isEmpty(value) ? [1] : valueToUpdate.map(r => roomList.filter(rL => rL.type_chambre === r)[0].id_type_chambre)
      } else if (item[id_roomcheck] && item[id_roomcheck][0] === 1) {
        // delete room type = none
        idRoomList = [..._.tail(item[id_roomcheck]), id]
      } else {
        idRoomList = [...item[id_roomcheck], id]
      }
      // new array for number of room if a room is deleted
      const nbRoom = [...item[nb_roomcheck]]
      if (indexRoomDeleted > -1) {
        nbRoom.splice(indexRoomDeleted, 1)
      }
      this.setState({
        item: {
          ...item,
          [field]: valueToUpdate,
          [`id_${field}`]: idRoomList,
          [nb_roomcheck]: nbRoom,
        },
      }, () => this.callback())
      // for all other autocomplete input changed
    } else {
      // Update field and id in items object
      this.setState({
        item: {
          ...item,
          [field]: !value ? 'none' : value,
          [`id_${field}`]: isNaN(id) ? 1 : id,
        },
      }, () => this.callback())
    }
  }

  // update description fields
  // inputs change
  handleEditorChange = field => (content) => {
    const { item } = this.state
    this.setState({
      item: {
        ...item,
        [field]: content,
      },
    }, () => this.callback());
  }

  handleChange = field => (event) => {
    const { item } = this.state
    const { value } = event.target
    this.setState({
      item: {
        ...item,
        [field]: value,
      },
    }, () => this.callback());
  }


  getObjectValue = (field, value) => {
    const {
      daysList,
      accomodationList,
      pensionList,
      roomList,
    } = this.props
    const {
      locationList,
    } = this.state
    // check with database name
    let list = []
    let name = ''
    let id = ''
    switch (field) {
      case 'title':
        list = _.clone(daysList)
        name = 'nom_journee'
        id = 'id_journee'
        break;
      case 'accomodation':
      case 'accomodation_opt_1':
      case 'accomodation_opt_2':
        list = _.clone(accomodationList)
        name = 'nom_hebergement'
        id = 'id_hebergement'
        break;
      case 'pension':
        list = _.clone(pensionList)
        name = 'pension'
        id = 'id_pension'
        break;
      case 'room':
        list = _.clone(roomList)
        name = 'type_chambre'
        id = 'id_type_chambre'
        break;
      case 'start':
      case 'end':
        list = _.clone(locationList)
        name = 'lieu'
        id = 'id_lieu'
        break;
      default:
        return null;
    }
    const object = _.find(list, l => l[name] === value)
    if (object) return object[id]
    return null
  }

  // action on imput
  onBlur = field => (event) => {
    const { item } = this.state
    const { value } = event.target
    // No effect if no change
    if (item[field] === value) return null
    let valueMod = value
    // format location name 'XXX-YY-ZZ'
    if (field === 'start' || field === 'end') valueMod = _.upperCase(_.trim(value)).replace(locationFormatRegExp, '-')
    const idValue = valueMod ? this.getObjectValue(field, valueMod) : {}
    // place is true if start or end field was changed
    const isPlace = field === 'start' || field === 'end'

    // if this value doesn't exists in various list, ask in modal to record it
    if (idValue === null && !isPlace) {
      this.setState({
        idItemModified: item.id,
        inputValue: valueMod,
        modalContent: field,
        modalType: '',
        modalTitle: 'Do you want to create a new element ?',
        modalSimpleAction: false,
        modalDisabled: true,
      }, () => this.handleOpen())
    } else {
      // update item
      this.updateItem(field, valueMod, idValue, isPlace)
    }
    return null
  }

  onChange = field => (event, value) => {
    // find the global object from this value
    const idValue = value ? this.getObjectValue(field, value) : null
    // place is true if start or end field was changed
    const isPlace = field === 'start' || field === 'end'
    // update item
    this.updateItem(field, value, idValue, isPlace)
  }

  onChangeActivity = (event, value) => {
    const {
      activityItem,
    } = this.state
    // find the global object from this value
    this.setState({
      activityItem: value,
    })
  }

  versionChange = (event) => {
    const {
      item,
    } = this.state
    this.setState({
      versionValue: event.target.value,
    }, () => this.updateItem('version', item.title, null, false))
  }

  // On change accomodation
  onChangeDay = content => (event, newValue) => {
    const {
      daysList,
      item,
      token,
    } = this.state
    const {
      tripDetails,
      DELETE_TRIP_ACTIVITIES,
      tripActivities,
    } = this.props
    let idValue = ''
    let value = ''
    const dayIndex = item.id - 1

    if (typeof newValue === 'string') {
      // On press Enter
      const valueExist = daysList.filter(d => d.nom_journee === newValue)
      if (_.isEmpty(valueExist)) {
        this.setState({
          idItemModified: content.id,
          inputValue: newValue,
          modalContent: 'title',
          modalType: '',
          modalTitle: 'Do you want to create a new element ?',
          modalSimpleAction: false,
          modalDisabled: true,
        }, () => this.handleOpen())
      } else {
        idValue = valueExist[0].id_journee
        value = valueExist[0].nom_journee
      }
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      this.setState({
        idItemModified: content.id,
        inputValue: newValue.inputValue,
        modalContent: 'title',
        modalType: '',
        modalTitle: 'Do you want to create a new element ?',
        modalSimpleAction: false,
        modalDisabled: true,
      }, () => this.handleOpen())
    } else if (!newValue) {
      idValue = null
      value = ''
    } else {
      idValue = newValue.id_journee
      value = newValue.nom_journee
    }
    this.updateItem('title', value, idValue, false)
    return null
  }

  filterOptionsDay = (options, params) => {
    const { daysListFiltered, daysList } = this.state
    const filtered = _.filter(daysListFiltered, d => d.nom_journee.toLowerCase().indexOf(params.inputValue.toLowerCase()) > -1)
    const valueExist = daysList.filter(d => d.nom_journee === params.inputValue)

    // Suggest the creation of a new value
    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: params.inputValue,
        nom_journee: `Add "${params.inputValue}"`,
      });
    }
    return filtered;
  }

  getOptionLabelDay = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // after input clear
    if (!option.title) {
      return ''
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.title
  }

  // On change accomodation
  onChangeAccomodation = content => (event, newValue) => {
    const { accomodationList } = this.props
    let idValue = ''
    let value = ''
    if (typeof newValue === 'string') {
      // On press Enter
      const valueExist = accomodationList.filter(d => d.nom_hebergement === newValue)
      if (_.isEmpty(valueExist)) {
        this.setState({
          idItemModified: content.id,
          inputValue: newValue,
          modalContent: 'accomodation',
          modalType: '',
          modalTitle: 'Do you want to create a new element ?',
          modalSimpleAction: false,
          modalDisabled: true,
        }, () => this.handleOpen())
      } else {
        // is like select accomodation in list
        idValue = valueExist[0].id_hebergement
        value = valueExist[0].nom_hebergement
      }
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      this.setState({
        idItemModified: content.id,
        inputValue: newValue.inputValue,
        modalContent: 'accomodation',
        modalType: '',
        modalTitle: 'Do you want to create a new element ?',
        modalSimpleAction: false,
        modalDisabled: true,
      }, () => this.handleOpen())
    } else if (!newValue) {
      idValue = null
      value = ''
    } else {
      idValue = newValue.id_hebergement
      value = newValue.nom_hebergement
    }
    this.updateItem('accomodation', value, idValue, false)
    return null
  }

  filterOptionsAccomodation = (options, params) => {
    const { accomodationFiltered } = this.state
    // const { accomodationList } = this.props
    const filtered = _.filter(accomodationFiltered, d => d.nom_hebergement.toLowerCase().indexOf(params.inputValue.toLowerCase()) > -1)
    const valueExist = filtered.filter(d => d.nom_hebergement === params.inputValue)

    // Suggest the creation of a new value
    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: params.inputValue,
        nom_hebergement: `Add "${params.inputValue}"`,
      });
    }
    return filtered;
  }

  getOptionLabelAccomodation = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // after input clear
    if (!option.accomodation) {
      return ''
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.accomodation;
  }

  // On change room
  onChangeRoom = (content, list) => (event, newValue, reason) => {
    const {
      roomList,
      item,
      token,
    } = this.state

    // newValue is an array of string representative the rooms selected
    // It contains an object representative the room added
    // if a room is deleted, the input return an array of string without the room deleted

    let idValue = null
    let value = []

    // onPress Enter new value (type string) is added on the last position in newValue array
    const isNewRoomAdded = (newValue.length === content.room.length + 1) && (typeof newValue[newValue.length - 1] === 'string')
    if (isNewRoomAdded) {
      // On press enter
      // Create a new value from the user input
      const newValueAdded = newValue[0] === 'none' ? _.tail(newValue) : newValue
      const newValueFiltered = newValueAdded.map(r => _.capitalize(r))
      const newRoomName = _.capitalize(newValueFiltered[newValueFiltered.length - 1])
      const roomExist = roomList.filter(r => r.type_chambre.toLowerCase() === newRoomName.toLowerCase())


      const accomodationRoomExist = list.filter(l => l.type_chambre === newRoomName.toLowerCase())

      // if room doesn't exist in accomodation
      if (_.isEmpty(accomodationRoomExist)) {
        this.setState({
          idItemModified: content.id,
          inputValue: newValue,
          modalContent: 'accomodation',
          modalType: '',
          modalTitle: 'Do you want to create a new element ?',
          modalSimpleAction: false,
          modalDisabled: true,
        }, () => this.handleOpen())
      }
    } else if (_.isEmpty(newValue)) {
      // room input is empty now
      idValue = null
      value = []
      this.updateItem('room', value, idValue, false, 0)
    } else if (reason === 'remove-option') {
      // a room has been deleted
      const indexRoomDeleted = content.room.indexOf(_.difference(content.room, newValue)[0])
      value = [...newValue]
      this.updateItem('room', value, idValue, false, indexRoomDeleted)
    } else if (newValue && newValue[newValue.length - 1].inputValue) {
      // Create a new value from the user input
      // reject the first type of room if it is 'none'

      this.setState({
        idItemModified: content.id,
        inputValue: item.accomodation,
        modalContent: 'accomodation',
        modalType: '',
        modalTitle: 'Do you want to create a new element ?',
        modalSimpleAction: false,
        modalDisabled: true,
      }, () => this.handleOpen())
    } else {
      // when a room is added
      // reject the first type of room if it is 'none'
      const newValueFiltered = newValue[0] === 'none' ? _.tail(newValue) : newValue
      idValue = newValue[newValue.length - 1].id_heb_ch
      value = newValueFiltered.map((nV) => {
        if (typeof nV === 'string') return nV
        return nV.type_chambre
      })
      this.updateItem('room', value, idValue, false)
    }
    return null
  }

   // On change room
   onChangeRoom_opt = (field, content, list) => (event, newValue, reason) => {
     const {
       roomList,
       item,
       token,
     } = this.state

     // newValue is an array of string representative the rooms selected
     // It contains an object representative the room added
     // if a room is deleted, the input return an array of string without the room deleted

     let idValue = null
     let value = []

     // onPress Enter new value (type string) is added on the last position in newValue array
     const isNewRoomAdded = (newValue.length === content.room.length + 1) && (typeof newValue[newValue.length - 1] === 'string')

     if (isNewRoomAdded) {
       // On press enter
       // Create a new value from the user input
       const newValueAdded = newValue[0] === 'none' ? _.tail(newValue) : newValue
       const newValueFiltered = newValueAdded.map(r => _.capitalize(r))
       const newRoomName = _.capitalize(newValueFiltered[newValueFiltered.length - 1])
     } else if (_.isEmpty(newValue)) {
       // room input is empty now
       idValue = null
       value = []
       this.updateItem(field, value, idValue, false, 0)
     } else if (reason === 'remove-option') {
       // a room has been deleted
       const indexRoomDeleted = content.room.indexOf(_.difference(content.room, newValue)[0])
       value = [...newValue]
       this.updateItem(field, value, idValue, false, indexRoomDeleted)
     } else {
       // when a room is added
       // reject the first type of room if it is 'none'
       const newValueFiltered = newValue[0] === 'none' ? _.tail(newValue) : newValue
       idValue = newValue[newValue.length - 1].id_type_chambre
       value = newValueFiltered.map((nV) => {
         if (typeof nV === 'string') return nV
         return nV.type_chambre
       })
       this.updateItem(field, value, idValue, false)
     }
     return null
   }

  filterOptionsRoom = list => (options, params) => {
    const {
      roomList,
    } = this.state
    const filtered = _.filter(list, d => d.type_chambre.toLowerCase().indexOf(params.inputValue.toLowerCase()) > -1)
    const valueExist = list.filter(d => d.type_chambre.toLowerCase() === params.inputValue.toLowerCase())
    // Suggest the creation of a new value
    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: _.capitalize(params.inputValue),
        type_chambre: `Add "${_.capitalize(params.inputValue)}"`,
      });
    }
    return filtered;
  }

  filterOptionsRoom_opt = list => (options, params) => {
    const {
      roomList,
    } = this.state
    const filtered = _.filter(list, d => d.type_chambre.toLowerCase().indexOf(params.inputValue.toLowerCase()) > -1)
    const valueExist = list.filter(d => d.type_chambre.toLowerCase() === params.inputValue.toLowerCase())
    // Suggest the creation of a new value

    return filtered;
  }

  getOptionLabelRoom = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // after input clear
    if (!option.room) {
      return ''
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.room;
  }

  handleRadioChange = (event) => {
    const {
      item,
      newImageArray,
      token,
    } = this.state
    const personalizedValue = event.target.value
    this.setState({
      item: {
        ...item,
        personalized: personalizedValue,
      },
    }, () => this.callback())
    const newDayContent = {
      nom_journee: item.title ? item.title : '',
      nom_journee_court: item.shortTitle ? item.shortTitle : '',
      version: '1',
      day_duration: item.day_duration ? item.day_duration : 1,
      day_activity: item.day_activity ? item.day_activity : '',
      id_start: item.id_start || null,
      id_end: item.id_end || null,
      desc_journee: '',
      transport: item.transport ? item.transport : '',
      distance: item.distance ? item.distance : '',
      categorie: '',
      image: item.image ? item.image : '',
      active: '2',
    }

    if (personalizedValue === '1') {
      this.setState({
        idItemModified: item.id,
        inputValue: newDayContent,
        modalContent: 'day_personalized',
        modalTitle: 'Do you want to create a new day ?',
        modalSimpleAction: false,
        modalDisabled: true,
      }, () => this.handleOpen())
    }
  }

  // On change pension
  onChangePension = content => (event, newValue) => {
    const { pensionList } = this.state
    let idValue = ''
    let value = ''
    if (typeof newValue === 'string') {
      // On press Enter
      const valueExist = pensionList.filter(d => d.pension === newValue)
      if (_.isEmpty(valueExist)) {
        this.setState({
          idItemModified: content.id,
          inputValue: newValue,
          modalContent: 'pension',
          modalType: '',
          modalTitle: 'Do you want to create a new element ?',
          modalSimpleAction: false,
          modalDisabled: true,
        }, () => this.handleOpen())
      } else {
        idValue = valueExist[0].id_pension
        value = valueExist[0].pension
      }
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      this.setState({
        idItemModified: content.id,
        inputValue: newValue.inputValue,
        modalContent: 'pension',
        modalTitle: 'Do you want to create a new element ?',
        modalSimpleAction: false,
        modalDisabled: true,
      }, () => this.handleOpen())
    } else if (!newValue) {
      idValue = null
      value = ''
    } else {
      idValue = newValue.id_pension
      value = newValue.pension
    }
    this.updateItem('pension', value, idValue, false)
    return null
  }

  filterOptionsPension = list => (options, params) => {
    // const filter = createFilterOptions();
    // const filtered = filter(options, params);
    const { pensionList } = this.state
    const filtered = _.filter(list, d => d.pension.toLowerCase().indexOf(params.inputValue.toLowerCase()) > -1)
    const valueExist = pensionList.filter(d => d.pension === params.inputValue)

    // Suggest the creation of a new value
    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: params.inputValue,
        pension: `Add "${params.inputValue}"`,
      });
    }
    return filtered;
  }

  getOptionLabelPension = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // after input clear
    if (!option.pension) {
      return ''
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.pension;
  }

   displayArchiveRadioButton = () => {
     const { item } = this.state
     return (
       <FormControl component="fieldset">
         <RadioGroup className="radio-group-active" aria-label="gender" name="gender1" value={item.personalized ? item.personalized : '0'} onChange={this.handleRadioChange}>
           <FormControlLabel value="0" control={<CSSRadio />} label="Standard" />
           <FormControlLabel value="1" control={<CSSRadio />} label="Personalized" />
         </RadioGroup>
       </FormControl>
     )
   }

   handleActivityDayChange = (content) => {
     const {
       item,
     } = this.state
     const tabArrayId = content.map(c => c.id_activity)
     this.setState({
       item: {
         ...item,
         day_activity: _.join(tabArrayId, ','),
       },
     });
     return true
   }


   displayPictures = () => {
     const {
       dayContent,
     } = this.state
     return (
       <CssGrid
         item
         xs={12}
         spacing={2}
       >
         <Uploader
           imageDataArray={this.getImageUrl()}
           saveImage={imagesDataArray => this.saveImage(imagesDataArray)}
           redirectToGallery={() => this.redirectToGallery()}
           deleteImage={(urlArray, imageArray) => this.deleteImage(urlArray, imageArray)}
           allowDrop="dayLine"
           nbMaxImg="5"
         />
       </CssGrid>
     )
   }

   // ***************************
   // return autocomplete input for day data

  displayAutocompleteDay = (field, content, list) => {
    const {
      validateError,
      item,
    } = this.state
    const HtmlTooltip = withStyles(theme => ({
      tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 520,
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #dadde9',
      },
    }))(Tooltip);
    return (
      item.personalized !== '1' ? (
        <HtmlTooltip
          placement="right-start"
          title={(
            <React.Fragment>
              <Interweave content={content.description} />
            </React.Fragment>
      )}
        >
          <Autocomplete
            id={`${field}-field-${content.id}`}
            value={content}
            onChange={this.onChangeDay(content)}
            disabled={item.personalized === '1'}
            filterOptions={this.filterOptionsDay}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={list}
            getOptionLabel={this.getOptionLabelDay}
            renderOption={option => option.nom_journee}
            freeSolo
            renderInput={params => (
              <Input
                {...params}
                label={_.capitalize(field)}
                margin="normal"
                placeholder={`Select a ${field} or create a new one`}
                variant="outlined"
                required
                error={validateError ? params.inputProps.value === '' : false}
              />
            )}
          />
        </HtmlTooltip>
      )
        : (
          <Input
            id="Titledisplay"
            label="Title day personalised"
            className="dayPerso"
            value={item && item.title ? item.title : ''}
            onChange={this.handleChange('title')}
            margin="normal"
            variant="outlined"
          />
        )

    );
  }

  displayAutocompleteAccomodation = (field, content, list) => {
    const {
      validateError,
    } = this.state
    return (
      <Autocomplete
        id={`${field}-field-${content.id}`}
        value={content}
        onChange={this.onChangeAccomodation(content)}
        filterOptions={this.filterOptionsAccomodation}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={list}
        getOptionLabel={this.getOptionLabelAccomodation}
        renderOption={option => option.nom_hebergement}
        freeSolo
        renderInput={params => (
          <Input
            {...params}
            label={_.capitalize(field)}
            margin="normal"
            placeholder={`Select a ${field} or create a new one`}
            variant="outlined"
            required
            error={validateError ? params.inputProps.value === '' : false}
          />
        )}
      />
    )
  }

  displayAutocompleteRoom = (field, content, list) => {
    const dayRoomList = !_.isEmpty(content) && content.room[0] !== 'none' ? content.room : []
    return (
      <Autocomplete
        multiple
        id={`${field}-field-${content.id}`}
        options={list.map(option => option.type_chambre)}
        value={dayRoomList}
        onChange={this.onChangeRoom(content, list)}
        filterOptions={this.filterOptionsRoom(list)}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        getOptionLabel={this.getOptionLabelRoom}
        renderOption={option => option.type_chambre}
        freeSolo
        renderTags={(value, getTagProps) => value.map((option, index) => (
          <Chip variant="outlined" label={`${content.nb_room[index] || 1}x ${option}` || ''} {...getTagProps({ index })} />
        ))
        }
        renderInput={params => (
          <Input
            {...params}
            variant="outlined"
            label="Room"
            margin="normal"
            className="largeWidth"
          />
        )}
      />
    )
  }

  displayAutocompleteRoom_opt = (field, content, list) => {
    const roomcheck = `room${field}`
    const nb_roomcheck = `nb_room${field}`
    const dayRoomList = !_.isEmpty(content) && content[roomcheck][0] !== 'none' ? content[roomcheck] : []


    return (
      <Autocomplete
        multiple
        id={`${roomcheck}-field-${content.id}`}
        options={list.map(option => option.type_chambre)}
        value={dayRoomList}
        onChange={this.onChangeRoom_opt(roomcheck, content, list)}
        filterOptions={this.filterOptionsRoom_opt(list)}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        getOptionLabel={this.getOptionLabelRoom}
        renderOption={option => option.type_chambre}
        freeSolo
        renderTags={(value, getTagProps) => value.map((option, index) => (
          <Chip variant="outlined" label={`${content[nb_roomcheck][index] || 1}x ${option}` || ''} {...getTagProps({ index })} />
        ))
        }
        renderInput={params => (
          <Input
            {...params}
            variant="outlined"
            label="Room"
            margin="normal"
            className="largeWidth"
          />
        )}
      />
    )
  }

  displayAutocompletePension = (field, content, list) => {
    const {
      validateError,
    } = this.state
    return (
      <Autocomplete
        id={`${field}-field-${content.id}`}
        value={content}
        onChange={this.onChangePension(content)}
        filterOptions={this.filterOptionsPension(list)}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={list}
        getOptionLabel={this.getOptionLabelPension}
        renderOption={option => option.pension}
        freeSolo
        renderInput={params => (
          <Input
            {...params}
            label={_.capitalize(field)}
            margin="normal"
            placeholder={`Select a ${field} or create a new one`}
            variant="outlined"
            required
            error={validateError ? params.inputProps.value === '' : false}
          />
        )}
      />
    )
  }

  displayInput = (field, list, key, classname, index) => {
    const {
      item,
    } = this.state
    const { validateError } = this.props
    return (
      <Autocomplete
        id={field + index}
        freeSolo
        options={!_.isEmpty(list) ? list.map(option => option[key]) : []}
        value={!_.isEmpty(item) && item[field] ? item[field] : ''}
        onChange={this.onChange(field)}
        onBlur={this.onBlur(field)}
        renderInput={params => (
          <Input
            {...params}
            label={_.capitalize(field)}
            margin="normal"
            className={classname}
            variant="outlined"
            required={field === 'title'}
            error={field === 'title' && !params.inputProps.value && validateError}
          />
        )}
      />
    )
  }

  displayInputActivity = activityList => (
    <Autocomplete
      id="activity"
      options={activityList}
      getOptionLabel={option => `${option.activity} (${option.locationGrand && option.locationGrand} ${option.universel === 1 ? ('universel') : (option.location && option.location)})`}
      onChange={this.onChangeActivity}
      renderInput={params => <TextField {...params} label="activity" variant="outlined" />}
    />
  )

  // ***************************
  // display element

  displayDayInfo = () => {
    const {
      item,
      daysListFiltered,
      locationList,
    } = this.state
    const {
      index,
    } = this.props
    return (
      <CssGrid item lg={6} md={12} className="day-line-options">
        <CssGrid
          container
          spacing={2}
          className="inLine"
        >
          <CssGrid item xs={1}>
            {this.displayDayClassement(index)}
          </CssGrid>
          <CssGrid item xs={2}>
            {this.displayInput('start', locationList, 'lieu', '', index)}
          </CssGrid>
          <CssGrid item xs={5}>
            {this.displayAutocompleteDay('title', item, daysListFiltered)}
            {/* {this.displayInput('title', daysListFiltered, 'nom_journee', '', index)} */}
          </CssGrid>
          <CssGrid item xs={2}>
            {this.displayVersionDay()}
          </CssGrid>
          <CssGrid item xs={2}>
            {this.displayInput('end', locationList, 'lieu', '', index)}
          </CssGrid>
        </CssGrid>
      </CssGrid>
    )
  }

  displayServiceInfo = () => {
    const {
      item,
      accomodationFiltered,
      pensionList,
    } = this.state
    const { isActionButtonDisabled } = this.props
    return (
      <CssGrid item lg={6} md={12} className="day-line-options">
        <CssGrid
          container
          spacing={2}
          className="inLine"
        >
          <CssGrid item xs={3}>
            {this.displayAutocompleteAccomodation('accomodation', item, accomodationFiltered)}
          </CssGrid>

          <CssGrid item xs={2}>
            {this.displayAutocompletePension('pension', item, pensionList)}
          </CssGrid>
          <CssGrid item xs={3}>
            {this.displayAssistance('guide')}
          </CssGrid>
          <CssGrid item xs={3}>
            {this.displayAssistance('driver')}
          </CssGrid>
          <CssGrid item xs={1}>
            {isActionButtonDisabled ? null : this.displayDeleteBtn()}
          </CssGrid>
        </CssGrid>
      </CssGrid>
    )
  }

  // ************************************************
  displayVersionDay = () => {
    const {
      versionValue,
      versionList,
      item,
    } = this.state
    return (
      <Input
        id="version"
        label="Version"
        select
        value={versionValue !== 0 ? versionValue : '1'}
        onChange={this.versionChange}
        margin="normal"
        disabled={item.personalized === '1'}
        variant="outlined"
        className=""
      >
        {versionList.map(day => (
          <MenuItem
            key={day.id_journee}
            value={day.version}
          >
            {day.version.toString()}
          </MenuItem>
        ))
        }
      </Input>
    )
  }

  // return icon for guide or driver assistance
  displayAssistance = (assistance) => {
    const { item } = this.state

    const { informationsContent } = this.props

    // eslint-disable-next-line no-nested-ternary
    return (
      assistance === 'guide' ? (
        <div>
          {informationsContent.theme_devis === 'parfum2' && (
          <Input
            id="assistance"
            name="assistance"
            label="Assistance guide"
            select
            margin="normal"
            variant="outlined"
            value={!_.isEmpty(item[assistance]) && item[assistance] ? item[assistance] : ''}
            onChange={this.handleChange(assistance)}
          >
            <MenuItem value="0">No guide No Driver</MenuItem>
            <MenuItem value="1">French guide and driver</MenuItem>
            <MenuItem value="2">English guide and driver</MenuItem>
            <MenuItem value="3">French guide and no driver</MenuItem>
            <MenuItem value="4">English guide and no driver</MenuItem>
          </Input>
          )}
          {informationsContent.theme_devis !== 'parfum2' && (
          <Input
            id="assistance"
            name="assistance"
            label="Assistance guide"
            select
            margin="normal"
            variant="outlined"
            value={!_.isEmpty(item[assistance]) && item[assistance] ? item[assistance] : ''}
            onChange={this.handleChange(assistance)}
          >

            <MenuItem value="0">No guide</MenuItem>
            <MenuItem value="1">French guide</MenuItem>
            <MenuItem value="2">English guide</MenuItem>
            <MenuItem value="3">English-speaking escort</MenuItem>
            <MenuItem value="4">Local guide</MenuItem>
          </Input>
          )}
        </div>
      )
        : (
          <div>
            {informationsContent.theme_devis === 'parfum2' && (
            <Input
              id="assistance"
              name="assistance"
              label="Assistance driver"
              select
              margin="normal"
              variant="outlined"
              value={!_.isEmpty(item[assistance]) && item[assistance] ? item[assistance] : ''}
              onChange={this.handleChange(assistance)}
            >
              <MenuItem value="0">Ticket visit included</MenuItem>
              <MenuItem value="1">Ticket visit not included</MenuItem>
            </Input>
            )}
            {informationsContent.theme_devis !== 'parfum2' && (
              <Input
                id="assistance"
                name="assistance"
                label="driver"
                select
                margin="normal"
                variant="outlined"
                value={!_.isEmpty(item[assistance]) && item[assistance] ? item[assistance] : ''}
                onChange={this.handleChange(assistance)}
              >
                <MenuItem value="0">no driver</MenuItem>
                <MenuItem value="1">driver</MenuItem>
                <MenuItem value="3">Local transports</MenuItem>
                <MenuItem value="4">bus</MenuItem>
                <MenuItem value="5">English speaking driver</MenuItem>
                <MenuItem value="6">French speaking driver</MenuItem>
              </Input>
            )}
          </div>
        )


    )
  }

  displayDeleteBtn = () => (
    <div
      title="delete"
      className="deleteDay"
      onClick={this.deleteDay}
    >
      <DeleteOutlineTwoToneIcon
        fontSize="large"
      />
    </div>
  )

  HTMLToPlainText = (htmlText) => {
    const parser = new DOMParser();
    const parsed = parser.parseFromString(htmlText, 'text/html');
    const plainText = parsed.body.textContent;
    return <CopyToClipboard text={plainText} />;
  }

  HTMLToHTML = (htmlText) => {
    navigator.clipboard.writeText(htmlText);
  }

  insertActivity = (participant, activityItem) => {
    const {
      token,
      item,
    } = this.state
    const {
      tripDetails,
      tripActivities,
      PUT_TRIP_ACTIVITY,
    } = this.props
    this.handleClose()
    const element = _.find(tripActivities[item.id - 1], { id_activity: activityItem.id_activity });
    // const nbAdult = tripDetails[0].nb_adulte && !isNaN(parseInt(tripDetails[0].nb_adulte, 10)) && parseInt(tripDetails[0].nb_adulte, 10)
    // const nbChild = tripDetails[0].nb_enfant && !isNaN(parseInt(tripDetails[0].nb_enfant, 10)) && parseInt(tripDetails[0].nb_enfant, 10)
    let nbSenior = null
    let nbAdult = null
    let nbChild = null
    if (activityItem.activity_type === 'individual') {
      nbSenior = participant && participant.senior && participant.senior
      nbAdult = participant && participant.adult && participant.adult
      nbChild = participant && participant.children && participant.children
    } else {
      nbAdult = participant && participant.nb && participant.nb
    }
    if (nbAdult) {
      if (element) {
        alert('this activity is already in the cotation.')
      } else {
        PUT_TRIP_ACTIVITY(token, tripDetails[0].id_circuit, item.id_journee, item.id - 1, activityItem.id_activity, nbSenior, nbAdult, nbChild)
      }
    } else {
      alert('Please define first the number')
    }
  }


  deleteActivity = (e, activityID) => {
    e.preventDefault()
    const {
      token,
      item,
    } = this.state
    const {
      tripDetails,
      tripActivities,
      DELETE_TRIP_ACTIVITIES,
    } = this.props
    const element = _.find(tripActivities[item.id - 1], { id_activity: activityID });
    if (element) {
      DELETE_TRIP_ACTIVITIES(token, tripDetails[0].id_circuit, item.id_journee, item.id - 1, activityID, 1)
    } else {
      alert('this activity is not in the cotation for this day.')
    }
  }


  displayDayClassement = (index) => {
    const {
      isDisplayedDescription,
    } = this.state
    if (isDisplayedDescription) {
      return (
        <div className="dayClassment" onClick={this.toggleDescriptionDay}>
          {index + 1}
          <img className="underline-curved-bottom" src={underlineCurvedBottom} srcSet={`${underlineCurvedBottom2x} 2x, ${underlineCurvedBottom3x} 3x`} alt="" />
        </div>
      )
    }
    return (
      <div className="dayClassment" onClick={this.toggleDescriptionDay}>
        {index + 1}
        <img className="underline-curved-right" src={underlineCurvedRight} srcSet={`${underlineCurvedRight2x} 2x, ${underlineCurvedRight3x} 3x`} alt="" />
      </div>
    )
  }

  displayDisplayModal = () => {
    const {
      item,
      isModalOpen,
      modalContent,
      modalTitle,
      modalType,
      modalDisabled,
      inputValue,
      modalSimpleAction,
    } = this.state
    return (
      <ModalComponent
        isModalOpen={isModalOpen}
        onClose={this.handleClose}
        onResponseNo={() => this.clearField()}
        onResponseYes={newItem => this.checkNewItem(newItem)}
        title={modalTitle}
        modalContent={modalContent}
        type={modalType}
        inputValue={inputValue}
        simpleAction={modalSimpleAction}
        disabled={modalDisabled}
        item={item}
      />
    )
  }


  updateItemsBilling = (item) => {
    // replace item when update
    const {
      tripAllDays,
    } = this.props

    const indexItem = _.findIndex(tripAllDays, o => o.id_journee === item.id_journee)
    _.map(tripAllDays, (o, index) => {
      if (index === indexItem) {
        return item
      }
      return o
    })

    // this.setState({
    //   items: newItems,
    //   isValidated: false,
    // })
  }


displayInputActivityContent = () => {
  const {
    item,
    locationList,
    activityItem,
    filterLoc,
  } = this.state
  const {
    index,
    activityList,
    userAccount,
  } = this.props
  const filteredLieuGrand = locationList.filter(ll => ll.id_lieu === item.id_start);
  const filteredActivityList = filterLoc
    ? activityList.filter(activity => (activity.active !== '2' && activity.id_location === item.id_start)
      || (!_.isEmpty(filteredLieuGrand) && activity.active !== '2' && activity.id_location_grand && activity.id_location_grand === filteredLieuGrand[0].id_lieu_grand)
      || (activity.active !== '2' && activity.id_location === item.id_end)
      || (activity.universel === 1))
    : activityList; const description = userAccount.language ? `${userAccount.language}_description` : 'fr_description'
  const parser = activityItem && !_.isEmpty(activityItem[description]) && new DOMParser();
  const parsed = activityItem && !_.isEmpty(activityItem[description]) && parser.parseFromString(activityItem[description], 'text/html');
  const plainText = activityItem && !_.isEmpty(activityItem[description]) && parsed.body.textContent;

  const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 520,
      fontSize: theme.typography.pxToRem(14),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);
  return (
    <div>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs={12}>
          -
          <FormControlLabel
            control={(
              <CssCheckbox
                checked={filterLoc ? !!filterLoc : false}
                onChange={() => this.setState({ filterLoc: !filterLoc })}
                name="filter by location or province"
              />
              )}
            label="filter by location or province"
          />
        </Grid>
        <Grid item xs={4}>
          {this.displayInputActivity(filteredActivityList)}

          {activityItem && activityItem.id_activity && (
          <div>
            <Grid
              container
              spacing={2}
            >
              <Grid item md={2} xs={2} className="option-div">
                <div className="option-div-css">
                  <HtmlTooltip
                    className="tooltip-activity"
                    placement="right-start"
                    title={(
                      <React.Fragment>
                        <Interweave content={activityItem ? activityItem[description] : ''} />
                      </React.Fragment>
)}
                  >
                    <Show />
                  </HtmlTooltip>
                </div>
              </Grid>
              <Grid item md={2} xs={2} className="option-div">
                <div className="option-div-css">
                  <Tooltip title="copy as text" aria-label="copy as text">

                    <CopyToClipboard text={plainText}>
                      <Copy />
                    </CopyToClipboard>
                  </Tooltip>

                </div>
              </Grid>
              <Grid item md={2} xs={2} className="option-div">
                <div className="option-div-css">
                  <Tooltip title="copy as html" aria-label="copy as html">
                    <CopyToClipboard text={activityItem && activityItem[description]}>
                      <Copyhtml />
                    </CopyToClipboard>
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
          </div>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

  // Display description wwhen click on Number line
  displayDescritption = (stripe) => {
    const {
      item,
      accomodationFiltered,
      displayAccoOpt,
      displayActivityContent,
      displayPerso,
    } = this.state
    const {
      index,
      informationsContent,
      roomByAccomodationList,
      isValidated,
    } = this.props
    const filteredRoomList = isNaN(item.id_accomodation) ? [] : roomByAccomodationList.filter(r => r.id_hebergement === item.id_accomodation)
    const filteredRoomList_opt1 = isNaN(item.id_accomodation_opt_1) ? [] : roomByAccomodationList.filter(r => r.id_hebergement === item.id_accomodation_opt_1)
    const filteredRoomList_opt2 = isNaN(item.id_accomodation_opt_2) ? [] : roomByAccomodationList.filter(r => r.id_hebergement === item.id_accomodation_opt_2)
    const { theme_devis } = informationsContent
    const backgroundColor = stripe ? 'background-stripe' : 'background-white'

    return (
      <div className="description">
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={10}>
            <div className="editorStyle">
              <p className={`editorTitle ${backgroundColor}`}>Description</p>
              <div>
                <TextEditor
                  object={item}
                  value={item && item.description ? item.description : ''}
                  handleEditorChange={this.handleEditorChange('description')}
                  isModalOpenInChildren={modalState => this.isModalOpenInChildrenEditor(modalState)}
                />
              </div>
            </div>
          </Grid>
          { item.personalized === '1'
            && (
              <Grid item xs={2} className="logo-company-div">

                {this.displayPictures()}
              </Grid>
            )
            }
        </Grid>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div> Activities description:</div>
          <Switch
            onChange={() => this.setState({ displayActivityContent: !displayActivityContent })}
            label="display"
            color="primary"
          />
          <div> Personnalisation options:</div>
          <Switch
            onChange={() => this.setState({ displayPerso: !displayPerso })}
            label="display"
            color="primary"
          />
        </div>

        {displayActivityContent
          ? (
            <div>
              {this.displayInputActivityContent()}
            </div>
          ) : (null)}
        {displayPerso
          ? (
            <div>
              <Grid
                container
                spacing={2}
              >
                <Grid item xs={12}>
                  {this.displayArchiveRadioButton()}
                </Grid>
                <Grid item xs={6}>
                  <Input
                    id="transport"
                    label="Transport"
                    disabled={item.personalized === '0'}
                    onChange={this.handleChange('transport')}
                    value={item && item.transport ? item.transport : ''}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                {
              theme_devis === 'prenium' || theme_devis === 'duyen-template' || theme_devis === 'duyen'
                ? null
                : (
                  <Grid item xs={6}>
                    <Input
                      id="distance"
                      label="Distance"
                      disabled={item.personalized === '0'}
                      onChange={this.handleChange('distance')}
                      value={item && item.distance ? item.distance : ''}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                )
            }
              </Grid>
              <Grid
                container
                spacing={2}
              >
                {
            theme_devis === 'prenium' || theme_devis === 'duyen-template' || theme_devis === 'duyen'
              ? null
              : (
                <Grid item xs={6}>
                  <Input
                    id="shortTitle"
                    label="Short Title"
                    onChange={this.handleChange('shortTitle')}
                    disabled={item.personalized === '0'}
                    value={item && item.shortTitle ? item.shortTitle : ''}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              )
          }
                <Grid item xs={6}>
                  <Input
                    id="dayduration"
                    label="duration"
                    disabled={item.personalized === '0'}
                    onChange={this.handleChange('day_duration')}
                    type="number"
                    value={item && item.day_duration ? item.day_duration : ''}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </div>
          ) : (null)}
      </div>
    )
  }

  toggleDescriptionDay = () => {
    const { isDisplayedDescription } = this.state
    this.setState({
      isDisplayedDescription: !isDisplayedDescription,
      displayActivityContent: false,
      displayPerso: false,
    })
  }

  // ***************************
  render() {
    const {
      item,
      isModalOpen,
      isDisplayedDescription,
    } = this.state
    const {
      index,
      tripDetails,
    } = this.props
    // background striped line by line
    const stripe = index % 2 === 0 ? 'stripe' : null
    const defaultAdult = tripDetails[0].nb_adulte && !isNaN(parseInt(tripDetails[0].nb_adulte, 10)) ? parseInt(tripDetails[0].nb_adulte, 10) : 1
    const defaultChildren = tripDetails[0].nb_enfant && !isNaN(parseInt(tripDetails[0].nb_enfant, 10)) ? parseInt(tripDetails[0].nb_enfant, 10) : 0
    const defaultSenior = tripDetails[0].nb_senior && !isNaN(parseInt(tripDetails[0].nb_senior, 10)) ? parseInt(tripDetails[0].nb_senior, 10) : 0

    return (
      <div className={`dayLine ${stripe}`}>
        {/* <div className="inLine"> */}
        <CssGrid
          container
          spacing={2}
          className="inLine"
        >
          {this.displayDayInfo()}
          {this.displayServiceInfo()}
        </CssGrid>
        {/* </div> */}

        {isDisplayedDescription && this.displayDescritption(stripe)}
        {isModalOpen && this.displayDisplayModal()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  daysList: _.filter(state.api.daysList, d => d.id_journee !== 1 && d.active === '1'),
  pensionList: state.api.pensionList,
  locationList: _.filter(state.api.locationList, l => l.id_lieu !== 1),
  putLocationContent: state.api.putLocationContent,
  activityList: state.activityReducer.activityList,
  accomodationList: state.api.accomodationList,
  roomList: state.api.roomList,
  tripAllDays: state.api.tripAllDays,
  userAccount: state.userAccountReducer.userAccount,
  putTripActivity: state.billingReducer.putTripActivity,
  roomByAccomodationList: state.api.roomByAccomodationList,
  putRoomByAccomodation: state.api.putRoomByAccomodation,
  putRoomContent: state.api.putRoomContent,
  putPensionContent: state.api.putPensionContent,
  putDayContent: state.api.putDayContent,
  informationsContent: state.informationsReducer.informationsContent,
  tripDetails: state.api.tripDetails,
  tripActivities: state.billingReducer.tripActivities,
  deleteTripActivities: state.billingReducer.deleteTripActivities,
})

const mapDispatchToProps = dispatch => ({
  GET_PENSION_LIST: userId => dispatch({
    type: 'GET_PENSION_LIST',
    userId,
  }),
  GET_DAYS_LIST: userId => dispatch({
    type: 'GET_DAYS_LIST',
    userId,
  }),
  GET_ROOM_LIST: userId => dispatch({
    type: 'GET_ROOM_LIST',
    userId,
  }),
  RESET_ROOM_CONTENT: () => dispatch({
    type: 'RESET_ROOM_CONTENT',
  }),
  RESET_PENSION_CONTENT: () => dispatch({
    type: 'RESET_PENSION_CONTENT',
  }),
  PUT_PENSION_CONTENT: (token, pensionContent, action) => dispatch({
    type: 'PUT_PENSION_CONTENT',
    token,
    pensionContent,
    action,
  }),
  RESET_PUT_TRIP_ACTIVITY: () => dispatch({
    type: 'RESET_PUT_TRIP_ACTIVITY',
  }),
  GET_LOCATION_LIST: userId => dispatch({
    type: 'GET_LOCATION_LIST',
    userId,
  }),
  RESET_DAY_CONTENT: () => dispatch({
    type: 'RESET_DAY_CONTENT',
  }),
  GET_ROOM_BY_ACCOMODATION_LIST: userId => dispatch({
    type: 'GET_ROOM_BY_ACCOMODATION_LIST',
    userId,
  }),
  DELETE_TRIP_ACTIVITIES: (token, idCircuit, idJournee, dayIndex, idActivity, nbActivities) => dispatch({
    type: 'DELETE_TRIP_ACTIVITIES',
    token,
    idCircuit,
    idJournee,
    dayIndex,
    idActivity,
    nbActivities,
  }),
  RESET_DELETE_TRIP_ACTIVITIES: () => dispatch({
    type: 'RESET_DELETE_TRIP_ACTIVITIES',
  }),
  RESET_ROOM_BY_ACCOMODATION: () => dispatch({
    type: 'RESET_ROOM_BY_ACCOMODATION',
  }),
  RESET_LOCATION_CONTENT: () => dispatch({
    type: 'RESET_LOCATION_CONTENT',
  }),
  PUT_LOCATION_CONTENT: (token, locationContent, action) => dispatch({
    type: 'PUT_LOCATION_CONTENT',
    token,
    locationContent,
    action,
  }),
  VALIDATE_STEP: (page, value) => dispatch({
    type: 'VALIDATE_STEP',
    page,
    value,
  }),
  PUT_DAY_CONTENT: (token, dayContent, action) => dispatch({
    type: 'PUT_DAY_CONTENT',
    token,
    dayContent,
    action,
  }),
  PUT_TRIP_ACTIVITY: (token, idCircuit, idJournee, dayIndex, idActivity, nbSenior, nbAdult, nbChild) => dispatch({
    type: 'PUT_TRIP_ACTIVITY',
    token,
    idCircuit,
    idJournee,
    dayIndex,
    idActivity,
    nbSenior,
    nbAdult,
    nbChild,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DayLine))
