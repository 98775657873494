
const resourcesColumns = [
  {
    title: 'Quote',
    field: 'nom_devis',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Creation Date',
    field: 'date_creation',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Departure Date',
    field: 'date_depart',
    editable: 'never',
    resizable: true,
  },

  {
    title: 'Client',
    field: 'demandeur',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'email',
    field: 'e_mail',
    editable: 'never',
    resizable: true,
  },
]

const resourcesColumnsRequest = [
  {
    title: 'Creation Date',
    field: 'date_request',
  },
  {
    title: 'Date Depart',
    field: 'date_departure',
  },
  {
    title: 'Traveller Number',
    field: 'nb_traveler',
  },
  {
    title: 'Trip name',
    field: 'trip_name',
    hidden: true,
    resizable: true,
    cellStyle: {
      backgroundColor: '#e57373',
    },
  },
]
const resourcesColumnsRequest1 = [
  {
    title: 'Creation Date',
    field: 'date_request',
  },
  {
    title: 'Date Depart',
    field: 'date_departure',
  },
  {
    title: 'Traveller Number',
    field: 'nb_traveler',
  },
]


export { resourcesColumns, resourcesColumnsRequest, resourcesColumnsRequest1 }
