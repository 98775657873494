import axios from 'axios'
import stage from '../../config'

const apiUrl = stage.api.duyen.url

export default {
  get_list_circuit(token, dateTampon, dateTamponFin, filterAdvisor) {
    return axios.get(`${apiUrl}/api/trip/${dateTampon}/${dateTamponFin}/${filterAdvisor}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  delete_trip(token, idTripSelected) {
    return axios.delete(`${apiUrl}/api/trip/${idTripSelected}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_trip_details(token, idTripSelected) {
    return axios.get(`${apiUrl}/api/tripDetails/${idTripSelected}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_trip_devis_final(token, idDevis) {
    return axios.get(`${apiUrl}/api/tripDevisFinal/${idDevis}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  put_trip_details(token, tripDetails, action) {
    const userId = localStorage.getItem('userId')
    switch (action) {
      case 'update':
        return axios.put(`${apiUrl}/api/tripDetails/update`, { tripDetails, userId }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'version':
        return axios.put(`${apiUrl}/api/tripDetails/newVersion`, { tripDetails, userId }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'copy':
        return axios.put(`${apiUrl}/api/tripDetails/copyTrip`, { tripDetails, userId }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'customer':
        return axios.put(`${apiUrl}/api/tripDetails/customer`, { tripDetails, userId }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'new_trip':
        return axios.put(`${apiUrl}/api/tripDetails/newTrip`, { tripDetails, userId }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      default:
        return null
    }
  },
  put_trip_devis_final(token, tripDevisFinal, action) {
    const userId = localStorage.getItem('userId')
    switch (action) {
      case 'update':
        return axios.put(`${apiUrl}/api/tripDevisFinal/update`, { tripDevisFinal, userId }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'create':
        return axios.put(`${apiUrl}/api/tripDevisFinal/create`, { tripDevisFinal, userId }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      default:
        return null
    }
  },

  put_trip_details_url(token, tripDetails) {
    const userId = localStorage.getItem('userId')
    return axios.put(`${apiUrl}/api/tripDetails/updateUrl`, { tripDetails, userId }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
  get_trip_content(idTourSelected) {
    return axios.get(`${apiUrl}/api/tripContent/${idTourSelected}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  put_trip_content(token, newTripContent, dayContentPersonalized, action) {
    switch (action) {
      case 'update':
        return axios.put(`${apiUrl}/api/tripContent/update`, { newTripContent, dayContentPersonalized }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'modify':
        return axios.put(`${apiUrl}/api/tripContent/modify`, { newTripContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'create':
        return axios.put(`${apiUrl}/api/tripContent/create`, { newTripContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'version':
        return axios.put(`${apiUrl}/api/tripContent/version`, { newTripContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      default:
        return null;
    }
  },
  get_trip_alldays(tripDaysList, idCircuit) {
    return axios.get(`${apiUrl}/api/tripDays/${tripDaysList}/${idCircuit}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_trip_alldaysaccomodation(tripDaysList, tripAccoList) {
    return axios.get(`${apiUrl}/api/tripDays/daysaccomodationList/${tripDaysList}/${tripAccoList}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_trip_accomodation(tripAccomodationList) {
    return axios.get(`${apiUrl}/api/tripAccomodation/${tripAccomodationList}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },

  get_trip_accomodation_opt1(tripAccomodationList) {
    return axios.get(`${apiUrl}/api/tripAccomodationOpt1/${tripAccomodationList}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_trip_accomodation_opt2(tripAccomodationList) {
    return axios.get(`${apiUrl}/api/tripAccomodationOpt2/${tripAccomodationList}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_trip_season_by_accomodation(tripAccomodationList) {
    return axios.get(`${apiUrl}/api/tripAccomodation/seasonbyaccomodation/${tripAccomodationList}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_trip_season_by_accomodation_opt1(tripAccomodationList) {
    return axios.get(`${apiUrl}/api/tripAccomodationOpt1/seasonbyaccomodation/${tripAccomodationList}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_trip_season_by_accomodation_opt2(tripAccomodationList) {
    return axios.get(`${apiUrl}/api/tripAccomodationOpt2/seasonbyaccomodation/${tripAccomodationList}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_trip_pension(tripPensionList) {
    return axios.get(`${apiUrl}/api/tripPension/${tripPensionList}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_trip_room(tripRoomList, tripAccoList) {
    return axios.get(`${apiUrl}/api/tripRoom/${tripRoomList}/${tripAccoList}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_trip_room_opt1(tripRoomList, tripAccoList) {
    return axios.get(`${apiUrl}/api/tripRoomOpt1/${tripRoomList}/${tripAccoList}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },

  get_trip_room_opt2(tripRoomList, tripAccoList) {
    return axios.get(`${apiUrl}/api/tripRoomOpt2/${tripRoomList}/${tripAccoList}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  // LIST
  get_status_list() {
    return axios.get(`${apiUrl}/api/lists/statusList`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_days_list(userId) {
    return axios.get(`${apiUrl}/api/lists/daysList/${userId}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_location_list(userId) {
    return axios.get(`${apiUrl}/api/lists/locationList/${userId}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },

  get_location_grand_list(userId) {
    return axios.get(`${apiUrl}/api/lists/locationGrandList/${userId}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_pension_list(userId) {
    return axios.get(`${apiUrl}/api/lists/pensionList/${userId}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_accomodation_list(userId) {
    return axios.get(`${apiUrl}/api/lists/accomodationList/${userId}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_room_list(userId) {
    return axios.get(`${apiUrl}/api/lists/roomList/${userId}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_accomodation_type_list(userId) {
    return axios.get(`${apiUrl}/api/lists/accomodationTypeList/${userId}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_room_by_accomodation_list(userId) {
    return axios.get(`${apiUrl}/api/lists/roomByAccomodationList/${userId}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_room_by_accomodation(userId, accoId) {
    return axios.get(`${apiUrl}/api/lists/roomByAccomodation/${userId}/${accoId}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_season_by_accomodation(userId, accoId) {
    return axios.get(`${apiUrl}/api/lists/seasonByAccomodation/${userId}/${accoId}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_season_by_activity(userId, actId) {
    return axios.get(`${apiUrl}/api/lists/seasonByActivity/${userId}/${actId}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_season_by_activity_all(userId) {
    return axios.get(`${apiUrl}/api/lists/seasonByActivityAll/${userId}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_profil_user_list() {
    return axios.get(`${apiUrl}/api/lists/profilUserList`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  // MANAGEMENT
  put_day_content(token, dayContent, action) {
    switch (action) {
      case 'update':
        return axios.put(`${apiUrl}/api/management/day/update`, { dayContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'create':
        return axios.put(`${apiUrl}/api/management/day/create`, { dayContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      default:
        return null;
    }
  },
  delete_day_content(token, dayId) {
    return axios.delete(`${apiUrl}/api/management/day/delete/${dayId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
  put_accomodation_content(token, accomodationContent, action) {
    switch (action) {
      case 'update':
        return axios.put(`${apiUrl}/api/management/accomodation/update`, { accomodationContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'create':
        return axios.put(`${apiUrl}/api/management/accomodation/create`, { accomodationContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      default:
        return null;
    }
  },
  delete_accomodation_content(token, accomodationId) {
    return axios.delete(`${apiUrl}/api/management/accomodation/delete/${accomodationId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
  put_location_content(token, locationContent, action) {
    switch (action) {
      case 'update':
        return axios.put(`${apiUrl}/api/management/location/update`, { locationContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'create':
        return axios.put(`${apiUrl}/api/management/location/create`, { locationContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      default:
        return null;
    }
  },
  put_location_grand_content(token, locationGrandContent, action) {
    switch (action) {
      case 'update':
        return axios.put(`${apiUrl}/api/management/location_grand/update`, { locationGrandContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'create':
        return axios.put(`${apiUrl}/api/management/location_grand/create`, { locationGrandContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      default:
        return null;
    }
  },
  delete_location_content(token, locationId) {
    return axios.delete(`${apiUrl}/api/management/location/delete/${locationId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
  delete_location_grand_content(token, locationGrandId) {
    return axios.delete(`${apiUrl}/api/management/location_grand/delete/${locationGrandId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },

  put_pension_content(token, pensionContent, action) {
    switch (action) {
      case 'update':
        return axios.put(`${apiUrl}/api/management/pension/update`, { pensionContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'create':
        return axios.put(`${apiUrl}/api/management/pension/create`, { pensionContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      default:
        return null;
    }
  },
  delete_pension_content(token, pensionId) {
    return axios.delete(`${apiUrl}/api/management/pension/delete/${pensionId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
  put_room_content(token, roomContent, action) {
    switch (action) {
      case 'update':
        return axios.put(`${apiUrl}/api/management/room/update`, { roomContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'create':
        return axios.put(`${apiUrl}/api/management/room/create`, { roomContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      default:
        return null;
    }
  },
  delete_room_content(token, roomId) {
    return axios.delete(`${apiUrl}/api/management/room/delete/${roomId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
  put_accomodation_type_content(token, accomodationTypeContent, action) {
    switch (action) {
      case 'update':
        return axios.put(`${apiUrl}/api/management/accomodationType/update`, { accomodationTypeContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'create':
        return axios.put(`${apiUrl}/api/management/accomodationType/create`, { accomodationTypeContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      default:
        return null;
    }
  },
  delete_accomodation_type_content(token, accomodationTypeId) {
    return axios.delete(`${apiUrl}/api/management/accomodationType/delete/${accomodationTypeId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
  put_room_by_accomodation(token, roomByAccomodation, action) {
    switch (action) {
      case 'create':
        return axios.put(`${apiUrl}/api/management/roomByAccomodation/create`, { roomByAccomodation }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      default:
        return null;
    }
  },

  put_season_by_accomodation(token, seasonByAccomodation, SeasonDifftoupdate, SeasonDifftoDelete, SeasonDifftoAdd, action) {
    switch (action) {
      case 'create':
        return axios.put(`${apiUrl}/api/management/seasonByAccomodation/create`, { seasonByAccomodation }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'update':
        return axios.put(`${apiUrl}/api/management/seasonByAccomodation/update`, { SeasonDifftoupdate, SeasonDifftoDelete, SeasonDifftoAdd }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      default:
        return null;
    }
  },
  put_season_by_activity(token, seasonByActivity, SeasonDifftoupdate, SeasonDifftoDelete, SeasonDifftoAdd, action) {
    switch (action) {
      case 'create':
        return axios.put(`${apiUrl}/api/management/seasonByActivity/create`, { seasonByActivity }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'update':
        return axios.put(`${apiUrl}/api/management/seasonByActivity/update`, { SeasonDifftoupdate, SeasonDifftoDelete, SeasonDifftoAdd }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      default:
        return null;
    }
  },
  put_room_price_by_accomodation(token, roomByAccomodation) {
    return axios.put(`${apiUrl}/api/management/roomByAccomodation/update`, { roomByAccomodation }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
  delete_room_by_accomodation(token, roomByAccomodationId) {
    return axios.delete(`${apiUrl}/api/management/roomByAccomodation/delete/${roomByAccomodationId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },

  delete_season_by_accomodation(token, seasonByAccomodationId) {
    return axios.delete(`${apiUrl}/api/management/seasonByAccomodation/delete/${seasonByAccomodationId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
  delete_season_by_activity(token, seasonByActivityId) {
    return axios.delete(`${apiUrl}/api/management/seasonByActivity/delete/${seasonByActivityId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },

  // USERS
  get_list_email_users() {
    return axios.get(`${apiUrl}/api/register`)
  },
  get_list_registred_users() {
    return axios.get(`${apiUrl}/api/login`)
  },
  post_user_signin(email, password) {
    return axios.post(`${apiUrl}/api/login`, { email, password }, {
    })
      .then(res => res.data)
      .catch(err => console.log(err))
  },
  get_unvalid_token_list() {
    return axios.get(`${apiUrl}/api/unvalidToken`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
}
