import _ from 'lodash'
import moment from 'moment'
import {
  POST_SIGNIN_RESULTS,
  GET_UNVALID_TOKEN_LIST_RESULTS,
  GET_LIST_CIRCUIT_RESULTS,
  DELETE_TRIP_RESULTS,
  RESET_DELETE_TRIP,
  ROW_TRIP_SELECT,
  ID_TRIP_SELECT,
  GET_TRIP_DETAILS_RESULTS,
  PUT_TRIP_DETAILS_RESULTS,
  PUT_TRIP_DETAILS_URL_RESULTS,
  PUT_TRIP_DEVIS_FINAL_RESULTS,
  RESET_TRIP_DETAILS,
  RESET_TRIP_DEVIS_FINAL,
  GET_TRIP_CONTENT_RESULTS,
  PUT_TRIP_CONTENT_RESULTS,
  RESET_TRIP_CONTENT,
  GET_TRIP_ALLDAYS_RESULTS,
  GET_TRIP_ALLDAYS_ACCOMODATION_RESULTS,
  GET_TRIP_ACCOMODATION_RESULTS,
  GET_TRIP_ACCOMODATION_OPT1_RESULTS,
  GET_TRIP_ACCOMODATION_OPT2_RESULTS,
  GET_TRIP_SEASON_BY_ACCOMODATION_RESULTS,
  GET_TRIP_SEASON_BY_ACCOMODATION_OPT1_RESULTS,
  GET_TRIP_SEASON_BY_ACCOMODATION_OPT2_RESULTS,
  GET_TRIP_PENSION_RESULTS,
  GET_TRIP_DEVIS_FINAL_RESULTS,
  GET_TRIP_ROOM_RESULTS,
  GET_TRIP_ROOM_OPT1_RESULTS,
  GET_TRIP_ROOM_OPT2_RESULTS,
  GET_STATUS_LIST_RESULTS,
  GET_DAYS_LIST_RESULTS,
  GET_LOCATION_LIST_RESULTS,
  GET_LOCATION_GRAND_LIST_RESULTS,
  GET_PENSION_LIST_RESULTS,
  GET_ACCOMODATION_LIST_RESULTS,
  GET_ROOM_LIST_RESULTS,
  GET_ACCOMODATION_TYPE_LIST_RESULTS,
  GET_ROOM_BY_ACCOMODATION_LIST_RESULTS,
  GET_ROOM_BY_ACCOMODATION_RESULTS,
  GET_SEASON_BY_ACCOMODATION_RESULTS,
  GET_SEASON_BY_ACTIVITY_RESULTS,
  GET_SEASON_BY_ACTIVITY_ALL_RESULTS,
  GET_PROFIL_USER_LIST_RESULTS,
  PUT_DAY_CONTENT_RESULTS,
  DELETE_DAY_CONTENT_RESULTS,
  RESET_DAY_CONTENT,
  RESET_LIST_CIRCUIT,
  PUT_ACCOMODATION_CONTENT_RESULTS,
  DELETE_ACCOMODATION_CONTENT_RESULTS,
  RESET_ACCOMODATION_CONTENT,
  PUT_LOCATION_CONTENT_RESULTS,
  PUT_LOCATION_GRAND_CONTENT_RESULTS,
  DELETE_LOCATION_CONTENT_RESULTS,
  DELETE_LOCATION_GRAND_CONTENT_RESULTS,
  RESET_LOCATION_CONTENT,
  RESET_LOCATION_GRAND_CONTENT,
  PUT_PENSION_CONTENT_RESULTS,
  DELETE_PENSION_CONTENT_RESULTS,
  RESET_PENSION_CONTENT,
  PUT_ROOM_CONTENT_RESULTS,
  DELETE_ROOM_CONTENT_RESULTS,
  RESET_ROOM_CONTENT,
  PUT_ACCOMODATION_TYPE_CONTENT_RESULTS,
  DELETE_ACCOMODATION_TYPE_CONTENT_RESULTS,
  RESET_ACCOMODATION_TYPE_CONTENT,
  PUT_ROOM_BY_ACCOMODATION_RESULTS,
  PUT_SEASON_BY_ACCOMODATION_RESULTS,
  PUT_SEASON_BY_ACTIVITY_RESULTS,
  PUT_ROOM_PRICE_BY_ACCOMODATION_RESULTS,
  DELETE_ROOM_BY_ACCOMODATION_RESULTS,
  DELETE_SEASON_BY_ACCOMODATION_RESULTS,
  DELETE_SEASON_BY_ACTIVITY_RESULTS,
  RESET_ROOM_BY_ACCOMODATION,
  RESET_ROOM_PRICE_BY_ACCOMODATION,
  RESET_SEASON_BY_ACCOMODATION,
  RESET_SEASON_BY_ACTIVITY,
  RESET_TRIP_INFORMATION,
  VALIDATE_STEP,
  ACTIVE_STEP,
  RESET_APP,
  LOADER,
} from './actions'

const initialState = {
  userLog: {},
  unvalidTokenList: [],
  listCircuit: [],
  deletedTrip: {},
  rowTripSelected: [],
  idTripSelected: null,
  isNewTrip: true,
  isDetailModified: false,
  isDuplicatedTrip: false,
  tripDetails: [],
  putTripDetails: null,
  putTripContent: null,
  tripContent: null,
  tripAllDays: null,
  tripAllDaysAccomodations: null,
  tripSeasonByAccomodation: [],
  tripSeasonByAccomodationOpt1: [],
  tripSeasonByAccomodationOpt2: [],
  tripAccomodation: null,
  tripAccomodationOpt1: null,
  tripAccomodationOpt2: null,
  tripPension: null,
  tripRoom: null,
  tripRoomOpt1: null,
  tripRoomOpt2: null,
  activeStep: null,
  isTripStepValidated: false,
  isTripDetailsStepValidated: false,
  isDaysListStepValidated: false,
  isTripExtensionStepValidated: false,
  isBillingStepValidated: false,
  isLoading: true,
  statusList: [],
  daysList: [],
  locationList: [],
  pensionList: [],
  accomodationList: [],
  roomList: [],
  accomodationTypeList: [],
  roomByAccomodationList: [],
  profilUserList: [],
  putDayContent: { success: false },
  deleteDayContent: null,
  putAccomodationContent: { success: false },
  deleteAccomodationContent: null,
  putLocationContent: { success: false },
  deleteLocationContent: null,
  putPensionContent: { success: false },
  deletePensionContent: null,
  putRoomContent: { success: false },
  deleteRoomContent: null,
  putAccomodationTypeContent: { success: false },
  deleteAccomodationTypeContent: null,
  putRoomByAccomodation: { success: false },
  deleteRoomByAccomodation: null,
}

export default (state = initialState, action) => {
  let sortedList = []
  switch (action.type) {
    case RESET_APP:
      return initialState
    case POST_SIGNIN_RESULTS:
      return {
        ...state,
        userLog: action.userLog,
      }
    case GET_UNVALID_TOKEN_LIST_RESULTS:
      return {
        ...state,
        unvalidTokenList: action.unvalidTokenList.map(t => t.token),
      }
    case GET_LIST_CIRCUIT_RESULTS:
      if (!_.isEmpty(action.listCircuit) && !action.listCircuit[0].error) {
        _.map(action.listCircuit, (l) => {
          l.date_creation = moment(l.date_creation).format('YYYY-MM-DD')
          l.date_depart = moment(l.date_depart).format('YYYY-MM-DD')
        })
        sortedList = action.listCircuit.sort((a, b) => {
          if (_.capitalize(a.nom_devis) > _.capitalize(b.nom_devis)) return 1
          if (_.capitalize(a.nom_devis) < _.capitalize(b.nom_devis)) return -1
          return 0
        })
      }
      return {
        ...state,
        listCircuit: sortedList,
      }
    case DELETE_TRIP_RESULTS:
      return {
        ...state,
        deletedTrip: action.deletedTrip,
      }
    case RESET_DELETE_TRIP:
      return {
        ...state,
        deletedTrip: {},
        rowTripSelected: [],
      }
    case ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.activeStep,
      }
    case ROW_TRIP_SELECT:
      return {
        ...state,
        rowTripSelected: action.rowsSelected,
      }
    case ID_TRIP_SELECT:
      return {
        ...state,
        idTripSelected: action.idTripSelected ? action.idTripSelected : null,
        isNewTrip: action.isNewTrip || false,
        isDetailModified: action.isDetailModified || false,
        isDuplicatedTrip: action.isDuplicatedTrip || false,
      }
    case GET_TRIP_DETAILS_RESULTS:
      if (!_.isEmpty(action.tripDetails) && !action.tripDetails[0].error) {
        _.map(action.tripDetails, (l) => {
          l.date_creation = moment(l.date_creation).format('DD-MM-YYYY')
          if (l.date_modif) {
            l.date_modif = moment(l.date_modif).format('DD-MM-YYYY')
          }
        })
      }
      return {
        ...state,
        tripDetails: action.tripDetails,
      }
    case PUT_TRIP_DETAILS_RESULTS:
      return {
        ...state,
        putTripDetails: action.putTripDetails,
      }
    case PUT_TRIP_DETAILS_URL_RESULTS:
      return {
        ...state,
        putTripDetailsUrl: action.putTripDetails,
      }
    case PUT_TRIP_DEVIS_FINAL_RESULTS:
      return {
        ...state,
        putTripDevisFinal: action.putTripDevisFinal,
      }
    case RESET_TRIP_DETAILS:
      return {
        ...state,
        putTripDetails: { success: false },
      }
    case RESET_TRIP_DEVIS_FINAL:
      return {
        ...state,
        putTripDevisFinal: { success: false },
      }
    case GET_TRIP_CONTENT_RESULTS:
      return {
        ...state,
        tripContent: action.tripContent,
      }
    case PUT_TRIP_CONTENT_RESULTS:
      return {
        ...state,
        putTripContent: action.putTripContent,
      }
    case RESET_TRIP_CONTENT:
      return {
        ...state,
        putTripContent: null,
      }
    case GET_TRIP_ALLDAYS_RESULTS:
      return {
        ...state,
        tripAllDays: action.tripAllDays,
      }
    case GET_TRIP_ALLDAYS_ACCOMODATION_RESULTS:
      return {
        ...state,
        tripAllDaysAccomodations: action.tripAllDaysAccomodations,
      }
    case GET_TRIP_ACCOMODATION_RESULTS:
      return {
        ...state,
        tripAccomodation: action.tripAccomodation,
      }
    case GET_TRIP_ACCOMODATION_OPT1_RESULTS:
      return {
        ...state,
        tripAccomodationOpt1: action.tripAccomodationOpt1,
      }
    case GET_TRIP_ACCOMODATION_OPT2_RESULTS:
      return {
        ...state,
        tripAccomodationOpt2: action.tripAccomodationOpt2,
      }
    case GET_TRIP_SEASON_BY_ACCOMODATION_RESULTS:
      return {
        ...state,
        tripSeasonByAccomodation: action.tripSeasonByAccomodation,
      }
    case GET_TRIP_SEASON_BY_ACCOMODATION_OPT1_RESULTS:
      return {
        ...state,
        tripSeasonByAccomodationOpt1: action.tripSeasonByAccomodationOpt1,
      }
    case GET_TRIP_SEASON_BY_ACCOMODATION_OPT2_RESULTS:
      return {
        ...state,
        tripSeasonByAccomodationOpt2: action.tripSeasonByAccomodationOpt2,
      }
    case GET_TRIP_PENSION_RESULTS:
      return {
        ...state,
        tripPension: action.tripPension,
      }
    case GET_TRIP_DEVIS_FINAL_RESULTS:
      return {
        ...state,
        tripDevisFinal: action.tripDevisFinal,
      }
    case GET_TRIP_ROOM_RESULTS:
      return {
        ...state,
        tripRoom: action.tripRoom,
      }
    case GET_TRIP_ROOM_OPT1_RESULTS:
      return {
        ...state,
        tripRoomOpt1: action.tripRoomOpt1,
      }
    case GET_TRIP_ROOM_OPT2_RESULTS:
      return {
        ...state,
        tripRoomOpt2: action.tripRoomOpt2,
      }
    case GET_STATUS_LIST_RESULTS:
      return {
        ...state,
        statusList: action.statusList,
      }
    case GET_DAYS_LIST_RESULTS:
      return {
        ...state,
        daysList: action.daysList,
      }
    case GET_LOCATION_LIST_RESULTS:
      return {
        ...state,
        locationList: action.locationList,
      }
    case GET_LOCATION_GRAND_LIST_RESULTS:
      return {
        ...state,
        locationGrandList: action.locationGrandList,
      }
    case GET_PENSION_LIST_RESULTS:
      return {
        ...state,
        pensionList: action.pensionList,
      }
    case GET_ACCOMODATION_LIST_RESULTS:
      return {
        ...state,
        accomodationList: action.accomodationList,
      }
    case GET_ROOM_LIST_RESULTS:
      return {
        ...state,
        roomList: action.roomList,
      }
    case GET_ACCOMODATION_TYPE_LIST_RESULTS:
      return {
        ...state,
        accomodationTypeList: action.accomodationTypeList,
      }
    case GET_ROOM_BY_ACCOMODATION_LIST_RESULTS:
      return {
        ...state,
        roomByAccomodationList: action.roomByAccomodationList,
      }
    case GET_ROOM_BY_ACCOMODATION_RESULTS:
      return {
        ...state,
        roomByAccomodation: action.roomByAccomodation,
      }
    case GET_SEASON_BY_ACCOMODATION_RESULTS:
      return {
        ...state,
        seasonByAccomodation: action.seasonByAccomodation,
      }
    case GET_SEASON_BY_ACTIVITY_RESULTS:
      return {
        ...state,
        seasonByActivity: action.seasonByActivity,
      }
    case GET_SEASON_BY_ACTIVITY_ALL_RESULTS:
      return {
        ...state,
        seasonByActivityAll: action.seasonByActivityAll,
      }
    case GET_PROFIL_USER_LIST_RESULTS:
      return {
        ...state,
        profilUserList: action.profilUserList,
      }
    case PUT_DAY_CONTENT_RESULTS:
      return {
        ...state,
        putDayContent: action.putDayContent,
      }
    case DELETE_DAY_CONTENT_RESULTS:
      return {
        ...state,
        deleteDayContent: action.deleteDayContent,
      }
    case RESET_DAY_CONTENT:
      return {
        ...state,
        putDayContent: { success: false },
        deleteDayContent: null,
      }
    case RESET_LIST_CIRCUIT:
      return {
        ...state,
        listCircuit: [],
      }
    case PUT_ACCOMODATION_CONTENT_RESULTS:
      return {
        ...state,
        putAccomodationContent: action.putAccomodationContent,
      }
    case DELETE_ACCOMODATION_CONTENT_RESULTS:
      return {
        ...state,
        deleteAccomodationContent: action.deleteAccomodationContent,
      }
    case RESET_ACCOMODATION_CONTENT:
      return {
        ...state,
        putAccomodationContent: { success: false },
        deleteAccomodationContent: null,
      }
    case PUT_LOCATION_CONTENT_RESULTS:
      return {
        ...state,
        putLocationContent: action.putLocationContent,
      }
    case PUT_LOCATION_GRAND_CONTENT_RESULTS:
      return {
        ...state,
        putLocationGrandContent: action.putLocationGrandContent,
      }
    case DELETE_LOCATION_CONTENT_RESULTS:
      return {
        ...state,
        deleteLocationContent: action.deleteLocationContent,
      }
    case DELETE_LOCATION_GRAND_CONTENT_RESULTS:
      return {
        ...state,
        deleteLocationGrandContent: action.deleteLocationGrandContent,
      }
    case RESET_LOCATION_CONTENT:
      return {
        ...state,
        putLocationContent: { success: false },
        deleteLocationContent: null,
      }
    case RESET_LOCATION_GRAND_CONTENT:
      return {
        ...state,
        putLocationGrandContent: { success: false },
        deleteLocationGrandContent: null,
      }
    case PUT_PENSION_CONTENT_RESULTS:
      return {
        ...state,
        putPensionContent: action.putPensionContent,
      }
    case DELETE_PENSION_CONTENT_RESULTS:
      return {
        ...state,
        deletePensionContent: action.deletePensionContent,
      }
    case RESET_PENSION_CONTENT:
      return {
        ...state,
        putPensionContent: { success: false },
        deletePensionContent: null,
      }
    case PUT_ROOM_CONTENT_RESULTS:
      return {
        ...state,
        putRoomContent: action.putRoomContent,
      }
    case DELETE_ROOM_CONTENT_RESULTS:
      return {
        ...state,
        deleteRoomContent: action.deleteRoomContent,
      }
    case RESET_ROOM_CONTENT:
      return {
        ...state,
        putRoomContent: { success: false },
        deleteRoomContent: null,
      }
    case PUT_ACCOMODATION_TYPE_CONTENT_RESULTS:
      return {
        ...state,
        putAccomodationTypeContent: action.putAccomodationTypeContent,
      }
    case DELETE_ACCOMODATION_TYPE_CONTENT_RESULTS:
      return {
        ...state,
        deleteAccomodationTypeContent: action.deleteAccomodationTypeContent,
      }
    case RESET_ACCOMODATION_TYPE_CONTENT:
      return {
        ...state,
        putAccomodationTypeContent: { success: false },
        deleteAccomodationTypeContent: null,
      }
    case PUT_ROOM_BY_ACCOMODATION_RESULTS:
      return {
        ...state,
        putRoomByAccomodation: action.putRoomByAccomodation,
      }
    case PUT_SEASON_BY_ACCOMODATION_RESULTS:
      return {
        ...state,
        putSeasonByAccomodation: action.putSeasonByAccomodation,
      }
    case PUT_SEASON_BY_ACTIVITY_RESULTS:
      return {
        ...state,
        putSeasonByActivity: action.putSeasonByActivity,
      }
    case PUT_ROOM_PRICE_BY_ACCOMODATION_RESULTS:
      return {
        ...state,
        putRoomPriceByAccomodation: action.putRoomPriceByAccomodation,
      }
    case DELETE_ROOM_BY_ACCOMODATION_RESULTS:
      return {
        ...state,
        deleteRoomByAccomodation: action.deleteRoomByAccomodation,
      }
    case DELETE_SEASON_BY_ACCOMODATION_RESULTS:
      return {
        ...state,
        deleteSeasonByAccomodation: action.deleteSeasonByAccomodation,
      }
    case DELETE_SEASON_BY_ACTIVITY_RESULTS:
      return {
        ...state,
        deleteSeasonByActivity: action.deleteSeasonByActivity,
      }
    case RESET_ROOM_BY_ACCOMODATION:
      return {
        ...state,
        putRoomByAccomodation: { success: false },
        deleteRoomByAccomodation: null,
        roomByAccomodation: [],
      }

    case RESET_ROOM_PRICE_BY_ACCOMODATION:
      return {
        ...state,
        putRoomPriceByAccomodation: { success: false },
        roomByAccomodation: [],

      }
    case RESET_SEASON_BY_ACCOMODATION:
      return {
        ...state,
        putSeasonByAccomodation: { success: false },
        deleteSeasonByAccomodation: null,
        seasonByAccomodation: [],
      }
    case RESET_SEASON_BY_ACTIVITY:
      return {
        ...state,
        putSeasonByActivity: { success: false },
        deleteSeasonByActivity: null,
        seasonByActivity: [],
      }
    case RESET_TRIP_INFORMATION:
      return {
        ...state,
        tripDetails: [],
        tripContent: null,
        tripAllDays: null,
        tripSeasonByAccomodation: [],
        tripSeasonByAccomodationOpt1: [],
        tripSeasonByAccomodationOpt2: [],
        tripAllDaysAccomodations: null,
        tripAccomodationOpt1: null,
        tripAccomodationOpt2: null,
        tripAccomodation: null,
        tripPension: null,
        tripRoom: null,
        tripRoomOpt1: null,
        tripRoomOpt2: null,
        idTripSelected: null,
        isTripStepValidated: false,
        isTripDetailsStepValidated: false,
        isDaysListStepValidated: false,
        isTripExtensionStepValidated: false,
        isBillingStepValidated: false,
        isTourOperatorStepValidated: false,
        tourOperatorStepStatus: false,
      }
    case VALIDATE_STEP:
      return {
        ...state,
        [`is${action.page}StepValidated`]: action.value,
        [`${_.camelCase(action.page)}StepProgression`]: action.progression,
      }
    case LOADER:
      return {
        ...state,
        isLoading: action.isLoading,
      }
    default:
      return state
  }
}
