/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/accessible-emoji */

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import axios from 'axios'
import Interweave from 'interweave';
import { renderToStaticMarkup } from 'react-dom/server';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import 'moment/locale/it';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import stage from '../../../config'
import {
  ActionButton,
  TextEditor,
  Loader,
} from '../..'

// eslint-disable-next-line no-unused-vars
import translateBoard from '../../../generate/utils/translateBoard'
import duplicate from '../../../assets/images/duplicate.png'
import iconeimage from '../../../assets/images/icone-image.png'


const savedImgUrl = stage.imgStorage
const apiUrl = stage.api.duyen.url

class GenerateHtmlCiel extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    tripDetails: PropTypes.array,
    tripAllDays: PropTypes.array,
    tripAccomodation: PropTypes.array,
    tripAccomodationOpt1: PropTypes.array,
    tripAccomodationOpt2: PropTypes.array,
    tripDevisFinal: PropTypes.object,
    tripBilling: PropTypes.object,
    tripRoom: PropTypes.array,
    companyContent: PropTypes.object,
    tripContent: PropTypes.array,
    PUT_TRIP_DETAILS_URL: PropTypes.func.isRequired,
    PUT_TRIP_DEVIS_FINAL: PropTypes.func.isRequired,
    locationList: PropTypes.array,
    tripPension: PropTypes.array,
    informationsContent: PropTypes.object,
    userAccount: PropTypes.object,
  }

  static defaultProps = {
    tripDetails: [],
    tripRoom: [],
    tripDevisFinal: {},
    tripAllDays: [],
    tripBilling: {},
    tripAccomodation: [],
    tripAccomodationOpt1: [],
    tripAccomodationOpt2: [],
    companyContent: {},
    locationList: [],
    tripPension: [],
    tripContent: [],
    informationsContent: {},
    userAccount: {},
  }

  constructor(props) {
    super(props);
    this.dropFile = this.dropFile.bind(this)
    this.handlePrint = this.handlePrint.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.createCustomMarkerIcon = this.createCustomMarkerIcon.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.redirectToGallery = this.redirectToGallery.bind(this)
    this.state = {
      token: localStorage.getItem('token'),
      isLoading: false,
      urlDevis: '',
      dateUpdateUrl: '',
      devisContent: '',
      typeCarte: 0,
    };
  }

  componentDidMount() {
    const {
      tripDevisFinal,
      history,
    } = this.props
    if (tripDevisFinal && !_.isEmpty(tripDevisFinal)) {
      this.setState({
        devisContent: tripDevisFinal[0].textHtml,
      })
    }
    if (history.location.state) {
      const {
        currentPage,
      } = history.location.state
      if (currentPage === 'print') {
        const {
          state,
        } = history.location.state
        this.setState({
          devisContent: state.item,
        })
      }
    }
  }

  redirectToGallery = () => {
    const {
      devisContent,
    } = this.state
    const {
      history,
    } = this.props

    history.push('gallery', {
      currentPage: 'print',
      isSelectionAction: false,
      nbMaxImg: 0,
      currentImg: [],
      state: {
        item: devisContent,
      },
    })
  }


handlePrint = (html) => {
  const content = html; // Récupérez la valeur du contenu HTML de votre éditeur

  const newWindow = window.open('', '_blank');
  newWindow.document.write(content);
  newWindow.document.close();
}

handleSave = (devisContent) => {
  const {
    PUT_TRIP_DEVIS_FINAL,
    tripDetails,
    tripDevisFinal,
  } = this.props
  const {
    token,
  } = this.state
  const tripDevisFinalServeur = {}
  tripDevisFinalServeur.textHtml = devisContent
  tripDevisFinalServeur.id_devis = tripDetails[0].id_devis
  if (tripDevisFinal && !_.isEmpty(tripDevisFinal)) {
    PUT_TRIP_DEVIS_FINAL(token, tripDevisFinalServeur, 'update')
  } else {
    PUT_TRIP_DEVIS_FINAL(token, tripDevisFinalServeur, 'create')
  }
}

handleEditorChange = (content) => {
  this.setState({
    devisContent: content,
  });
}


  dropFile = (html) => {
    const {
      tripDetails,
      PUT_TRIP_DETAILS_URL,
    } = this.props

    const {
      token,
    } = this.state

    const blob = new Blob([html]);
    const file = new File([blob], `${tripDetails[0].date_depart}-${tripDetails[0].id_devis}${tripDetails[0].id_circuit}${tripDetails[0].id_conseiller}${tripDetails[0].version}.html`, { type: 'text/html' });
    // upload image files to API server
    // name format : name-date.extension

    // upload image file to API server
    const imageFormObj = new FormData()
    imageFormObj.append('imageName', file.name)
    imageFormObj.append('company', 'devis')
    imageFormObj.append('file', file);

    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'content-type': 'text/html',
      },
    }
    // send image to server
    axios.post(`${apiUrl}/api/uploadDevis`, imageFormObj, config)
      .then((res) => {
        const tripDetailsUrl = {}
        tripDetailsUrl.urlDevis = res.data.urlDevis
        tripDetailsUrl.id_devis = tripDetails[0].id_devis

        PUT_TRIP_DETAILS_URL(token, tripDetailsUrl)
        this.setState({
          urlDevis: res.data.urlDevis,
          dateUpdateUrl: moment().format('YYYY-MM-DD HH:mm'),
        })
      })
    return true
  }


  // Get array of images


  createCustomMarkerIcon = (index) => {
    const canvas = document.createElement('canvas');
    canvas.width = 40; // Largeur de l'icône
    canvas.height = 40; // Hauteur de l'icône
    const context = canvas.getContext('2d');

    // Dessin du cercle de fond
    context.beginPath();
    context.arc(20, 20, 18, 0, 2 * Math.PI); // Cercle centré
    context.fillStyle = 'red'; // Couleur de fond
    context.fill();
    context.closePath();

    // Ajout du texte (l'index)
    context.fillStyle = 'white'; // Couleur du texte
    context.font = 'bold 14px Arial'; // Police du texte
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(index, 20, 20); // Position du texte

    // Retourne une image comme icône
    return canvas.toDataURL();
  }


  render() {
    const {
      tripDetails,
      companyContent,
      locationList,
      informationsContent,
      userAccount,
      tripAllDays,
      tripAccomodation,
      tripAccomodationOpt1,
      tripAccomodationOpt2,
      tripPension,
      tripContent,
      tripRoom,
      tripBilling,
    } = this.props

    const {
      guide,
      chauffeur,
      nb_chambre,
      type_chambre,
      hebergement,
      hebergement_opt1,
      hebergement_opt2,

    } = tripContent[0]


    const {
      map_localisation,
      langage,
      cover_image,
      prenom_responsable_groupe,
      nom_responsable_groupe,
      desc_voyage,
      centre_interet,
    } = tripDetails[0]
    const {
      urlDevis,
      dateUpdateUrl,
      devisContent,
      isLoading,
      typeCarte,
    } = this.state

    const tableauHebergement = (roomAccoArray, roomAcco1Array, roomAcco2Array) => (roomAccoArray.map((item, i) => (
      {
        lieu_fin_journee: tripAllDays[i].lieu_fin_journee,
        nomHeb: tripAccomodation[i].nom_hebergement,
        nomHeb1: tripAccomodationOpt1[i].nom_hebergement,
        nomHeb2: tripAccomodationOpt2[i].nom_hebergement,
        adresse: tripAccomodation[i].adresse,
        adresse1: tripAccomodationOpt1[i].adresse,
        adresse2: tripAccomodationOpt2[i].adresse,
        image_hebergement: tripAccomodation[i].image,
        image_hebergement1: tripAccomodationOpt1[i].image,
        image_hebergement2: tripAccomodationOpt2[i].image,
        desc_hebergement: tripAccomodation[i].fr_desc_hebergement,
        desc_hebergement1: tripAccomodationOpt1[i].fr_desc_hebergement,
        desc_hebergement2: tripAccomodationOpt2[i].fr_desc_hebergement,
        heb: roomAccoArray[i],
        heb1: roomAcco1Array[i],
        heb2: roomAcco2Array[i],
        day_duration: tripAllDays[i].day_duration,
        cle: `${roomAccoArray[i]}${roomAcco1Array[i]}${roomAcco2Array[i]}${tripAllDays[i].lieu_fin_journee}`,
      }

    ))
    )


    const imageArray = cover_image.split(',')
    // Fonction pour extraire et transformer les données
    const prepareImagesData = () => {
      const result = [];

      if (!_.isEmpty(imageArray[0])) {
        result.push({
          image: imageArray[0].trim(),
          nom_journee: '',
        });
      }

      tripAllDays.forEach((journee, index) => {
        const images = journee.image.split(','); // Séparer les images
        images.forEach((img) => {
          if (!_.isEmpty(img)) {
            result.push({
              image: img.trim(),
              nom_journee: journee.nom_journee,
              day: index + 1,
            });
          }
        });
      });


      return result;
    };

    const findOcc = (arr, key) => {
      const arr2 = [];

      arr.forEach((x) => {
        // Checking if there is any object in arr2
        // which contains the key value
        if (arr2.some(val => val[key] === x[key])) {
          // If yes! then increase the occurrence by 1
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              // eslint-disable-next-line no-plusplus
              k.occurrence += x.day_duration
            }
          })
        } else {
          // If not! Then create a new object initialize
          // it with the present iteration key's value and
          // set the occurrence to 1
          const a = {}
          a[key] = x[key]
          a.occurrence = x.day_duration
          arr2.push(a);
        }
      })
      return arr2
    }


    const guideArray = _.split(guide, ',')

    const Fonddefaut = {
      backgroundColor: informationsContent.main_color,
    };

    const Colordefaut = {
      color: informationsContent.main_color,
    };

    // get langage
    // *****************************************************************
    const langageTranslation = translateBoard.filter(l => l.isoCode === langage)[0].translation

    const tripLocalisationList = !_.isEmpty(tripDetails) ? _.split(tripDetails[0].map_localisation, ',') : []
    const tabMap = tripLocalisationList.map(tLL => locationList.filter(c => c.lieu === tLL))
    let markerMap = ''
    tabMap.map((item, index) => map_localisation && item[0].latitude && (markerMap = `${markerMap}&markers=size:mid%7Ccolor:red%7Clabel:${index + 1}%7C${item[0].latitude},${item[0].longitude}`))
    const urlMap = `https://maps.googleapis.com/maps/api/staticmap?size=800x1000&maptype=roadmap${markerMap}&key=AIzaSyCUHs6GMO6QKYfSHFsslBpEHJH7Ue-xfdY`


    const firstname = prenom_responsable_groupe || null
    const lastname = nom_responsable_groupe || null
    const travelerName = firstname || lastname ? [firstname, lastname].join(' ') : null
    const TabIncJour = tripAllDays.map(item => (item.day_duration))
    const longueurVoyage = tripAllDays.length
    const IncDayTot = _.sum(TabIncJour.slice(0, longueurVoyage))
    const userDesc = langage && `${langage}_presentation`;
    const advisorName = !_.isEmpty(userAccount) ? `${userAccount.firstname} ${userAccount.lastname}` : null
    const advisorMail = !_.isEmpty(userAccount) ? userAccount.email : null
    const advisorPhone = !_.isEmpty(userAccount) ? userAccount.phone : null
    const dateDepart = moment(tripDetails[0].date_depart, 'YYYY-MM-DD').locale(langage).format('DD MMMM YYYY')
    const dateFin = moment(tripDetails[0].date_depart, 'YYYY-MM-DD').add(IncDayTot - 1, 'd').locale(langage).format('DD MMMM YYYY')
    const chauffeurArray = _.split(chauffeur, ',')
    const imagesData = prepareImagesData();
    const ButtonWithSvgPartager = () => (
      <button className="btn-partager" type="button">
        <img className="icon" src={`${savedImgUrl}/${companyContent.company}/share-doc-1735436110910.png`} alt="..." />
        <span className="textButton">Partager</span>
      </button>
    );


    const ButtonWithSvgTelecharger = () => (
      <button className="btn-telecharger" type="button">
        <img className="icon" src={`${savedImgUrl}/${companyContent.company}/download-doc-1735436237487.png`} alt="..." />
        <span className="textButton">Télécharger</span>
      </button>
    );
    const ButtonWithSvgGalerie = () => (
      <button className="btn-position" type="button">
        <img className="icon" src={`${savedImgUrl}/${companyContent.company}/image-doc-1735436200785.png`} alt="..." />
        <span className="textButton">Galerie</span>
      </button>
    );

    const styleTabItiWeb = {
      color: 'black',
      textAlign: 'left',
      paddingTop: '4px',
      paddingBottom: '4px',
      textDecoration: 'none !important',
    }

    const check = {
      fontSize: '12.0pt; mso-bidi-font-size: 10.5pt',
      fontFamily: 'MS Mincho',
      msoFareastFontFamily: 'DengXian',
      msoFareastThemeFont: 'minor-fareast',
      msoBidiFontFamily: 'MS Mincho',
      color: '#00b050',
      background: 'white',
      msoAnsiLanguage: 'FR',
      msoFaBidiLanguage: 'AR-SA',
    }

    const borderRadius = {
      borderRadius: 15,
    };
    const roomTypeArray = _.split(type_chambre, ',')
    const roomsNumberArray = nb_chambre ? _.split(nb_chambre, ',') : null
    const roomAccoArray = _.split(hebergement, ',')
    const roomAcco1Array = _.split(hebergement_opt1, ',')
    const roomAcco2Array = _.split(hebergement_opt2, ',')
    const hasDifferentValueAcco1 = roomAcco1Array.some(value => value !== '1');
    const hasDifferentValueAcco2 = roomAcco2Array.some(value => value !== '1');
    const tableauUnique = tableauHebergement(roomAccoArray, roomAcco1Array, roomAcco2Array)
    const uniqueHotels = {};
    const originalOrder = [];
    tableauUnique.forEach((hotel, index) => {
      // Vérifier si l'email de l'hôtel existe dans les hôtels uniques
      if (hotel.cle && !uniqueHotels[hotel.cle]) {
        // Ajouter l'hôtel unique à l'objet uniqueHotels avec le jour de la réservation
        uniqueHotels[hotel.cle] = {
          ...hotel,
          reservedDays: [index + 1], // Ajoutez le jour de la réservation
        };
        originalOrder.push(index);
      } else if (hotel.cle && uniqueHotels[hotel.cle]) {
        // Si l'email existe déjà, ajoutez le jour de la réservation à l'array reservedDays
        uniqueHotels[hotel.cle].reservedDays.push(index + 1);
      }
    });
    const Hotel_Unique = originalOrder.map(index => uniqueHotels[tableauUnique[index].cle]);


    const Nombre_Occurence_Hotel = findOcc(tableauUnique, 'cle')
    const texteEntreeSite = langage && `${langage}_information_5_description`;
    const remarque = `${langage}_remarque`
    const nbOfRoomLabel = (index) => {
      const roomsTypeForDay = roomTypeArray[index] && roomTypeArray[index] !== '1' ? roomTypeArray[index].split('-') : []
      const roomsNbForDay = roomsNumberArray && roomsNumberArray[index] !== 'null' ? roomsNumberArray[index].split('-') : []
      const roomsNameForDay = roomsTypeForDay.map(rT => (tripRoom[index] && !_.isEmpty(tripRoom[index]) ? tripRoom[index].find(r => r.id_heb_ch === parseInt(rT, 10)).type_chambre : 'none'))
      const label = _.isEmpty(roomsNbForDay) ? [] : roomsNameForDay.map((r, j) => `${r} (x${roomsNbForDay[j]})${roomsNameForDay.length === j + 1 ? '' : ', '}`)
      return label.join(''); // Utilisation de slice() pour retirer la dernière virgule
    }

    const texteHotel = langage && `${langage}_invoice`;
    const myPageFunc = () => {
      const myPage = (
        <div className="fullContainer">

          <div className="espaceCarroussel">
            <div id="carouselExampleControls" className="carousel slide">
              <div className="carousel-inner">
                {imagesData.map((item, indexImage) => (
                  <div className={indexImage === 0 ? 'carousel-item active' : 'carousel-item'}>
                    <div className="cardMain">
                      <img className="imajeCarouMain" src={`${savedImgUrl}/${companyContent.company}/${imagesData[indexImage].image}`} alt="..." />
                      {!_.isEmpty(imagesData[indexImage].nom_journee)
                      && <div className="legendeImage">Jour {imagesData[indexImage].day} - {imagesData[indexImage].nom_journee}</div>
                      }
                    </div>
                  </div>
                ))}
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true" />
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true" />
                <span className="visually-hidden">Next</span>
              </button>
            </div>
            <button id="toggleCarrousel" className="close-galerie" type="button">Fermer la galerie</button>
          </div>
          <div className="GlobalFileSense">
            <Grid container spacing={0} align="row" justifyContent="center">
              <Grid item xs={12}>
                <div
                  className="container-cover1"
                >
                  <img
                    className="ImageCoverSense"
                    src={`${savedImgUrl}/${companyContent.company}/${imageArray[0]}`}
                  />
                  <ButtonWithSvgGalerie />
                  <div className="buttonContainer">
                    <ButtonWithSvgTelecharger />
                    <ButtonWithSvgPartager />
                  </div>
                  <img className="logo" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
                  <div className="titreCover1">
                    <span>{tripDetails[0].nom_programme}</span>
                    <div className="ligneSeparation" />
                  </div>
                </div>
              </Grid>
            </Grid>
            <div className="GlobalFileDeux">
              <div className="navBarCiel">
                <a href="#home" className="animation-button" onClick="toggleMenu()">Presentation</a>
                <a href="#brief" className="animation-button" onClick="toggleMenu()">Itinéraire</a>
                {(typeCarte === 1 || typeCarte === 2 || typeCarte === 3)
                && <a href="#carte" className="animation-button" onClick="toggleMenu()">Carte</a>
                }
                <a href="#detail" className="animation-button" onClick="toggleMenu()">Programme détaillé</a>
                <a href="#prix" className="animation-button" onClick="toggleMenu()">Prix</a>
                <a href="#hotel" className="animation-button" onClick="toggleMenu()">Hébergement</a>
                <a href="#info" className="animation-button" onClick="toggleMenu()">Bon à savoir</a>
                <a href="#video" className="animation-button" onClick="toggleMenu()">Vidéo</a>
              </div>
              <div className="iconMenu" onClick="toggleMenu()">
                <div style={Fonddefaut} className="bar1" />
                <div style={Fonddefaut} className="bar2" />
                <div style={Fonddefaut} className="bar3" />
              </div>
              <Grid container spacing={0} align="row" justifyContent="center">
                <Grid item md={8} xs={12}>
                  <div id="home" className="titreSense2" style={Colordefaut}>A propos de ce voyage</div>
                  <div className="marginDay">
                    <Interweave content={desc_voyage} />
                  </div>
                </Grid>
                <Grid item md={4} xs={12}>
                  <table className="advisor-table">
                    <tr>
                      <td colSpan="2">
                        <div className="fontPresentation">
                          <div><span className="mettreGras">PARTICIPANTS:</span> {travelerName} </div>
                          <br />
                          <div><span className="mettreGras">Date:</span>  Du {dateDepart} au {dateFin} </div>
                          <br />
                          <div><span className="mettreGras">Durée: </span>{IncDayTot} jours {tripDetails[0].destination}</div>
                          <br />
                          <div> Programme créé par  {advisorName} de CielChine.com </div>
                          <br />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img className="imageConseiller" src={`${savedImgUrl}/${companyContent.company}/${userAccount.image}`} />
                      </td>
                      <td>
                        {advisorName && <div className="advisor-name">{advisorName}</div> }
                        {advisorMail && (
                        <div className="advisor-mail">
                          <img className="icon" src={`${savedImgUrl}/${companyContent.company}/email_adress-1736472049965.png`} alt="..." />
                          <span>{advisorMail}</span>
                        </div>
                        ) }
                        {advisorPhone
                        && (
                        <div className="advisor-phone">
                          <img className="icon" src={`${savedImgUrl}/${companyContent.company}/phone-doc-1735464535567.png`} alt="..." />
                          <span>{advisorPhone}</span>
                        </div>
                        ) }

                      </td>
                    </tr>

                    <tr>
                      <td colSpan="2" className="advisor-info">
                        <div className="advisor-desc">
                          <Interweave content={userAccount[userDesc]} />
                        </div>
                      </td>
                    </tr>
                  </table>
                </Grid>
              </Grid>


              <div className="CoupePasSense">

                <div id="brief" className="titreSense2" style={Colordefaut}>Itinéraire en bref</div>
                <div className="mettreRouge">
                  <table
                    className="tabItineraireSenseGlobal"
                  >
                    <tbody>
                      <tr>
                        <th width="20%" style={Fonddefaut}>Jour & date </th>
                        <th width="30%" style={Fonddefaut}>Programme</th>
                        <th width="18%" style={Fonddefaut}>Transport</th>
                        <th width="8%" style={Fonddefaut}>Repas</th>
                        <th width="4%" style={Fonddefaut}>Guide</th>
                        <th width="20%" style={Fonddefaut}>Hébergement</th>
                      </tr>
                      {tripAllDays.map((item, index) => {
                        const IncDay = _.sum(TabIncJour.slice(0, index))
                        const datejour = tripDetails[0].date_depart
                          ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').locale(langage).add(IncDay, 'd').format('ddd DD MMM'))
                          : ''
                        let guideNeed = false
                        switch (guideArray[index]) {
                          case '0':
                            guideNeed = false
                            break;
                          case '1':
                            guideNeed = true
                            break;
                          case '2':
                            guideNeed = true
                            break;
                          case '3':
                            guideNeed = true
                            break;
                          case '4':
                            guideNeed = true
                            break;
                          default:
                            guideNeed = false
                        }
                        return (
                          <tr key={`Bref${item.id_journee}`}>
                            <td width="20%">
                              <a style={styleTabItiWeb} href={`#Jour${index}`}>
                                J {TabIncJour[index] === 1 ? (IncDay + 1) : (`${IncDay + 1} to ${IncDay + TabIncJour[index]}`)} {datejour}
                              </a>
                            </td>
                            <td width="30%">
                              <a style={styleTabItiWeb} href={`#Jour${index}`}>
                                {item.nom_journee}
                              </a>
                            </td>
                            <td width="18%">
                              <a style={styleTabItiWeb} href={`#Jour${index}`}>
                                {item.transport}
                              </a>
                            </td>
                            <td width="8%">
                              <a style={styleTabItiWeb} href={`#Jour${index}`}>
                                {tripPension[index].pension !== 'none' && tripPension[index].pension}
                              </a>
                            </td>
                            <td width="4%">
                              <a style={styleTabItiWeb} href={`#Jour${index}`}>
                                {guideNeed ? <span style={check}>✓</span> : ''}
                              </a>
                            </td>
                            <td width="10%">
                              <a style={styleTabItiWeb} href={`#Jour${index}`}>
                                {tripAccomodation[index].nom_hebergement !== 'none' && tripAccomodation[index].nom_hebergement}
                              </a>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <br />
              <div className="CoupePasSense">

                {(tripDetails[0].image || tripDetails[0].map_localisation) && (

                <div className="CoupePasSense">
                  {typeCarte === 1 && tripDetails[0].image
                    && (
                      <div className="centreTabPrice" id="carte">
                        <div className="titreSense2" style={Colordefaut}>Carte du voyage</div>
                        <br />
                        <img className="carte" style={borderRadius} src={`${savedImgUrl}/${companyContent.company}/${tripDetails[0].image}`} />
                        <div className="sautePage" />
                      </div>
                    )}
                    {typeCarte === 2 && tripDetails[0].map_localisation && (
                    <div className="centreTabPrice" id="carte">
                      <div className="titreSense2" style={Colordefaut}>Carte du voyage</div>
                      <br />
                      <div className="buttonInter">
                        <div id="map" />
                        <label>
                          <input type="checkbox" id="filterLieux" checked />
                          Lieux
                        </label>
                        <label>
                          <input type="checkbox" id="filterHotels" checked />
                          Hôtels
                        </label>
                      </div>
                      <img className="carteGoogleFixe" style={borderRadius} src={urlMap} />
                      <div className="sautePage" />
                    </div>

                    )}
                  {typeCarte === 3 && tripDetails[0].map_localisation && (
                  <div className="centreTabPrice" id="carte">
                    <div className="titreSense2" style={Colordefaut}>Carte du voyage</div>
                    <br />
                    <img style={borderRadius} src={urlMap} />
                    <div className="sautePage" />
                  </div>

                  )}
                </div>
                )}
              </div>

              <div id="detail" className="titreSense2" style={Colordefaut}>Programme</div>
              <div>
                {tripAllDays.map((item_jour, index) => {
                  const desc = `desc_jour_${index + 1}`;
                  const IncDay = _.sum(TabIncJour.slice(0, index))
                  const imageArrayDay = item_jour.image.split(',')
                  const datejour = tripDetails[0].date_depart
                    ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').locale(langage).add(IncDay, 'd').format('ddd DD MMM'))
                    : ''
                  // eslint-disable-next-line no-nested-ternary
                  let guideNeed = ''
                  switch (guideArray[index]) {
                    case '0':
                      guideNeed = ''
                      break;
                    case '1':
                      guideNeed = langageTranslation.withGuide
                      break;
                    case '2':
                      guideNeed = langageTranslation.withGuideEn
                      break;
                    case '3':
                      guideNeed = langageTranslation.withGuideEnEvasion
                      break;
                    case '4':
                      guideNeed = langageTranslation.withLocal
                      break;
                    default:
                      guideNeed = ''
                  }
                  let driverNeed = ''
                  switch (chauffeurArray[index]) {
                    case '0':
                      driverNeed = ''
                      break;
                    case '1':
                      driverNeed = langageTranslation.driverEvasion
                      break;
                    case '2':
                      driverNeed = ''
                      break;
                    case '3':
                      driverNeed = 'Transport Locaux'
                      break;
                    case '4':
                      driverNeed = langageTranslation.busEvasion
                      break;
                    case '5':
                      driverNeed = langageTranslation.driverSpeakingEnglish
                      break;
                    case '6':
                      driverNeed = langageTranslation.driverSpeakingFrench
                      break;
                    default:
                      driverNeed = ''
                  }
                  const union = guideNeed && driverNeed ? ` ${langageTranslation.and} ` : ''
                  const asteriskText = `${guideNeed}${union}${driverNeed}` !== '' ? `${guideNeed}${union}${driverNeed}` : langageTranslation.noGuideNoDriver

                  const BandeauJour = () => (
                    <div className="titreJournee">
                      <div id={`Jour${index}`}>  <span style={Colordefaut}>J {TabIncJour[index] === 1 ? (IncDay + 1) : (`${IncDay + 1} à ${IncDay + TabIncJour[index]}`)} - {datejour}:</span> {item_jour.nom_journee}  </div>
                    </div>
                  );

                  const TableService = () => (
                    <div className="marginDay">
                      {tripPension[index].pension !== 'none' && (<div className="fontAcco">Repas: {tripPension[index].pension}</div>)}
                      {(tripAccomodation && tripAccomodation[index].nom_hebergement !== 'none')
                                                                    && (
                                                                    <div className="fontAccoColor">
                                                                      Hébergement: {tripAccomodation[index].nom_hebergement !== 'none' && (`${tripAccomodation[index].nom_hebergement}${!_.isEmpty(tripAccomodation[index].categorie) ? `(${tripAccomodation[index].categorie})` : ''}`)}
                                                                      {nbOfRoomLabel(index) && (!roomsNumberArray ? '' : `, ${nbOfRoomLabel(index)}`)}
                                                                    </div>
                                                                    )
                                        }
                      <div className="fontAcco">{asteriskText}</div>
                      {item_jour.distance && (<div className="fontAcco">{item_jour.distance}</div>)}
                    </div>
                  );
                  return (
                    <div className="journeeBox" key={`${datejour}`}>
                      <Grid container spacing={0} align="row" justifyContent="center">
                        {imageArrayDay[0] !== ''
                            && (
                            <Grid item md={5} xs={12}>
                              <div id={`carouselExampleControls${index}`} className="carousel slide" data-bs-ride="false" data-bs-interval="0">
                                <div className="carousel-inner">
                                  {imageArrayDay.map((item, indexImage) => (
                                    <div className={indexImage === 0 ? 'carousel-item active' : 'carousel-item'}>
                                      <div className="card">
                                        <img className="imajeCarou" src={`${savedImgUrl}/${companyContent.company}/${imageArrayDay[indexImage]}`} alt="..." />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target={`#carouselExampleControls${index}`} data-bs-slide="prev">
                                  <span className="carousel-control-prev-icon" aria-hidden="true" />
                                  <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target={`#carouselExampleControls${index}`} data-bs-slide="next">
                                  <span className="carousel-control-next-icon" aria-hidden="true" />
                                  <span className="visually-hidden">Next</span>
                                </button>
                              </div>
                            </Grid>
                            )}
                        <Grid item md={imageArrayDay[0] !== '' ? 7 : 12} xs={12}>
                          <BandeauJour />
                          <div className="marginDay">
                            <Interweave content={tripContent[0][desc]} />
                          </div>
                          <div className="paddingService">
                            <TableService />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  )
                })}
              </div>
              <div id="prix" className="titreSense2" style={Colordefaut}>Prix du voyage</div>
              <div className="journeeBox">
                <div className="CoupePasSense">
                  <div>
                    <Interweave content={tripBilling.tabPrice} />
                  </div>
                  <br />
                </div>
                <div>
                  <br />
                  <Grid container spacing={0} align="row" justifyContent="center">
                    <Grid item md={6} xs={12}>
                      <div className="CoupePasSense">
                        <div className="TitrePrixComprend">  {langageTranslation.priceContained}:  </div>
                        <br />
                        <Interweave content={tripDetails[0].prix_comprend} />
                      </div>
                    </Grid>
                    <Grid md={6} xs={12}>
                      <div className="CoupePasSense">
                        <div className="TitrePrixComprendPas">  {langageTranslation.priceDoNotContained}:  </div>
                        <br />
                        <Interweave content={tripDetails[0].prix_comprend_pas} />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <br />
              {!_.isEmpty(centre_interet)
              && (
              <div>
                <div className="titreSense2" style={Colordefaut}>Entrée des sites</div>
                <div className="journeeBox">
                  <Interweave content={informationsContent[texteEntreeSite]} />
                  <Interweave content={centre_interet} />
                </div>
              </div>
              )
             }
              <div id="hotel" className="titreSense2" style={Colordefaut}>Hôtels</div>
              <div className="journeeBox">
                <Interweave content={informationsContent[texteHotel]} />
              </div>
              <div>
                {parseInt(roomAcco1Array[0], 10) !== 1 && (
                <div className="titreSense2" style={Colordefaut}>OPTION HEBERGEMENT 1</div>
                )}
                {
                                     Hotel_Unique && Hotel_Unique.map((item, index) => {
                                       const imageArrayHotel = item.image_hebergement ? item.image_hebergement.split(',') : ''
                                       const LieuFinJournee = _.find(locationList, o => o.id_lieu === item.lieu_fin_journee)
                                       const adresseHebClean = item.adresse.replace(/<p>/g, '').replace(/<\/p>/g, '');
                                       const BandeauHeb = () => (
                                         <div className="titreJournee">
                                           <span>{LieuFinJournee.lieu}: </span> <span style={Colordefaut}>{item.nomHeb}</span> -   {Nombre_Occurence_Hotel[index].occurrence} nuit(s)
                                         </div>
                                       );

                                       return (
                                         item.nomHeb !== 'none'
                                          && (
                                          <div className="hebergementBox" key={`Acco${item.id_journee}`}>
                                            <Grid container spacing={0} align="row" justifyContent="center">
                                              {!_.isEmpty(imageArrayHotel) && imageArrayHotel[0] !== ''
                            && (
                            <Grid item md={5} xs={12}>
                              <div id={`carouselControlsHotel${index}`} className="carousel slide" data-bs-ride="false" data-bs-interval="0">
                                <div className="carousel-inner">
                                  {imageArrayHotel.map((itemImage, indexImage) => (
                                    <div className={indexImage === 0 ? 'carousel-item active' : 'carousel-item'}>
                                      <div className="card">
                                        <img className="imajeCarou" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[indexImage]}`} alt="..." />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target={`#carouselControlsHotel${index}`} data-bs-slide="prev">
                                  <span className="carousel-control-prev-icon" aria-hidden="true" />
                                  <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target={`#carouselControlsHotel${index}`} data-bs-slide="next">
                                  <span className="carousel-control-next-icon" aria-hidden="true" />
                                  <span className="visually-hidden">Next</span>
                                </button>
                              </div>
                            </Grid>
                            )}
                                              <Grid item md={imageArrayHotel[0] !== '' ? 7 : 12} xs={12}>
                                                <BandeauHeb />
                                                <div className="marginDay">
                                                  <Interweave content={item.desc_hebergement} />
                                                  {item.adresse && !_.isEmpty(item.adresse) && <Interweave content={adresseHebClean} />}

                                                </div>
                                              </Grid>
                                            </Grid>
                                          </div>
                                          )
                                       )
                                     })
                                    }
                {hasDifferentValueAcco1 && (
                <div className="titreSense2" style={Colordefaut}>OPTION HEBERGEMENT 2</div>
                )}
                {hasDifferentValueAcco1 && (
                  Hotel_Unique && Hotel_Unique.map((item, index) => {
                    const imageArrayHotel = item.image_hebergement1 ? item.image_hebergement1.split(',') : ''
                    const LieuFinJournee = _.find(locationList, o => o.id_lieu === item.lieu_fin_journee)
                    const adresseHebClean = item.adresse1.replace(/<p>/g, '').replace(/<\/p>/g, '');
                    const BandeauHeb = () => (
                      <div className="titreJournee">
                        <span>{LieuFinJournee.lieu}: </span> <span style={Colordefaut}>{item.nomHeb1}</span> -   {Nombre_Occurence_Hotel[index].occurrence} nuit(s)
                      </div>
                    );

                    return (
                      item.nomHeb1 !== 'none'
                      && (
                      <div className="hebergementBox" key={`Acco2${item.id_journee}`}>
                        <Grid container spacing={0} align="row" justifyContent="center">
                          {!_.isEmpty(imageArrayHotel) && imageArrayHotel[0] !== ''
        && (
        <Grid item md={5} xs={12}>
          <div id={`carouselControlsHotel1${index}`} className="carousel slide" data-bs-ride="false" data-bs-interval="0">
            <div className="carousel-inner">
              {imageArrayHotel.map((itemImage, indexImage) => (
                <div className={indexImage === 0 ? 'carousel-item active' : 'carousel-item'}>
                  <div className="card">
                    <img className="imajeCarou" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[indexImage]}`} alt="..." />
                  </div>
                </div>
              ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target={`#carouselControlsHotel1${index}`} data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target={`#carouselControlsHotel1${index}`} data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </Grid>
        )}
                          <Grid item md={imageArrayHotel[0] !== '' ? 7 : 12} xs={12}>
                            <BandeauHeb />
                            <div className="marginDay">
                              <Interweave content={item.desc_hebergement1} />
                              {item.adresse1 && !_.isEmpty(item.adresse1) && <Interweave content={adresseHebClean} />}

                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      )
                    )
                  })
                )}
                {hasDifferentValueAcco2 && (
                <div className="titreSense2" style={Colordefaut}>OPTION HEBERGEMENT 3</div>
                )}
                {hasDifferentValueAcco2 && (
                  Hotel_Unique && Hotel_Unique.map((item, index) => {
                    const imageArrayHotel = item.image_hebergement2 ? item.image_hebergement2.split(',') : ''
                    const LieuFinJournee = _.find(locationList, o => o.id_lieu === item.lieu_fin_journee)
                    const adresseHebClean = item.adresse2.replace(/<p>/g, '').replace(/<\/p>/g, '');
                    const BandeauHeb = () => (
                      <div className="titreJournee">
                        <span>{LieuFinJournee.lieu}: </span> <span style={Colordefaut}>{item.nomHeb2}</span> -   {Nombre_Occurence_Hotel[index].occurrence} nuit(s)
                      </div>
                    );
                    return (
                      item.nomHeb !== 'none'
                      && (
                      <div className="hebergementBox" key={`Acco2${item.id_journee}`}>
                        <Grid container spacing={0} align="row" justifyContent="center">
                          {!_.isEmpty(imageArrayHotel) && imageArrayHotel[0] !== ''
        && (
        <Grid item md={5} xs={12}>
          <div id={`carouselControlsHotel2${index}`} className="carousel slide" data-bs-ride="false" data-bs-interval="0">
            <div className="carousel-inner">
              {imageArrayHotel.map((itemImage, indexImage) => (
                <div className={indexImage === 0 ? 'carousel-item active' : 'carousel-item'}>
                  <div className="card">
                    <img className="imajeCarou" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[indexImage]}`} alt="..." />
                  </div>
                </div>
              ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target={`#carouselControlsHotel2${index}`} data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target={`#carouselControlsHotel2${index}`} data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </Grid>
        )}
                          <Grid item md={imageArrayHotel[0] !== '' ? 7 : 12} xs={12}>
                            <BandeauHeb />
                            <div className="marginDay">
                              <Interweave content={item.desc_hebergement2} />
                              {item.adresse1 && !_.isEmpty(item.adresse2) && <Interweave content={adresseHebClean} />}

                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      )
                    )
                  })
                ) }

              </div>
              <div>
                <div id="info" className="titreSense2" style={Colordefaut}>Informations complementaires</div>
                <div className="journeeBox">
                  <Interweave content={_.isEmpty(tripDetails[0].comment) ? informationsContent[remarque] : tripDetails[0].comment} />
                </div>
              </div>
              <div id="video" className="videoBox">
                <div className="titreSense2" style={Colordefaut}>VIDEO</div>
                <iframe className="VideoSense" width="624" height="468" src="https://www.youtube.com/embed/RfJ_3vYVrpY" title="Ciel Chine" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
              </div>
            </div>
          </div>
        </div>
      )
      return myPage
    }
    const pageHtml = renderToStaticMarkup(myPageFunc('HTMLWEB'));
    const urlBel = _.isEmpty(urlDevis)
      ? tripDetails[0].urlDevis.replace(/https:\/\/duyendoc\.s3(\.ap-south-1)?\.amazonaws\.com/, 'https://resa.cielchine.com')
      : urlDevis.replace(/https:\/\/duyendoc\.s3(\.ap-south-1)?\.amazonaws\.com/, 'https://resa.cielchine.com');


    const html = `
   <!DOCTYPE html>
   <html>
     <head>
     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
     <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>Votre proposition de voyage</title>
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet"> 

      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>
  
     <link rel="stylesheet" href="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlCielPro2.css">
    
     </head>
     <body>
      <script>

          document.addEventListener('DOMContentLoaded', function() {
             document.querySelector('.btn-position').addEventListener('click', function() {
                     var espaceCarroussel = document.querySelector('.espaceCarroussel');
                     var pageheadersensehtml = document.querySelector('.pageheadersensehtml');
                     var GlobalFileSense= document.querySelector('.GlobalFileSense');
                     espaceCarroussel.classList.toggle("responsive");
                     GlobalFileSense.classList.toggle("responsive");
                     pageheadersensehtml.classList.toggle("responsive");
                  }) 
                  });

                  document.addEventListener('DOMContentLoaded', function() {
                    document.querySelector('.close-galerie').addEventListener('click', function() {
                     var espaceCarroussel = document.querySelector('.espaceCarroussel');
                     var pageheadersensehtml = document.querySelector('.pageheadersensehtml');
                     var GlobalFileSense= document.querySelector('.GlobalFileSense');
                     espaceCarroussel.classList.toggle("responsive");
                     GlobalFileSense.classList.toggle("responsive");
                     pageheadersensehtml.classList.toggle("responsive");
                   }) 
                  });

               

            

                      document.addEventListener('DOMContentLoaded', function() {
                  document.querySelector('.iconMenu').addEventListener('click', function() {
              var navBarCiel = document.querySelector(".navBarCiel");
                  var iconMenu = document.querySelector(".iconMenu");
                   navBarCiel.classList.toggle("responsive");
                    iconMenu.classList.toggle("responsive");
                  }) 
              });

                        document.addEventListener('DOMContentLoaded', function() {
                document.querySelector('.navBarCiel').addEventListener('click', function() {
                 var navBarCiel = document.querySelector(".navBarCiel");
                 var iconMenu = document.querySelector(".iconMenu");
                 navBarCiel.classList.toggle("responsive");
                iconMenu.classList.toggle("responsive");
                  }) 
                  });
                    
                  document.addEventListener('DOMContentLoaded', function() {
                   document.querySelector('.btn-telecharger').addEventListener('click', function() {
                   window.print() }) 
                  });
                  document.addEventListener('DOMContentLoaded', function() {
                  document.querySelector('.btn-partager').addEventListener('click', function() {
                  if (navigator.share) { // Vérifie si l'API de partage est disponible
                  navigator.share({
                  title: '${tripDetails[0].nom_programme}',
                  text: 'Devis personnel pour ${travelerName}',
                  url: '${urlBel}'
                  }).then(() => {
                  console.log('Contenu partagé avec succès');
                  }).catch((error) => {
                  console.error('Erreur lors du partage', error);
                  });
                  } else {
                  alert('Le partage n’est pas pris en charge sur ce navigateur.');
                  }
                  });
                 });
     
     

  
    </script>
    
      
        ${devisContent}
  
      </body>
   </html>
   `;

    return (
      <>
        {isLoading ? <Loader /> : null}
        <div className="urlBox">
          <Grid container spacing={0} justifyContent="flex-start">
            <br />
            <Grid item xs={2}>
              <ActionButton
                label="Generate PDF"
                onClick={() => this.handlePrint(html)}
              />
            </Grid>
            <Grid item xs={2}>
              <ActionButton
                label="Generate URL"
                onClick={() => this.dropFile(html)}
              />
            </Grid>
            <Grid item xs={5}>
              <span className="titrounet">Link: </span><span className="urlDevisHorizon">{urlBel} </span>
              <CopyToClipboard text={urlBel}><img className="copyUrl" src={duplicate} /></CopyToClipboard>
              <br />
              <span className="titrounet">Last link update: </span>
              <span>{dateUpdateUrl === '' ? moment(tripDetails[0].date_modif_url).format('YYYY-MM-DD HH:mm') : dateUpdateUrl}</span>
              <br />
            </Grid>
            <Grid item xs={1}>
              <img className="copyUrl" title="Go to gallery" src={iconeimage} onClick={this.redirectToGallery} />
            </Grid>
            <Grid item md={12} xs={12}>

              <div>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    style={{ flexDirection: 'row' }}
                    value={typeCarte}
                    onChange={event => this.setState({
                      typeCarte: Number(event.target.value),
                      devisContent: '',
                    })}
                  >
                    <FormControlLabel value={0} control={<Radio />} label="No map" />
                    <FormControlLabel value={1} control={<Radio />} label="Image" />
                  </RadioGroup>
                </FormControl>
              </div>

            </Grid>
            <Grid item xs={12}>
              <div className="editorStyle">
                <p className="editorTitle background-details">Description</p>
                <TextEditor
                  linkCss={[
                    'https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlCielPro2.css',
                  ]}
                  height={1000}
                  value={_.isEmpty(devisContent) ? pageHtml : devisContent}
                  handleEditorChange={content => this.handleEditorChange(content)}
                />
              </div>
            </Grid>
          </Grid>
        </div>

        <ActionButton
          label="SAVE"
          onClick={() => this.handleSave(devisContent)}
        />
      </>

    );
  }
}

const mapStateToProps = state => ({
  tripDetails: state.api.tripDetails,
  accomodationList: state.api.accomodationList,
  locationList: state.api.locationList,
  companyContent: state.companyReducer.companyContent,
  tripContent: state.api.tripContent,
  informationsContent: state.informationsReducer.informationsContent,
  tripAccomodation: state.api.tripAccomodation,
  tripRoom: state.api.tripRoom,
  tripAccomodationOpt1: state.api.tripAccomodationOpt1,
  tripAccomodationOpt2: state.api.tripAccomodationOpt2,
  tripBilling: state.billingReducer.tripBilling,
  tripPrice: state.billingReducer.tripPrice,
  tripDevisFinal: state.api.tripDevisFinal,
  tripPension: state.api.tripPension,
  tripAllDays: state.api.tripAllDays,
  daysList: state.api.daysList,
  collaborators: state.collaboratorsReducer.collaborators,
  userAccount: state.userAccountReducer.userAccount,
})

const mapDispatchToProps = dispatch => ({
  PUT_TRIP_DETAILS_URL: (token, tripDetails) => dispatch({
    type: 'PUT_TRIP_DETAILS_URL',
    token,
    tripDetails,
  }),
  PUT_TRIP_DEVIS_FINAL: (token, tripDevisFinal, action) => dispatch({
    type: 'PUT_TRIP_DEVIS_FINAL',
    token,
    tripDevisFinal,
    action,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GenerateHtmlCiel))
