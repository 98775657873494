import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Modal from '@material-ui/core/Modal';
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckIcon from '@material-ui/icons/Check';
import InputAdornment from '@material-ui/core/InputAdornment';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import _ from 'lodash'

import {
  Input,
  ActionButton,
  Loader,
} from '..'
import { CssGrid } from '../../configCSS'

import stage from '../../config'


const { imgStorage } = stage

class ModalGalerie extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onResponseNo: PropTypes.func.isRequired,
    onResponseYes: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    GET_PICTURE: PropTypes.func.isRequired,
    pictureList: PropTypes.array,
    companyContent: PropTypes.object,

  }

  static defaultProps = {
    pictureList: [],
    companyContent: {},
  }


  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.onResponseNo = this.onResponseNo.bind(this)
    this.addImgInCheckedList = this.addImgInCheckedList.bind(this)
    this.removeImgInCheckedList = this.removeImgInCheckedList.bind(this)
    this.onResponseYes = this.onResponseYes.bind(this)
    this.displaySearchBar = this.displaySearchBar.bind(this)
    this.displayPictures = this.displayPictures.bind(this)
    this.handleCheckBox = this.handleCheckBox.bind(this)
    this.displayPicturesSelected = this.displayPicturesSelected.bind(this)
    this.updateList = this.updateList.bind(this)
    this.seachPicture = this.seachPicture.bind(this)
    this.state = {
      userId: localStorage.getItem('userId'),
      isLoading: false,
      pictureListFiltered: [],
      pictureCheckedList: [],
      pictureSearched: '',
    };
  }

  componentDidMount() {
    const {
      pictureList,
      companyContent,
      GET_PICTURE,
    } = this.props
    const {
      userId,
    } = this.state
    const { company } = companyContent
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }

    // check if props companyContent exists

    // check if props exists
    if (!_.isEmpty(pictureList)) {
      this.setState({
        pictureListFiltered: _.cloneDeep(pictureList),
      })
    } else if (!_.isEmpty(companyContent)) {
      this.setState({
        isLoading: true,
      })
      GET_PICTURE(userId, company)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      pictureList,
    } = this.props


    if (!_.isEqual(pictureList, prevProps.pictureList)) {
      this.updateList(pictureList)
    }
  }

    stopLoader = () => {
      this.setState({
        isLoading: false,
      })
    }

    startLoader = () => {
      this.setState({
        isLoading: true,
      })
    }

    updateList = (pictureList) => {
      this.stopLoader()

      this.setState({
        pictureListFiltered: _.cloneDeep(pictureList),
      })
    }


  // ********************
  // Modal
  handleClose = () => {
    const { onClose } = this.props
    onClose()
  }

  // ******************************
  // Action on input
  handleChange = field => (event) => {
    const { activityContent } = this.state
    this.setState({
      activityContent: {
        ...activityContent,
        [field]: event.target.value,
      },
    })
  }

  handleCheckBox = p => (e) => {
    const {
      pictureListFiltered,
    } = this.state
    const { checked } = e.target
    const pictureDeletedIndex = pictureListFiltered.findIndex(pic => pic.Key === p.Key)
    const pictureListUpdated = [...pictureListFiltered]
    p.checked = !p.checked
    pictureListUpdated.splice(pictureDeletedIndex, 1, p)

    // update checked list
    if (checked) {
      this.addImgInCheckedList(p.Key)
    } else {
      this.removeImgInCheckedList(p.Key, false)
    }

    // update list filtered
    this.setState({
      pictureListFiltered: pictureListUpdated,
    })
  }

  addImgInCheckedList = (Key) => {
    const {
      pictureCheckedList,
    } = this.state
    this.setState({
      pictureCheckedList: [...pictureCheckedList, Key],
    })
  }

  removeImgInCheckedList = (Key, uncheckedInList = false) => {
    const {
      pictureCheckedList,
      pictureListFiltered,
    } = this.state
    const pictureCheckedUpdated = [...pictureCheckedList]
    pictureCheckedUpdated.splice(pictureCheckedList.indexOf(Key), 1)
    this.setState({
      pictureCheckedList: pictureCheckedUpdated,
    })

    // unchecked picture un filtered list
    if (uncheckedInList) {
      const pictureListUpdated = [...pictureListFiltered]
      pictureListUpdated.map((pic) => {
        if (pic.Key === Key) {
          pic.checked = false
          return pic
        }
        return pic
      })
      this.setState({
        pictureListFiltered: pictureListUpdated,
      })
    }
  }

  seachPicture = (event) => {
    const { pictureList } = this.props
    this.setState({
      pictureSearched: event.target.value,
      pictureListFiltered: pictureList.filter(p => p.Key.toLowerCase().includes(event.target.value.toLowerCase())),
    })
  }

  displaySearchBar = () => {
    const { pictureSearched } = this.state
    return (
      <CssGrid
        container
        spacing={2}
        className="searchbar-div"
      >
        <CssGrid item sm={11} xs={10} className="search-picture-div">
          <Input
            key="search-picture-input"
            label="Search"
            value={pictureSearched}
            onChange={this.seachPicture}
            style={{ width: '100%', marginBottom: '30px' }}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment className="select-unit" position="end">
                  <SearchOutlinedIcon style={{ marginRight: '20px' }} />
                </InputAdornment>
              ),
            }}
          />
        </CssGrid>
      </CssGrid>
    )
  }

  displayPicturesSelected = () => {
    const {
      pictureCheckedList,
    } = this.state
    return (
      <div className="cart-checked-div">
        <h3 className="picture-checked-title">{'Pictures selected (max:1})'}</h3>
        <CssGrid
          container
          spacing={2}
          style={{ justifyContent: 'center' }}
        >
          {pictureCheckedList.map(p => (
            <CssGrid key={`${p.ETag}${p}`} item lg={2} md={3} sm={4} xs={6} className="cart-div">
              <img src={`${imgStorage}/${p}`} style={{ width: '100%' }} alt={p} />
              <CssGrid
                container
                spacing={1}
                className="cart-info-div"
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <CssGrid item xs={10}>
                  <p>{p.split('/')[1].slice(0, -18)}</p>
                </CssGrid>
                <CssGrid item xs={2} style={{ cursor: 'pointer' }} onClick={() => this.removeImgInCheckedList(p, true)}>
                  <HighlightOffIcon color="error" />
                </CssGrid>
              </CssGrid>
            </CssGrid>
          ))}
        </CssGrid>

        <div className="modalActions">
          <ActionButton
            label="Validate"
            type="submit"
            disabled={_.isEmpty(pictureCheckedList)}
            onClick={() => this.onResponseYes()}
          />
          <ActionButton
            label="back"
            onClick={() => this.onResponseNo()}
            color="reverse"
          />
        </div>
      </div>
    )
  }

  displayPictures = () => {
    const {
      pictureListFiltered,
      pictureCheckedList,
    } = this.state
    return (
      <div style={{
        maxHeight: '500px', overflowY: 'auto', border: '1px solid #ccc', padding: '10px',
      }}
      >

        <CssGrid
          container
          spacing={2}
          className="cart-general-div"
          style={{ display: 'flex', justifyContent: 'space-around' }}
        >
          {pictureListFiltered.map((p) => {
            if (_.endsWith(p.Key, '/')) return null
            return (
              <CssGrid key={`${p.ETag}${p.Key}`} item lg={2} md={3} sm={4} xs={6} className="cart-div">
                <img src={`${imgStorage}/${p.Key}`} style={{ width: '100%' }} alt={p.Key} />
                <CssGrid
                  container
                  spacing={1}
                  className="cart-info-div"
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <CssGrid item xs={10}>
                    <p>{p.Key.split('/')[1].slice(0, -18)}</p>
                  </CssGrid>
                </CssGrid>
                {pictureCheckedList.length < 1 && (
                <Checkbox
                  checked={p.checked || false}
                  className="cart-chekbox"
                  onChange={this.handleCheckBox(p)}
                  icon={<CheckBoxOutlineBlankIcon style={{ backgroundColor: 'white', borderColor: 'blue' }} />}
                  checkedIcon={<CheckIcon style={{ backgroundColor: 'white', color: 'blue' }} />}
                  inputProps={{ 'aria-label': 'checkbox' }}
                />
                )}
              </CssGrid>
            )
          })}
        </CssGrid>
      </div>
    )
  }


  // **********************
  // Modal response
  onResponseNo = () => {
    const { onResponseNo } = this.props
    onResponseNo()
  }

  onResponseYes = () => {
    const { pictureCheckedList } = this.state
    const { onResponseYes } = this.props
    onResponseYes(pictureCheckedList)
  }

  // **************************
  // Render
  render() {
    const {
      isModalOpen,
    } = this.props
    const {
      isLoading,
    } = this.state

    return (

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isModalOpen}
        onClose={this.handleClose}
      >
        <div className="modal">
          <div className="management-gallery">
            {isLoading ? <Loader /> : <div className="loader-null" />}
          </div>
          {this.displaySearchBar()}
          {this.displayPicturesSelected()}
          {this.displayPictures()}


        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  companyContent: state.companyReducer.companyContent,
  pictureList: state.pictureReducer.pictureList,
  deletePicture: state.pictureReducer.deletePicture,
})

const mapDispatchToProps = dispatch => ({
  GET_PICTURE: (userId, company) => dispatch({
    type: 'GET_PICTURE',
    userId,
    company,
  }),
  DELETE_PICTURE: (token, Key) => dispatch({
    type: 'DELETE_PICTURE',
    token,
    Key,
  }),
  RESET_PICTURE: () => dispatch({
    type: 'RESET_PICTURE',
  }),
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalGalerie))
