import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import api from './api'
import {
  postUserSignInResults,
  getUnvalidTokenListResults,
  getListCircuitResults,
  deleteTripResults,
  getTripDetailsResults,
  putTripDetailsResults,
  putTripDevisFinalResults,
  putTripDetailsUrlResults,
  getTripContentResults,
  putTripContentResults,
  getTripAllDaysResults,
  getTripAllDaysAccomodationResults,
  getTripAccomodationResults,
  getTripSeasonByAccomodationResults,
  getTripSeasonByAccomodationOpt1Results,
  getTripSeasonByAccomodationOpt2Results,
  getTripAccomodationOpt1Results,
  getTripAccomodationOpt2Results,
  getTripPensionResults,
  getTripDevisFinalResults,
  getTripRoomResults,
  getTripRoomOpt1Results,
  getTripRoomOpt2Results,
  getStatusListResults,
  getDaysListResults,
  getLocationListResults,
  getLocationGrandListResults,
  getPensionListResults,
  getAccomodationListResults,
  getRoomListResults,
  getAccomodationTypeListResults,
  getRoomByAccomodationListResults,
  getRoomByAccomodationResults,
  getSeasonByAccomodationResults,
  getSeasonByActivityResults,
  getSeasonByActivityAllResults,
  getProfilUserListResults,
  putDayContentResults,
  deleteDayContentResults,
  putAccomodationContentResults,
  deleteAccomodationContentResults,
  putLocationContentResults,
  putLocationGrandContentResults,
  deleteLocationContentResults,
  deleteLocationGrandContentResults,
  putPensionContentResults,
  deletePensionContentResults,
  putRoomContentResults,
  deleteRoomContentResults,
  putAccomodationTypeContentResults,
  deleteAccomodationTypeContentResults,
  putRoomByAccomodationResults,
  putSeasonByAccomodationResults,
  putSeasonByActivityResults,
  putRoomPriceByAccomodationResults,
  deleteRoomByAccomodationResults,
  deleteSeasonByAccomodationResults,
  deleteSeasonByActivityResults,
} from './actions'

// USER
function* postUserSignInAsync(params) {
  const {
    email,
    password,
  } = params
  const data = yield call(api.post_user_signin, email, password)
  yield put(postUserSignInResults(data))
}

function* getUnvalidTokenListAsync() {
  const data = yield call(api.get_unvalid_token_list)
  yield put(getUnvalidTokenListResults(data))
}

// TRIP PAGE
function* getListCircuitAsync(params) {
  const {
    token,
    dateTampon,
    dateTamponFin,
    filterAdvisor,
  } = params
  const data = yield call(api.get_list_circuit, token, dateTampon, dateTamponFin, filterAdvisor)
  yield put(getListCircuitResults(data))
}

function* deleteTripAsync(params) {
  const {
    token,
    idTripSelected,
  } = params
  const data = yield call(api.delete_trip, token, idTripSelected)
  yield put(deleteTripResults(data))
}

// TRIP DETAILS PAGE
function* getTripDetailsAsync(params) {
  const {
    token,
    idTripSelected,
  } = params
  const data = yield call(api.get_trip_details, token, idTripSelected)
  yield put(getTripDetailsResults(data))
}


function* getTripDevisFinalAsync(params) {
  const {
    token,
    idDevis,
  } = params
  const data = yield call(api.get_trip_devis_final, token, idDevis)
  yield put(getTripDevisFinalResults(data))
}

function* putTripDetailsAsync(params) {
  const {
    token,
    tripDetails,
    action,
  } = params
  const data = yield call(api.put_trip_details, token, tripDetails, action)
  yield put(putTripDetailsResults(data))
}

function* putTripDevisFinalAsync(params) {
  const {
    token,
    tripDevisFinal,
    action,
  } = params
  const data = yield call(api.put_trip_devis_final, token, tripDevisFinal, action)
  yield put(putTripDevisFinalResults(data))
}

function* putTripDetailsUrlAsync(params) {
  const {
    token,
    tripDetails,
  } = params
  const data = yield call(api.put_trip_details_url, token, tripDetails)
  yield put(putTripDetailsUrlResults(data))
}

// TRIP CONTENT
function* getTripContentAsync(params) {
  const { idTourSelected } = params
  const data = yield call(api.get_trip_content, idTourSelected)
  yield put(getTripContentResults(data))
}

function* putTripContentAsync(params) {
  const {
    token,
    newTripContent,
    dayContentPersonalized,
    action,
  } = params
  const data = yield call(api.put_trip_content, token, newTripContent, dayContentPersonalized, action)
  yield put(putTripContentResults(data))
}

function* getTripAllDaysAsync(params) {
  const {
    tripDaysList,
    idCircuit,
  } = params
  const data = yield call(api.get_trip_alldays, tripDaysList, idCircuit)
  yield put(getTripAllDaysResults(data))
}
function* getTripAllDaysAccomodationAsync(params) {
  const {
    tripDaysList,
    tripAccoList,
  } = params
  const data = yield call(api.get_trip_alldaysaccomodation, tripDaysList, tripAccoList)
  yield put(getTripAllDaysAccomodationResults(data))
}

function* getTripAccomodationAsync(params) {
  const { tripAccomodationList } = params
  const data = yield call(api.get_trip_accomodation, tripAccomodationList)
  yield put(getTripAccomodationResults(data))
}
function* getTripAccomodationOpt1Async(params) {
  const { tripAccomodationList } = params
  const data = yield call(api.get_trip_accomodation_opt1, tripAccomodationList)
  yield put(getTripAccomodationOpt1Results(data))
}

function* getTripAccomodationOpt2Async(params) {
  const { tripAccomodationList } = params
  const data = yield call(api.get_trip_accomodation_opt2, tripAccomodationList)
  yield put(getTripAccomodationOpt2Results(data))
}

function* getTripSeasonByAccomodationAsync(params) {
  const { tripAccomodationList } = params
  const data = yield call(api.get_trip_season_by_accomodation, tripAccomodationList)
  yield put(getTripSeasonByAccomodationResults(data))
}

function* getTripSeasonByAccomodationOpt1Async(params) {
  const { tripAccomodationList } = params
  const data = yield call(api.get_trip_season_by_accomodation_opt1, tripAccomodationList)
  yield put(getTripSeasonByAccomodationOpt1Results(data))
}

function* getTripSeasonByAccomodationOpt2Async(params) {
  const { tripAccomodationList } = params
  const data = yield call(api.get_trip_season_by_accomodation_opt2, tripAccomodationList)
  yield put(getTripSeasonByAccomodationOpt2Results(data))
}


function* getTripPensionAsync(params) {
  const { tripPensionList } = params
  const data = yield call(api.get_trip_pension, tripPensionList)
  yield put(getTripPensionResults(data))
}

function* getTripRoomAsync(params) {
  const { tripRoomList, tripAccoList } = params
  const data = yield call(api.get_trip_room, tripRoomList, tripAccoList)
  yield put(getTripRoomResults(data))
}
function* getTripRoomOpt1Async(params) {
  const { tripRoomList, tripAccoList } = params
  const data = yield call(api.get_trip_room_opt1, tripRoomList, tripAccoList)
  yield put(getTripRoomOpt1Results(data))
}

function* getTripRoomOpt2Async(params) {
  const { tripRoomList, tripAccoList } = params
  const data = yield call(api.get_trip_room_opt2, tripRoomList, tripAccoList)
  yield put(getTripRoomOpt2Results(data))
}

// LISTS
function* getStatusListAsync() {
  const data = yield call(api.get_status_list)
  yield put(getStatusListResults(data))
}

function* getDaysListAsync(params) {
  const {
    userId,
  } = params
  const data = yield call(api.get_days_list, userId)
  yield put(getDaysListResults(data))
}

function* getLocationListAsync(params) {
  const {
    userId,
  } = params
  const data = yield call(api.get_location_list, userId)
  yield put(getLocationListResults(data))
}
function* getLocationGrandListAsync(params) {
  const {
    userId,
  } = params
  const data = yield call(api.get_location_grand_list, userId)
  yield put(getLocationGrandListResults(data))
}

function* getPensionListAsync(params) {
  const {
    userId,
  } = params
  const data = yield call(api.get_pension_list, userId)
  yield put(getPensionListResults(data))
}

function* getAccomodationListAsync(params) {
  const {
    userId,
  } = params
  const data = yield call(api.get_accomodation_list, userId)
  yield put(getAccomodationListResults(data))
}

function* getRoomListAsync(params) {
  const {
    userId,
  } = params
  const data = yield call(api.get_room_list, userId)
  yield put(getRoomListResults(data))
}

function* getAccomodationTypeListAsync(params) {
  const {
    userId,
  } = params
  const data = yield call(api.get_accomodation_type_list, userId)
  yield put(getAccomodationTypeListResults(data))
}

function* getRoomByAccomodationListAsync(params) {
  const {
    userId,
  } = params
  const data = yield call(api.get_room_by_accomodation_list, userId)
  yield put(getRoomByAccomodationListResults(data))
}

function* getRoomByAccomodationAsync(params) {
  const {
    userId, accoId,
  } = params
  const data = yield call(api.get_room_by_accomodation, userId, accoId)
  yield put(getRoomByAccomodationResults(data))
}
function* getSeasonByAccomodationAsync(params) {
  const {
    userId, accoId,
  } = params
  const data = yield call(api.get_season_by_accomodation, userId, accoId)
  yield put(getSeasonByAccomodationResults(data))
}
function* getSeasonByActivityAsync(params) {
  const {
    userId, actId,
  } = params
  const data = yield call(api.get_season_by_activity, userId, actId)
  yield put(getSeasonByActivityResults(data))
}
function* getSeasonByActivityAllAsync(params) {
  const {
    userId,
  } = params
  const data = yield call(api.get_season_by_activity_all, userId)
  yield put(getSeasonByActivityAllResults(data))
}
function* getProfilUserListAsync() {
  const data = yield call(api.get_profil_user_list)
  yield put(getProfilUserListResults(data))
}

// MANAGEMENT
function* putDayContentAsync(params) {
  const {
    token,
    dayContent,
    action,
  } = params
  const data = yield call(api.put_day_content, token, dayContent, action)
  yield put(putDayContentResults(data))
}

function* deleteDayContentAsync(params) {
  const {
    token,
    dayId,
  } = params
  const data = yield call(api.delete_day_content, token, dayId)
  yield put(deleteDayContentResults(data))
}

function* putAccomodationContentAsync(params) {
  const {
    token,
    accomodationContent,
    action,
  } = params
  const data = yield call(api.put_accomodation_content, token, accomodationContent, action)
  yield put(putAccomodationContentResults(data))
}

function* deleteAccomodationContentAsync(params) {
  const {
    token,
    accomodationId,
  } = params
  const data = yield call(api.delete_accomodation_content, token, accomodationId)
  yield put(deleteAccomodationContentResults(data))
}

function* putLocationContentAsync(params) {
  const {
    token,
    locationContent,
    action,
  } = params
  const data = yield call(api.put_location_content, token, locationContent, action)
  yield put(putLocationContentResults(data))
}
function* putLocationGrandContentAsync(params) {
  const {
    token,
    locationGrandContent,
    action,
  } = params
  const data = yield call(api.put_location_grand_content, token, locationGrandContent, action)
  yield put(putLocationGrandContentResults(data))
}

function* deleteLocationContentAsync(params) {
  const {
    token,
    locationId,
  } = params
  const data = yield call(api.delete_location_content, token, locationId)
  yield put(deleteLocationContentResults(data))
}
function* deleteLocationGrandContentAsync(params) {
  const {
    token,
    locationGrandId,
  } = params
  const data = yield call(api.delete_location_grand_content, token, locationGrandId)
  yield put(deleteLocationGrandContentResults(data))
}

function* putPensionContentAsync(params) {
  const {
    token,
    pensionContent,
    action,
  } = params
  const data = yield call(api.put_pension_content, token, pensionContent, action)
  yield put(putPensionContentResults(data))
}

function* deletePensionContentAsync(params) {
  const {
    token,
    pensionId,
  } = params
  const data = yield call(api.delete_pension_content, token, pensionId)
  yield put(deletePensionContentResults(data))
}

function* putRoomContentAsync(params) {
  const {
    token,
    roomContent,
    action,
  } = params
  const data = yield call(api.put_room_content, token, roomContent, action)
  yield put(putRoomContentResults(data))
}

function* deleteRoomContentAsync(params) {
  const {
    token,
    roomId,
  } = params
  const data = yield call(api.delete_room_content, token, roomId)
  yield put(deleteRoomContentResults(data))
}

function* putAccomodationTypeContentAsync(params) {
  const {
    token,
    accomodationTypeContent,
    action,
  } = params
  const data = yield call(api.put_accomodation_type_content, token, accomodationTypeContent, action)
  yield put(putAccomodationTypeContentResults(data))
}

function* deleteAccomodationTypeContentAsync(params) {
  const {
    token,
    accomodationTypeId,
  } = params
  const data = yield call(api.delete_accomodation_type_content, token, accomodationTypeId)
  yield put(deleteAccomodationTypeContentResults(data))
}

function* putRoomByAccomodationAsync(params) {
  const {
    token,
    roomByAccomodation,
    action,
  } = params
  const data = yield call(api.put_room_by_accomodation, token, roomByAccomodation, action)
  yield put(putRoomByAccomodationResults(data))
}

function* putSeasonByAccomodationAsync(params) {
  const {
    token,
    seasonByAccomodation,
    SeasonDifftoupdate,
    SeasonDifftoDelete,
    SeasonDifftoAdd,
    action,
  } = params
  const data = yield call(api.put_season_by_accomodation, token, seasonByAccomodation, SeasonDifftoupdate, SeasonDifftoDelete, SeasonDifftoAdd, action)
  yield put(putSeasonByAccomodationResults(data))
}
function* putSeasonByActivityAsync(params) {
  const {
    token,
    seasonByActivity,
    SeasonDifftoupdate,
    SeasonDifftoDelete,
    SeasonDifftoAdd,
    action,
  } = params
  const data = yield call(api.put_season_by_activity, token, seasonByActivity, SeasonDifftoupdate, SeasonDifftoDelete, SeasonDifftoAdd, action)
  yield put(putSeasonByActivityResults(data))
}
function* putRoomPriceByAccomodationAsync(params) {
  const {
    token,
    roomByAccomodation,
  } = params
  const data = yield call(api.put_room_price_by_accomodation, token, roomByAccomodation)
  yield put(putRoomPriceByAccomodationResults(data))
}
function* deleteRoomByAccomodationAsync(params) {
  const {
    token,
    roomByAccomodationId,
  } = params
  const data = yield call(api.delete_room_by_accomodation, token, roomByAccomodationId)
  yield put(deleteRoomByAccomodationResults(data))
}
function* deleteSeasonByAccomodationAsync(params) {
  const {
    token,
    seasonByAccomodationId,
  } = params
  const data = yield call(api.delete_season_by_accomodation, token, seasonByAccomodationId)
  yield put(deleteSeasonByAccomodationResults(data))
}
function* deleteSeasonByActivityAsync(params) {
  const {
    token,
    seasonByActivityId,
  } = params
  const data = yield call(api.delete_season_by_activity, token, seasonByActivityId)
  yield put(deleteSeasonByActivityResults(data))
}

function* fetchResult() {
  yield takeEvery('POST_SIGNIN', postUserSignInAsync)
  yield takeEvery('GET_UNVALID_TOKEN_LIST', getUnvalidTokenListAsync)
  yield takeEvery('GET_LIST_CIRCUIT', getListCircuitAsync)
  yield takeEvery('DELETE_TRIP', deleteTripAsync)
  yield takeEvery('GET_TRIP_DETAILS', getTripDetailsAsync)
  yield takeEvery('PUT_TRIP_DETAILS', putTripDetailsAsync)
  yield takeEvery('PUT_TRIP_DETAILS_URL', putTripDetailsUrlAsync)
  yield takeEvery('PUT_TRIP_DEVIS_FINAL', putTripDevisFinalAsync)
  yield takeEvery('GET_TRIP_CONTENT', getTripContentAsync)
  yield takeEvery('PUT_TRIP_CONTENT', putTripContentAsync)
  yield takeEvery('GET_TRIP_ALLDAYS', getTripAllDaysAsync)
  yield takeEvery('GET_TRIP_ALLDAYS_ACCOMODATION', getTripAllDaysAccomodationAsync)
  yield takeEvery('GET_TRIP_ACCOMODATION', getTripAccomodationAsync)
  yield takeEvery('GET_TRIP_SEASON_BY_ACCOMODATION', getTripSeasonByAccomodationAsync)
  yield takeEvery('GET_TRIP_SEASON_BY_ACCOMODATION_OPT1', getTripSeasonByAccomodationOpt1Async)
  yield takeEvery('GET_TRIP_SEASON_BY_ACCOMODATION_OPT2', getTripSeasonByAccomodationOpt2Async)
  yield takeEvery('GET_TRIP_ACCOMODATION_OPT1', getTripAccomodationOpt1Async)
  yield takeEvery('GET_TRIP_ACCOMODATION_OPT2', getTripAccomodationOpt2Async)
  yield takeEvery('GET_TRIP_PENSION', getTripPensionAsync)
  yield takeEvery('GET_TRIP_DEVIS_FINAL', getTripDevisFinalAsync)
  yield takeEvery('GET_TRIP_ROOM', getTripRoomAsync)
  yield takeEvery('GET_TRIP_ROOM_OPT1', getTripRoomOpt1Async)
  yield takeEvery('GET_TRIP_ROOM_OPT2', getTripRoomOpt2Async)
  yield takeEvery('GET_STATUS_LIST', getStatusListAsync)
  yield takeEvery('GET_DAYS_LIST', getDaysListAsync)
  yield takeEvery('GET_LOCATION_LIST', getLocationListAsync)
  yield takeEvery('GET_LOCATION_GRAND_LIST', getLocationGrandListAsync)
  yield takeEvery('GET_PENSION_LIST', getPensionListAsync)
  yield takeEvery('GET_ACCOMODATION_LIST', getAccomodationListAsync)
  yield takeEvery('GET_ROOM_LIST', getRoomListAsync)
  yield takeEvery('GET_ACCOMODATION_TYPE_LIST', getAccomodationTypeListAsync)
  yield takeEvery('GET_ROOM_BY_ACCOMODATION_LIST', getRoomByAccomodationListAsync)
  yield takeEvery('GET_ROOM_BY_ACCOMODATION', getRoomByAccomodationAsync)
  yield takeEvery('GET_SEASON_BY_ACCOMODATION', getSeasonByAccomodationAsync)
  yield takeEvery('GET_SEASON_BY_ACTIVITY', getSeasonByActivityAsync)
  yield takeEvery('GET_SEASON_BY_ACTIVITY_ALL', getSeasonByActivityAllAsync)
  yield takeEvery('GET_PROFIL_USER_LIST', getProfilUserListAsync)
  yield takeEvery('PUT_DAY_CONTENT', putDayContentAsync)
  yield takeEvery('DELETE_DAY_CONTENT', deleteDayContentAsync)
  yield takeEvery('PUT_ACCOMODATION_CONTENT', putAccomodationContentAsync)
  yield takeEvery('DELETE_ACCOMODATION_CONTENT', deleteAccomodationContentAsync)
  yield takeEvery('PUT_LOCATION_CONTENT', putLocationContentAsync)
  yield takeEvery('PUT_LOCATION_GRAND_CONTENT', putLocationGrandContentAsync)
  yield takeEvery('DELETE_LOCATION_CONTENT', deleteLocationContentAsync)
  yield takeEvery('DELETE_LOCATION_GRAND_CONTENT', deleteLocationGrandContentAsync)
  yield takeEvery('PUT_PENSION_CONTENT', putPensionContentAsync)
  yield takeEvery('DELETE_PENSION_CONTENT', deletePensionContentAsync)
  yield takeEvery('PUT_ROOM_CONTENT', putRoomContentAsync)
  yield takeEvery('DELETE_ROOM_CONTENT', deleteRoomContentAsync)
  yield takeEvery('PUT_ACCOMODATION_TYPE_CONTENT', putAccomodationTypeContentAsync)
  yield takeEvery('DELETE_ACCOMODATION_TYPE_CONTENT', deleteAccomodationTypeContentAsync)
  yield takeEvery('PUT_ROOM_BY_ACCOMODATION', putRoomByAccomodationAsync)
  yield takeEvery('PUT_SEASON_BY_ACCOMODATION', putSeasonByAccomodationAsync)
  yield takeEvery('PUT_SEASON_BY_ACTIVITY', putSeasonByActivityAsync)
  yield takeEvery('PUT_ROOM_PRICE_BY_ACCOMODATION', putRoomPriceByAccomodationAsync)
  yield takeEvery('DELETE_ROOM_BY_ACCOMODATION', deleteRoomByAccomodationAsync)
  yield takeEvery('DELETE_SEASON_BY_ACCOMODATION', deleteSeasonByAccomodationAsync)
  yield takeEvery('DELETE_SEASON_BY_ACTIVITY', deleteSeasonByActivityAsync)
}

export default fetchResult
