/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'
import { renderToStaticMarkup } from 'react-dom/server';
import Grid from '@material-ui/core/Grid'
import Interweave from 'interweave';
import moment from 'moment'
import * as XLSX from 'xlsx';
import {
  ActionButton,
} from '../../../components'


export default function ReservationListSummary(props) {
  const {
    tripContent,
    tripActivities,
    tripDetails,
    tripAccomodation,
    tripSeasonByAccomodation,
    seasonByActivityAll,
    tripRoom,
    collaborators,
    tripBilling,
    tripAllDays,
  } = props

  // Filtre pour trouver les collaborateurs correspondant aux IDs dans tabOp

  const sales = collaborators.filter(c => c.id_conseiller === tripDetails[0].id_conseiller)
  // Map pour obtenir un tableau avec les noms et prénoms, puis join pour les concaténer en une seule chaîne


  // Buil array with all info trip alldays trip accomodation and trip activity
  const tripActivitiesFlat = [...new Set(Object.values(tripActivities)
    .flat())];
  const tripActivitiesWPrice = tripActivitiesFlat.map((valeur1) => {
    const { day_index } = valeur1
    const IncDay = tripAllDays[day_index].day_duration
    let seasonName = ''
    let PriceRoomCalculated = 0
    const roomsNumberArray = tripContent && tripContent[0].nb_chambre ? tripContent[0].nb_chambre.split(',') : null
    const roomsNbForDay = roomsNumberArray && roomsNumberArray[day_index] !== 'null' ? roomsNumberArray[day_index].split('-') : []
    const seasonByAccomodation = tripSeasonByAccomodation.filter(c => c.id_hebergement === tripAccomodation[day_index].id_hebergement)
    let datejour1 = tripDetails[0].number_day_departure ? tripDetails[0].number_day_departure + IncDay : ''
    const PriceRoom = tripRoom[day_index]
    const annee = _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').add(day_index, 'days').format('YYYY'))

    const ily = ((annee % 4 === 0) && (annee % 100 !== 0)) || (annee % 400 === 0);
    if (ily && (datejour1 > 59)) (datejour1 -= 1)

    if (!_.isEmpty(seasonByAccomodation)) {
      seasonByAccomodation.map((sBA) => {
        if (datejour1 >= sBA.number_day_start && datejour1 <= sBA.number_day_end) {
          seasonName = `price_${sBA.period_name}`
        }
        return null
      })
    }
    if (PriceRoom) {
      PriceRoom.map((PR, index2) => {
        if (PR[`${seasonName}`] && PR[seasonName] !== 0) {
          PriceRoomCalculated += (PR[seasonName] * roomsNbForDay[index2]) * (tripAllDays[day_index].day_duration ? tripAllDays[day_index].day_duration : 1)
        } else {
          PriceRoomCalculated += (PR.price_peakseason * roomsNbForDay[index2]) * (tripAllDays[day_index].day_duration ? tripAllDays[day_index].day_duration : 1)
        }
        return null
      })
    }
    const seasonByActivities = seasonByActivityAll.filter(sBA => sBA.id_activity === valeur1.id_activity)
    let priceSeasonNameAdult = 0
    let priceSeasonNameChild = 0
    let priceSeasonNameSenior = 0
    let isSeasonFound = false;
    if (!_.isEmpty(seasonByActivities)) {
      seasonByActivities.map((sBA) => {
        if (!isSeasonFound && datejour1 >= sBA.number_day_start && datejour1 <= sBA.number_day_end) {
          priceSeasonNameAdult = `price_adult${sBA.period_name}`
          priceSeasonNameChild = `price_child${sBA.period_name}`
          priceSeasonNameSenior = `price_senior${sBA.period_name}`
          isSeasonFound = true;
        } else if (!isSeasonFound) {
          priceSeasonNameAdult = 'price_adultpeakseason'
          priceSeasonNameChild = 'price_childpeakseason'
          priceSeasonNameSenior = 'price_seniorpeakseason'
        }
        return null
      })
    } else {
      priceSeasonNameAdult = 'price_adultpeakseason'
      priceSeasonNameChild = 'price_childpeakseason'
      priceSeasonNameSenior = 'price_seniorpeakseason'
    }
    let priceCalculatedActivityAdult = 0
    if (valeur1.activity_type === 'individual') {
      priceCalculatedActivityAdult = valeur1[priceSeasonNameAdult] * valeur1.nb_adult
    } else {
      priceCalculatedActivityAdult = valeur1[priceSeasonNameAdult] * (valeur1.nb_adult ? valeur1.nb_adult : 1)
    }
    const priceCalculatedAdultSum = valeur1.id_activity === 75 ? PriceRoomCalculated : priceCalculatedActivityAdult
    const priceCalculatedSenior = valeur1.id_activity === 75 ? '' : valeur1[priceSeasonNameSenior] * valeur1.nb_senior
    const priceCalculatedChild = valeur1.id_activity === 75 ? '' : valeur1[priceSeasonNameChild] * valeur1.nb_child
    const final_price_adult = (valeur1.final_price != null) ? valeur1.final_price : priceCalculatedAdultSum
    const final_price_senior = (valeur1.final_price_senior != null) ? valeur1.final_price_senior : priceCalculatedSenior
    const final_price_child = (valeur1.final_price_child != null) ? valeur1.final_price_child : priceCalculatedChild
    const final_price_total = final_price_adult + final_price_senior + final_price_child
    return {
      ...valeur1,
      ...tripAllDays[day_index],
      final_price_total,
    }
  });

  const filteredtripActivitiesFlat = tripActivitiesWPrice.filter(tAF => tAF.id_activity === 75).filter(tAF => tAF.id_accomodation !== 1)
  const filteredtripActivitiesFlatNoAcco = tripActivitiesWPrice.filter(tAF => tAF.id_activity !== 75)
  const tripAccoActiTemp = filteredtripActivitiesFlat.map((valeur1) => {
    const { day_index } = valeur1
    return {
      ...valeur1,
      ...tripAccomodation[day_index],
    }
  });
  const roomTypeArray = tripContent ? tripContent[0].type_chambre.split(',') : []

  const tripAccoActi = tripAccoActiTemp.map((valeur1) => {
    const { day_index } = valeur1
    return {
      ...valeur1,
      ...tripAllDays[day_index],
      roomType: roomTypeArray[day_index],
    }
  });


  // hotel creation table
  const uniqueHotels = {};
  const originalOrder = [];
  // Boucle à travers les hôtels
  const tripAccoActiKey = tripAccoActi.map(item => ({
    ...item,
    id_key: `${item.id_hebergement}_${item.roomType}`,
  }));
  tripAccoActiKey.forEach((hotel, index) => {
    // enregistrment des room et number
    const { day_index } = hotel
    const roomsNumberArray = tripContent && tripContent[0].nb_chambre ? tripContent[0].nb_chambre.split(',') : null
    const roomTypeForDay = () => {
      const roomsTypeForDay = roomTypeArray[day_index] && roomTypeArray[day_index] !== '1' ? roomTypeArray[day_index].split('-') : []
      const roomsNbForDay = roomsNumberArray && roomsNumberArray[day_index] !== 'null' ? roomsNumberArray[day_index].split('-') : []
      const roomsNameForDay = roomsTypeForDay.map(rT => (tripRoom[day_index] && !_.isEmpty(tripRoom[day_index]) ? tripRoom[day_index].find(r => r.id_heb_ch === parseInt(rT, 10)).type_chambre : 'none'))
      const label = _.isEmpty(roomsNbForDay) ? [] : roomsNameForDay.map((r, j) => `${r} (x${roomsNbForDay[j]})${roomsNameForDay.length === j ? '' : ', '}`)
      return label.join('')
    }


    // Vérifier si l'email de l'hôtel existe dans les hôtels uniques
    if (hotel.id_key && !uniqueHotels[hotel.id_key]) {
      // Ajouter l'hôtel unique à l'objet uniqueHotels avec le jour de la réservation
      uniqueHotels[hotel.id_key] = {
        ...hotel,
        id_hebergement: hotel.id_accomodation,
        nom_hebergement: hotel.nom_hebergement,
        phone: hotel.phone,
        email: hotel.email,
        reservedDays: [day_index + 1], // Ajoutez le jour de la réservation
        roomDays: roomTypeForDay(),
        activityCheckTab: [hotel.activityCheck ? hotel.activityCheck : 0],
        finalPrice: [hotel.final_price_total],
        emailContentTab: [!_.isEmpty(hotel.emailContent) && hotel.emailContent ? hotel.emailContent : ''],
        actCir: [hotel.id_act_cir],
        comment: [!_.isEmpty(hotel.comment) && hotel.comment ? hotel.comment : ''],
        nbNight: 1,
      };
      originalOrder.push(index);
    } else if (hotel.id_key && uniqueHotels[hotel.id_key]) {
      // Si l'email existe déjà, ajoutez le jour de la réservation à l'array reservedDays
      uniqueHotels[hotel.id_key].reservedDays.push(day_index + 1);
      uniqueHotels[hotel.id_key].finalPrice.push(hotel.final_price_total);
      uniqueHotels[hotel.id_key].activityCheckTab.push(hotel.activityCheck ? hotel.activityCheck : 0);
      uniqueHotels[hotel.id_key].emailContentTab.push(!_.isEmpty(hotel.emailContent) && hotel.emailContent ? hotel.emailContent : '');
      uniqueHotels[hotel.id_key].actCir.push(hotel.id_act_cir);
      uniqueHotels[hotel.id_key].comment.push(!_.isEmpty(hotel.comment) && hotel.comment ? hotel.comment : '');
      uniqueHotels[hotel.id_key].nbNight += 1
    }
  });
  const uniqueHotelsArray = originalOrder.map(index => uniqueHotels[tripAccoActiKey[index].id_key]);
  // determinate the header, isolate the unique value on activity_theme AND currency
  const tripActivitiesNoAccom = Object.keys(tripActivities).reduce((acc, key) => {
    acc[key] = tripActivities[key].filter(activity => activity.id_activity !== 75);
    return acc;
  }, {});

  const uniqueCurrencies = [...new Set(Object.values(tripActivitiesNoAccom)
    .flat()
    .map(item => item.currency))];

  const styleCc = {
    textAlign: 'center',
    height: '30px',
    border: '1px solid #000',
  }

  const styleCc2 = {
    textAlign: 'center',
    height: '30px',
    border: '1px solid #000',
    backgroundColor: '#B7DEE8',
  }

  const styleCc3 = {
    textAlign: 'center',
    height: '30px',
    border: '1px solid #000',
    backgroundColor: '#B1A0C7',
    fontWeight: 'bold',
  }

  const styleCc4 = {
    textAlign: 'center',
    height: '30px',
    borderBottom: '1px solid #000',
    backgroundColor: '#B7DEE8',
  }

  const styleCc5 = {
    textAlign: 'center',
    height: '30px',
    borderBottom: '1px solid #000',
  }

  const styleCc6 = {
    textAlign: 'center',
    height: '30px',
    backgroundColor: '#B7DEE8',
  }

  const styleCc7 = {
    textAlign: 'center',
    height: '30px',
  }
  const tripAccomodationNoNone = tripAccomodation.filter(tAD => tAD.id_hebergement !== 1)
  const currencyHeb = tripAccomodationNoNone.map(item => (item.currency))

  // determinate the header for the currency

  const currencyHebSansDoublons = _.uniq(currencyHeb);
  const currencySansDoublons = _.uniq(uniqueCurrencies.concat(currencyHebSansDoublons))
  const displaySupplier = (activityTheme) => {
    const filteredtripActivitiesByTheme = filteredtripActivitiesFlatNoAcco.filter(tAF => tAF.id_activity_theme === activityTheme)

    return (
      tripAllDays && filteredtripActivitiesByTheme.map((tAcc) => {
        const boucleJour = _.range(tAcc.trip_day_duration);
        const dateJour = tripDetails[0].date_depart
          ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').locale('en').add(tAcc.day_index, 'd').format('DD/MM'))
          : ''


        const dateToDisplayLand = boucleJour.map((value, index) => {
          const date = _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').locale('en').add(tAcc.day_index + index, 'd').format('DD/MM'))
          return (
            <div>{ date },</div>
          )
        })
        const dateFinal = (activityTheme === 4) ? dateToDisplayLand : dateJour
        return (
          <tr style={styleCc} key={`Acco${tAcc.id_act_cir}`}>
            <td />
            <td style={styleCc}>
              {tripAccomodation ? (
                <div>{tAcc.supplier && tAcc.supplier !== 'none' ? tAcc.supplier : 'N/A'}
                </div>
              ) : ''}
            </td>
            <td style={styleCc} colSpan="2">
              {dateFinal}
            </td>
            <td style={styleCc}>
              {tAcc.activity}
            </td>
            {currencySansDoublons.map((value, count) => {
              const devise = value === tAcc.currency ? tAcc.final_price_total : ''
              return (
                <td style={styleCc} key={`dev1${tAcc.id_act_cir}${count}`}>
                  {devise && devise.toLocaleString('en-US')}
                </td>
              )
            })}

          </tr>
        )
      })
    )
  }


  // calcul of the total price with currecny
  // for hotel
  let totalPrice1 = 0
  tripAccoActi.map((aOD) => {
    currencySansDoublons.map((uC, ic) => {
      if (uC === aOD.currency) { totalPrice1 += aOD.final_price_total * tripBilling[`exchangeRate${ic + 1}`] }
      return null
    })
    return null
  })
  // then for services
  filteredtripActivitiesFlatNoAcco.map((aOD) => {
    currencySansDoublons.map((uC, ic) => {
      if (uC === aOD.currency) { totalPrice1 += aOD.final_price_total * tripBilling[`exchangeRate${ic + 1}`] }
      return null
    })
    return null
  })


  const myPagefunc = () => {
    const {
      prenom_responsable_groupe,
      nom_responsable_groupe,
      code_devis,
      nationality,
    } = tripDetails[0]
    const TabIncJour = tripAllDays.map(item => (item.day_duration))
    const longueurVoyage = tripAllDays.length
    const IncDayTot = _.sum(TabIncJour.slice(0, longueurVoyage))
    const dateDepart = moment(tripDetails[0].date_depart, 'YYYY-MM-DD').format('DD MMMM YYYY')
    const dateFin = moment(tripDetails[0].date_depart, 'YYYY-MM-DD').add(IncDayTot - 1, 'd').format('DD MMMM YYYY')
    const longCurTab = currencySansDoublons.length

    return (
      <div>
        <Grid
          container
          spacing={2}
        >
          <Grid item md={12}>
            <table
              id="export-table"
              width="100%"
              style={{
                overflow: 'hidden',
                borderCollapse: 'collapse', // Assurez-vous que les bordures des cellules se joignent

              }}
            >
              <tbody>
                <tr>
                  <td colSpan={longCurTab + 3} style={styleCc}>
                    <table width="100%">
                      <tr>
                        <td
                          style={styleCc4}
                        >
                          Tour code
                        </td>
                        <td
                          style={styleCc5}
                        >
                          {code_devis}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={styleCc4}
                        >
                          Client's name
                        </td>
                        <td
                          style={styleCc5}
                        >
                          {nom_responsable_groupe} -
                          {prenom_responsable_groupe}
                        </td>

                      </tr>
                      <tr>
                        <td
                          style={styleCc4}
                        >
                          Number of pax
                        </td>
                        <td
                          style={styleCc5}
                        >
                          {tripDetails[0].group_size}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={styleCc4}
                        >
                          Nationality
                        </td>
                        <td
                          style={styleCc5}
                        >
                          {nationality}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={styleCc4}
                        >
                          Arrival Date
                        </td>
                        <td
                          style={styleCc5}
                        >
                          {dateDepart}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={styleCc4}
                        >
                          Departure Date
                        </td>
                        <td
                          style={styleCc5}
                        >
                          {dateFin}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={styleCc4}
                        >
                          Sale
                        </td>
                        <td
                          style={styleCc5}
                        >
                          {sales[0].firstname} {sales[0].lastname}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={styleCc6}
                        >
                          Selling price
                        </td>
                        <td
                          style={styleCc7}
                        >
                          {tripBilling.sellingPrice} {tripBilling.currency}
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td style={styleCc} />
                  <td
                    style={styleCc}

                  >
                    <table
                      width="100%"
                    >
                      <tbody>
                        <tr>
                          <td
                            colSpan={2}
                            style={styleCc2}
                          >
                            Exchange Rate
                          </td>
                        </tr>
                        {currencySansDoublons.map((item, index) => (
                          <tr>
                            <td
                              width="50%"
                              style={styleCc4}
                            >{item} to {tripBilling.currency}
                            </td>
                            <td
                              width="50%"
                              style={styleCc5}
                            >{tripBilling[`exchangeRate${index + 1}`]}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                  </td>
                </tr>
                <tr
                  style={{
                    height: '10px',
                  }}
                />
                <tr>
                  <td
                    style={styleCc2}
                  >
                    ORDER
                  </td>
                  <td
                    style={styleCc2}
                  >
                    SUPPLIER
                  </td>

                  <td
                    style={styleCc2}
                    colSpan="2"
                  >
                    SERVICE DATE
                  </td>
                  <td
                    style={styleCc2}
                  >
                    SERVICE'S LIST
                  </td>
                  <td
                    style={styleCc2}
                    colSpan={longCurTab}
                  >
                    TOTAL COST
                  </td>
                </tr>
                <tr>
                  <td
                    style={styleCc2}
                  >
                    I
                  </td>
                  <td
                    style={styleCc2}
                  >
                    Accomodation
                  </td>
                  <td
                    style={styleCc2}
                  >
                    Check In
                  </td>
                  <td
                    style={styleCc2}
                  >
                    Check Out
                  </td>
                  <td
                    style={styleCc2}
                  />
                  {currencySansDoublons.map((value, count) => (
                    <td style={styleCc2} key={`mot-${count}`}>
                      <div>{value} </div>
                    </td>

                  ))}


                </tr>
                {tripAllDays && uniqueHotelsArray.map((tAcc) => {
                  const checkIn = tAcc.reservedDays.map((rD, index) => {
                    const dateJour = tripDetails[0].date_depart
                      ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').locale('en').add(rD - 1, 'd').format('DD/MM'))
                      : ''
                    const isConsecutive = rD && tAcc.reservedDays[index - 1] === rD - 1;

                    // Si c'est consécutif, ne rien rendre
                    if (isConsecutive) {
                      return null;
                    }

                    return (
                      <div key={`CheckIn${tAcc.id_act_cir}${dateJour}`}>{dateJour}</div>
                    )
                  })
                  const checkOut = tAcc.reservedDays.map((rD, index) => {
                    const dateJour = tripDetails[0].date_depart
                      ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').locale('en').add(rD + tAcc.day_duration - 1, 'd').format('DD/MM'))
                      : ''
                    const isConsecutive = rD && tAcc.reservedDays[index + 1] === rD + 1;

                    // Si c'est consécutif, ne rien rendre
                    if (isConsecutive) {
                      return null;
                    }
                    return (
                      <div key={`CheckOut${tAcc.id_act_cir}${dateJour}`}>{dateJour}</div>
                    )
                  })


                  return (
                    <tr key={`Acco${tAcc.id_act_cir}`}>
                      <td
                        style={styleCc}
                      />
                      <td style={styleCc}>
                        {tripAccomodation ? (
                          <div>{tAcc.nom_hebergement}
                          </div>
                        ) : ''}
                      </td>
                      <td style={styleCc}>{checkIn}</td>
                      <td style={styleCc}>{checkOut}</td>
                      <td style={styleCc}>
                        <div key={`RoomTodisplay${tAcc.id_act_cir}`}>
                          {tAcc.roomDays}
                        </div>
                      </td>
                      {currencySansDoublons.map((value, count) => {
                        const devise = value === tAcc.currency ? tAcc.final_price_total : ''
                        return (
                          <td style={styleCc} key={`dev1${tAcc.id_act_cir}${count}`}>
                            {devise && devise.toLocaleString('en-US')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
                <tr>
                  <td
                    style={styleCc2}
                  >
                    II
                  </td>
                  <td
                    style={styleCc2}
                  >
                    Restaurant
                  </td>

                  <td
                    colSpan={longCurTab + 3}
                    style={styleCc2}
                  />
                </tr>
                {displaySupplier(2)}
                <tr>
                  <td
                    style={styleCc2}
                  >
                    III
                  </td>
                  <td
                    style={styleCc2}
                  >
                    Transportation
                  </td>

                  <td
                    colSpan={longCurTab + 3}
                    style={styleCc2}
                  />
                </tr>
                {displaySupplier(3)}
                <tr>
                  <td
                    style={styleCc2}
                  >
                    IV
                  </td>
                  <td
                    style={styleCc2}
                  >
                    Activity
                  </td>

                  <td
                    colSpan={longCurTab + 3}
                    style={styleCc2}
                  />
                </tr>
                {displaySupplier(4)}
                <tr>
                  <td
                    style={styleCc2}
                  >
                    V
                  </td>
                  <td
                    style={styleCc2}
                  >
                    Service Guide
                  </td>

                  <td
                    colSpan={longCurTab + 3}
                    style={styleCc2}
                  />
                </tr>
                {displaySupplier(5)}
                <tr>
                  <td
                    style={styleCc2}
                  >
                    VI
                  </td>
                  <td
                    style={styleCc2}
                  >
                    Flight
                  </td>

                  <td
                    colSpan={longCurTab + 3}
                    style={styleCc2}
                  />
                </tr>
                {displaySupplier(6)}
                <tr>
                  <td
                    style={styleCc2}
                  >
                    VII
                  </td>
                  <td
                    style={styleCc2}
                  >
                    Extra
                  </td>

                  <td
                    colSpan={longCurTab + 3}
                    style={styleCc2}
                  />
                </tr>
                {displaySupplier(7)}
                <tr>
                  <td
                    style={styleCc2}
                  >
                    VIII
                  </td>
                  <td
                    style={styleCc2}
                  >
                    Other
                  </td>

                  <td
                    colSpan={longCurTab + 3}
                    style={styleCc2}
                  />
                </tr>
                {displaySupplier(8)}
                <tr>
                  <td style={styleCc3} colSpan="5">
                    TOTAL COST
                  </td>

                  {currencySansDoublons.map((value, count) => {
                    // Calculer le total uniquement pour la monnaie correspondante
                    const totalFinalPriceHeb = tripAccoActi.reduce(
                      (acc, activite) => (activite.currency === value ? acc + parseFloat(activite.final_price_total ? activite.final_price_total : 0) : acc),
                      0,
                    ).toFixed(2);

                    const totalFinalPriceAct = filteredtripActivitiesFlatNoAcco.reduce(
                      (acc, activite) => (activite.currency === value ? acc + parseFloat(activite.final_price_total ? activite.final_price_total : 0) : acc),
                      0,
                    ).toFixed(2);

                    const totalPrice = parseFloat(totalFinalPriceHeb) + parseFloat(totalFinalPriceAct);
                    return (
                      <td style={styleCc} key={`mot-${count}`}>
                        <div>  {totalPrice && totalPrice.toLocaleString('en-US')}</div>
                      </td>
                    );
                  })}

                </tr>
                <tr>
                  <td style={styleCc3} colSpan="5">
                    GRAND TOTAL COST ({tripBilling.currency})
                  </td>
                  <td style={styleCc} colSpan={longCurTab}>{totalPrice1 && totalPrice1.toLocaleString('en-US')}</td>
                </tr>

              </tbody>
            </table>
          </Grid>

        </Grid>
        <br />
        <br />
        <br />


      </div>
    )
  }


  const pageHtml = renderToStaticMarkup(myPagefunc());


  const html = `
  <!DOCTYPE html>
  <html>
    <head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>Votre proposition de voyage</title>
    <style>    
    .mettreRouge table tbody tr:first-child td, .mettreRouge table tbody tr:first-child th,.centreTabPrice table tbody tr:first-child td, .centreTabPrice table tbody tr:first-child th {
   }
   .mettreRouge table, .centreTabPrice table {
     border-radius: 10px 10px 0 0 !important; 
     overflow: hidden !important;
    } 
   .mettreRouge table tbody, .centreTabPrice table tbody {
     border: 1px solid !important;    
   }
   .centreTabPrice table {
     break-inside: avoid;
   }

   .tableSummary td,
    .tableSummary th {
      border: 4px solid #000;
      padding: 8px;
    }
  
     </style>
    </head>
    <body>
    <a href="#" class="affiche-print" OnClick="javascript:window.print()">
          <img src="https://erp-travelsense-asia-pictures.s3.ap-south-1.amazonaws.com/travelsenseasia/icons8-download-1702002493677.png" alt="Download Icon" style="width: 50px; height: 50px;>
              <div style="font-weight: bold; font-size: 10px; ">download</div>
          </a> 
      
          ${pageHtml}
     </body>
  </html>
  `;

  const html2 = `
  <div>
    <style>    
      .mettreRouge table tbody tr:first-child td, 
      .mettreRouge table tbody tr:first-child th, 
      .centreTabPrice table tbody tr:first-child td, 
      .centreTabPrice table tbody tr:first-child th {}

      .mettreRouge table, 
      .centreTabPrice table {
        border-radius: 10px 10px 0 0 !important; 
        overflow: hidden !important;
      } 

      .mettreRouge table tbody, 
      .centreTabPrice table tbody {
        border: 1px solid !important;    
      }

      .centreTabPrice table {
        break-inside: avoid;
      }

      .tableSummary td,
      .tableSummary th {
        border: 4px solid #000;
        padding: 8px;
      }
    </style>


      
    ${pageHtml}
  </div>
`;


  const handlePrint = () => {
    const content = html; // Récupérez la valeur du contenu HTML de votre éditeur

    const newWindow = window.open('', '_blank');
    newWindow.document.write(content);
    newWindow.document.close();
  }


  const exportToExcelWithXLSX = () => {
  // Convertir le contenu HTML en un élément DOM
    const parser = new DOMParser();
    const doc = parser.parseFromString(pageHtml, 'text/html');

    // Sélectionner le tableau par son ID
    const table = doc.getElementById('export-table');

    if (!table) {
      console.error('Table with ID "export-table" not found.');
      return;
    }

    // Utiliser xlsx pour convertir le tableau HTML en une feuille de calcul
    const workbook = XLSX.utils.table_to_book(table, { sheet: 'Sheet1' });

    // Exporter le fichier Excel
    XLSX.writeFile(workbook, 'table-export.xlsx');
  };


  return (
    <div>
      <>
        <div className="urlBox">
          <Grid container spacing={0} justifyContent="flex-start">
            <br />

            <Grid item xs={2}>
              <ActionButton
                label="Generate PDF"
                onClick={() => handlePrint(html)}
              />
            </Grid>
            <Grid item xs={2}>
              <ActionButton
                label="export excell"
                onClick={() => exportToExcelWithXLSX()}
              />
            </Grid>
          </Grid>
        </div>
        <Interweave content={html2} />

      </>
    </div>
  );
}

ReservationListSummary.propTypes = {
  tripContent: PropTypes.array,
  collaborators: PropTypes.array,
  tripAllDays: PropTypes.array,
  tripBilling: PropTypes.object,
  tripActivities: PropTypes.object,
  tripRoom: PropTypes.array,
  tripAccomodation: PropTypes.array,
  tripSeasonByAccomodation: PropTypes.array,
  seasonByActivityAll: PropTypes.array,
  tripDetails: PropTypes.array,
};

ReservationListSummary.defaultProps = {
  tripContent: [],
  collaborators: [],
  tripDetails: [],
  tripBilling: {},
  tripRoom: [],
  tripSeasonByAccomodation: [],
  seasonByActivityAll: [],
  tripAccomodation: [],
  tripAllDays: [],
  tripActivities: {},
};
