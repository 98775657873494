/* eslint-disable prefer-promise-reject-errors */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types'
import { Editor } from '@tinymce/tinymce-react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import stage from '../../config'
import {
  ModalGalerie,
} from '..'


const savedImgUrl = stage.imgStorage

class TextEditor extends PureComponent {
  static propTypes = {
    object: PropTypes.object,
    value: PropTypes.string,
    linkCss: PropTypes.string,
    height: PropTypes.number,
    disabled: PropTypes.bool,
    handleEditorChange: PropTypes.func,
    isModalOpenInChildren: PropTypes.func,

  }

  static defaultProps = {
    object: {},
    linkCss: '',
    value: '',
    height: 250,
    disabled: false,
    handleEditorChange: () => {},
    isModalOpenInChildren: () => {},
  }

  constructor(props) {
    super(props);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.insertPictureInEditor = this.insertPictureInEditor.bind(this);
    this.getpic = this.getpic.bind(this);
    this.openGalerie = this.openGalerie.bind(this);
    this.state = {
      object: {},
      value: '',
      isModalOpen: false,
    };
  }

  componentDidMount() {
    const {
      object,
      value,
    } = this.props
    this.setState({
      object,
      value,
    })
  }

  componentWillReceiveProps(nextProps) {
    const {
      object: objectOld,
      value: valueOld,
    } = this.state
    const {
      object,
      value,
    } = nextProps
    if (value !== valueOld || object !== objectOld) {
      this.setState({
        object,
        value,
      })
    }
  }

  componentWillUnmount() {
  }

  handleEditorChange = (content) => {
    const {
      handleEditorChange,
    } = this.props
    handleEditorChange(content)
  }

  // ****************************************
  // Redirect


  handleClose = () => {
    const { isModalOpenInChildren } = this.props
    const { scrollY } = window;
    this.setState({
      isModalOpen: false,
    })
    setTimeout(() => {
      window.scrollTo(0, scrollY);
    }, 0);
    setTimeout(() => {
      // Indique au parent que le modal est fermé après un petit délai
      isModalOpenInChildren(false);
    }, 300);
  }


    // Insérer l'image dans l'éditeur TinyMCE
    insertPictureInEditor = (picture) => {
      const imgHtml = `<img src="${savedImgUrl}/${picture[0]}" alt="Selected image" width="200" height="200"/>`
      if (picture[0] && this.editor) {
        this.editor.insertContent(imgHtml);
      }
    };


  getpic= (picture) => {
    const { isModalOpenInChildren } = this.props
    const { scrollY } = window;
    this.setState({
      isModalOpen: false,
    })
    setTimeout(() => {
      window.scrollTo(0, scrollY);
    }, 0);
    this.insertPictureInEditor(picture) // Insérer immédiatement après la mise à jour
    setTimeout(() => {
      // Indique au parent que le modal est fermé après un petit délai
      isModalOpenInChildren(false);
    }, 300);
  }

  openGalerie=() => {
    const { isModalOpenInChildren } = this.props
    isModalOpenInChildren(true)
    this.setState({ isModalOpen: true });
  }

  render() {
    const {
      value,
      isModalOpen,
    } = this.state
    const {
      disabled,
      height,
      linkCss,
    } = this.props
    return (
      <div className="custom-editor-container">
        <Editor
          tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
          value={value || ''}
          disabled={disabled}
          init={{
            selector: '.custom-editor-container',
            license_key: 'gpl',
            height: height,
            menubar: false,
            plugins: 'lists link emoticons table image code pagebreak',
            toolbar: 'pagebreak | undo redo | bold | italic | underline | forecolor | backcolor | fontfamily | fontsize | link | alignleft aligncenter alignright alignjustify | lineheight | numlist bullist | emoticons | table | code | customImageButton',
            setup: (editor) => {
              this.editor = editor; // Référence à l'éditeur pour insérer le contenu
              editor.ui.registry.addButton('customImageButton', {
                icon: 'image',
                onAction: () => {
                  this.openGalerie()
                },
              });
            },
            ...(linkCss && { content_css: linkCss }), // Conditionnellement inclure content_css
            font_family_formats: `
          Andale Mono=andale mono,times;
          Arial=arial,helvetica,sans-serif;
          Arial Black=arial black,avant garde;
          Averta Demo=Averta Demo;
          Averta Demo ExtraBold Italic=Averta-Demo-ExtraBold-Italic;  
          Book Antiqua=book antiqua,palatino;
          Cambria=cambria,sans-serif;
          Calibri=calibri;
          Comic Sans MS=comic sans ms,sans-serif;
          Courier New=courier new,courier;
          Georgia=georgia,palatino;
          Gill Sans MT=Gill Sans MT,sans-serif;    
          Helvetica=helvetica;
          Impact=impact,chicago;
          Lato=Lato;
          Montserrat=Montserrat;
          Myriad Arabic Regular=myriad-arabic;
          Noto Sans=Noto Sans;
          Open Sans=Open Sans;
          Poppins=poppins;
          Raleway=raleway;
          Roboto=roboto;
          Symbol=symbol;
          Tahoma=tahoma,arial,helvetica,sans-serif;
          Terminal=terminal,monaco;
          Times New Roman=times new roman,times;
          Trebuchet MS=trebuchet ms,geneva;
          Verdana=verdana,geneva;
          Webdings=webdings;
          Wingdings=wingdings,zapf dingbats;
        `,

            contextmenu: false,
            image_advtab: true,
            default_link_target: '_blank',
            pagebreak_separator: ' <div style="page-break-after: always; clear:both"/></div>',
            line_height_formats: '8px 9px 10px 11px 12px 14px 16px 18px 20px 22px 24px 26px 36px',
            font_size_formats: '8pt 8.5pt 9pt 9.5pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 24pt',
            table_default_attributes: {
              border: '1px',
            },
          }}
          onEditorChange={this.handleEditorChange}
        />
        {isModalOpen && (
        <ModalGalerie
          isModalOpen={isModalOpen}
          onClose={this.handleClose}
          onResponseNo={this.handleClose}
          onResponseYes={picture => this.getpic(picture)}
          title="Create the activity"
          modalContent="activity_personalised"
        />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  companyContent: state.companyReducer.companyContent,
})

const mapDispatchToProps = dispatch => ({
  GET_PICTURE: (userId, company) => dispatch({
    type: 'GET_PICTURE',
    userId,
    company,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TextEditor))
